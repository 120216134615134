import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, H3, Intent } from '@blueprintjs/core';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';

import { PROPERTY_TYPES } from '../../../constants';
import PartPropertyModal from 'components/PartPropertyModal';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { Property, usePartPropertiesTableLazyQuery } from 'graphql/generated/graphql';
import { selectPartPropertiesSummaryView, tableViewSlice } from 'reducers/tableView';
import useDocumentTitle from '../../../hooks/useDocumentTitle';
import styles from './index.module.css';

const columnHelper = createColumnHelper<Property>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('display_name', {
    header: 'Display Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: info => PROPERTY_TYPES[info.getValue()],
  }),
  columnHelper.accessor('min', {
    header: 'Min',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('max', {
    header: 'Max',
    cell: info => info.getValue(),
  }),
  columnHelper.accessor('values', {
    header: 'Values',
    cell: info => info.getValue()?.join(', '),
    enableSorting: false,
  }),
  columnHelper.accessor('unit', {
    header: 'Unit',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
] as AccessorKeyColumnDef<Property>[];

export default () => {
  useDocumentTitle('Apex Setup - Part Properties');
  const dispatch = useDispatch();
  const { tableFilters, tableSorting } = useSelector(selectPartPropertiesSummaryView);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState<Property>();
  const [tableData, setTableData] = useState<Property[]>([]);
  const [getProperties, { data, refetch }] = usePartPropertiesTableLazyQuery();

  useEffect(() => {
    if (data) setTableData(data.properties.rows);
  }, [data]);

  const rowActions: RowActions<Property> = [{
    label: 'Edit',
    value: row => {
      setSelectedProperty(row.original);
      setIsFormDialogOpen(true);
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setPartPropertiesSummaryView({ filters, sorting }));

    getProperties({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };
  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, 200);

  const onFormSuccess = () => {
    setIsFormDialogOpen(false);
    setSelectedProperty(undefined);
    refetch();
  };

  const closeDialog = () => {
    setIsFormDialogOpen(false);
    setSelectedProperty(undefined);
  };

  return (
    <>
      <div className={styles.header}>
        <H3>Part Properties</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setIsFormDialogOpen(true)}
          text="Create Property"
        />
      </div>
      <Table
        id="part-property-summary"
        columns={columns}
        data={tableData}
        persistColumnVisibility
        enableHiding
        enablePagination
        manualFiltering
        manualPagination
        manualSorting
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        rowActions={rowActions}
        totalRowCount={data?.properties.totalCount}
      />
      <PartPropertyModal
        isOpen={isFormDialogOpen}
        onClose={() => closeDialog()}
        onSuccess={onFormSuccess}
        property={selectedProperty}
      />
    </>
  );
};
