import { Dialog, DialogBody, DialogFooter, InputGroup, Button } from '@blueprintjs/core';
import classNames from 'classnames';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';

interface Props {
  isOpen: boolean
  isCloseButtonShown?: boolean
  onClose: () => void
  title: string
  inputPlaceholder: string
  onOk: (text: string) => void
}

const SimpleInputDialog = (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const inputRef = useRef<HTMLInputElement>(null);

  const onEnter = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      props.onOk(inputRef.current?.value || '');
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [props.isOpen]);

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown={props.isCloseButtonShown}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      onOpened={() => inputRef.current?.focus()}
    >
      <DialogBody>
        <InputGroup
          inputRef={inputRef}
          defaultValue={props.inputPlaceholder}
        />
      </DialogBody>
      <DialogFooter
        actions={(
          <Button
            intent="primary"
            text="OK"
            onClick={() => props.onOk(inputRef.current?.value || '')}
          />
        )}
      />
    </Dialog>
  );
};

export default SimpleInputDialog;
