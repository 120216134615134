import { snakeCase } from 'lodash';
import { SetupField, SetupFieldType } from 'graphql/generated/graphql';

export interface SetupFieldProps {
  path: string,
  label: string,
  type: SetupFieldType,
  setupField: SetupField,
  positionId?: number
}

export default (name: string, setupFields: SetupField[], fieldLabelOverride?: string) => {
  const setupField: SetupField | undefined = setupFields.find(sf => sf.name === name);
  const retPaths: SetupFieldProps[] = [];
  if (!setupField) return retPaths;
  if (setupField.positions && setupField.positions.length) {
    const { type } = setupField;
    setupField.positions.forEach(p => {
      let label = fieldLabelOverride ?? setupField.label;
      const { id } = p;
      if (p.label) label = `${setupField.label} ${p.label}`;
      if (p.path_part) {
        retPaths.push({
          path: `${setupField.path.substring(0, setupField.path.lastIndexOf('.'))}.${p.path_part}`,
          label,
          type,
          setupField,
          positionId: id,
        });
      } else {
        retPaths.push({
          path: `${setupField.path}_${snakeCase(p.label)}`,
          label,
          type,
          setupField,
          positionId: id,
        });
      }
    });
  } else {
    retPaths.push({
      path: setupField.path,
      label: setupField.label,
      type: setupField.type,
      setupField,
    });
  }

  return retPaths;
};
