import React from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import { RHFTextInput } from 'components/RHFInputs';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import {
  CreatePartCategoryInput,
  PartCategory,
  useCreatePartCategoryMutation,
} from 'graphql/generated/graphql';
import { PartCategoryInput } from 'types';

interface Props {
  isOpen: boolean,
  onPartCategoryCreated?: (partCategory: PartCategory) => void,
  onClose: () => void,
}

const defaultCategory: PartCategoryInput = {
  name: '',
  display_name: '',
  description: '',
};

export default (props: Props) => {
  const form = useForm<PartCategoryInput>({ defaultValues: defaultCategory });
  const darkMode = useSelector(selectDarkMode);
  const [createPartCategory] = useCreatePartCategoryMutation();

  const { errors } = form.formState;

  const onSubmit = (input: CreatePartCategoryInput) => {
    createPartCategory({
      variables: { input },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Part category "${input.name}" successfully created`,
        });
        props.onPartCategoryCreated?.(data.partCategory as PartCategory);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating part category: ${e.message}`,
        });
      },
    });
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={() => {
        form.reset(defaultCategory);
        props.onClose();
      }}
      title="Create Part Category"
    >
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <DialogBody>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="name" />}
            label="Name"
          >
            <RHFTextInput
              controllerProps={{
                // There's a typing issue with RHF's `Control` type not inheriting the proper
                // type of the form so it's being typed as `any`
                control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                name: 'name',
                rules: {
                  required: 'Name is required',
                },
              }}
              inputProps={{
                intent: errors.name && Intent.DANGER,
              }}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="display_name" />}
            label="Display Name"
          >
            <RHFTextInput
              controllerProps={{
                control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                name: 'display_name',
                rules: {
                  required: 'Display name is required',
                },
              }}
              inputProps={{
                intent: errors.display_name && Intent.DANGER,
              }}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="description" />}
            label="Description"
          >
            <RHFTextInput
              controllerProps={{
                control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                name: 'description',
              }}
              inputProps={{
                intent: errors.description && Intent.DANGER,
              }}
            />
          </FormGroup>
        </DialogBody>
        <DialogFooter
          actions={(
            <>
              <Button
                onClick={props.onClose}
                text="Cancel"
              />
              <Button
                intent="primary"
                text="Create"
                type="submit"
              />
            </>
          )}
        />
      </form>
    </Dialog>
  );
};
