import { ReactNode } from 'react';
import { Icon, Intent, PopoverPosition } from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16'; // eslint-disable-line camelcase
import { Tooltip2 } from '@blueprintjs/popover2';

import styles from './index.module.css';
import classNames from 'classnames';

interface Props {
  children?: ReactNode;
  className?: string;
  content: JSX.Element | string;
  icon?: BlueprintIcons_16Id; // eslint-disable-line camelcase
  iconIntent?: Intent;
  popoverClassName?: string;
  position?: PopoverPosition;
  tooltipIntent?: Intent;
}

const IconTooltip = (props: Props) => {
  return (
    <Tooltip2
      className={classNames(styles.tooltipTarget, props.className)}
      content={props.content}
      position={props.position}
      popoverClassName={classNames(styles.tooltip, props.popoverClassName)}
      intent={props.tooltipIntent}
    >
      {props.children ?? <Icon tabIndex={-1} icon={props.icon ?? 'help'} intent={props.iconIntent} />}
    </Tooltip2>
  );
};

export default IconTooltip;
