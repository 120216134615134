import { createSlice } from '@reduxjs/toolkit';
import { State } from 'reducers';

export interface SetupColorsState {
  settings: {
    [key: number]: string;
  };
}

export const initialState: SetupColorsState = {
  settings: {},
};

export const SetupColorsSlice = createSlice({
  name: 'setupColors',
  initialState,
  reducers: {
    setSetupColorsState: (state, { payload }) => {
      state.settings = {
        ...state.settings,
        [payload.branchId]: payload.color,
      };
    },
  },
});

export default SetupColorsSlice.reducer;

export const selectSetupColorsState = (state: State) => state.setupColors.settings;
