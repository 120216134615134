import Chart from '../Chart';
import styles from './index.module.css';

interface TireChartProps {
  data: {
    lf: number[][];
    rf: number[][];
    lr: number[][];
    rr: number[][];
  }
}

export default (props: TireChartProps) => {
  const xAxisLabel = 'Slip Angle (deg)';
  const yAxisLabel = 'Combined Normalized Lateral Force (lbf/lbf)';
  const xToolTipLabel = 'Slip Angle';
  const yToolTipLabel = 'Lat Force';

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartRow}>
        <div className={styles.chart}>
          <Chart
            data={props.data.lf.map(d => ({ name: 'Tire LF', x: d[0], y: d[1], id: 1, color: 'blue' }))}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            tooltip
            xToolTipLabel={xToolTipLabel}
            yToolTipLabel={yToolTipLabel}
            title="LF"
          />
        </div>
        <div className={styles.chart}>
          <Chart
            data={props.data.rf.map(d => ({ name: 'Tire RF', x: d[0], y: d[1], id: 1, color: 'blue' }))}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            tooltip
            xToolTipLabel={xToolTipLabel}
            yToolTipLabel={yToolTipLabel}
            title="RF"
          />
        </div>
      </div>
      <div className={styles.chartRow}>
        <div className={styles.chart}>
          <Chart
            data={props.data.lr.map(d => ({ name: 'Tire LR', x: d[0], y: d[1], id: 1, color: 'blue' }))}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            tooltip
            xToolTipLabel={xToolTipLabel}
            yToolTipLabel={yToolTipLabel}
            title="LR"
          />
        </div>
        <div className={styles.chart}>
          <Chart
            data={props.data.rr.map(d => ({ name: 'Tire RR', x: d[0], y: d[1], id: 1, color: 'blue' }))}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            tooltip
            xToolTipLabel={xToolTipLabel}
            yToolTipLabel={yToolTipLabel}
            title="RR"
          />
        </div>
      </div>
    </div>
  );
};
