import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Button, H3, Intent } from '@blueprintjs/core';
import _ from 'lodash';

import { RUN_FIELD_TYPES, runFieldTypeSelectItems } from '../../constants';
import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import {
  RunField,
  useBulkDeleteRunFieldsMutation,
  useDeleteRunFieldMutation,
  useRunFieldManagementTableLazyQuery,
} from 'graphql/generated/graphql';
import { FilterType, RunFieldFormInput } from 'types';
import AppToaster from 'helpers/toaster';
import { selectRunFieldsSummaryView, tableViewSlice } from 'reducers/tableView';
import { useAlert } from 'components/Alert';
import RunFieldModal from 'components/RunFieldModal';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

const columnHelper = createColumnHelper<RunField>();
const columns = [
  columnHelper.accessor('label', {
    header: 'Label',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: info => RUN_FIELD_TYPES[info.getValue()],
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: runFieldTypeSelectItems,
        multiSelect: true,
      },
    },
    size: 100,
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('positions', {
    header: 'Positions',
    cell: info => info.getValue()?.map(p => p.label).join(', '),
    enableSorting: false,
    size: 180,
  }),
  columnHelper.accessor('options', {
    header: 'Options',
    cell: info => {
      const options = info.getValue();
      return options && options.length > 0 ? `${options.length} option(s)` : undefined;
    },
    enableSorting: false,
    size: 180,
  }),
] as AccessorKeyColumnDef<RunField>[];

export default () => {
  useDocumentTitle('Apex Setup - Run Fields');
  const dispatch = useDispatch();
  const { tableFilters, tableSorting } = useSelector(selectRunFieldsSummaryView);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [selectedRunField, setSelectedRunField] = useState<RunFieldFormInput | null>(null);
  const [tableData, setTableData] = useState<RunField[]>([]);

  const [getRunFields, { data, refetch }] = useRunFieldManagementTableLazyQuery();
  const [deleteRunField] = useDeleteRunFieldMutation();
  const [bulkDeleteRunFields] = useBulkDeleteRunFieldsMutation();

  const alert = useAlert();

  useEffect(() => {
    if (data) setTableData(data.runFields.rows as RunField[]);
  }, [data]);

  const bulkRowActions: BulkRowActions<RunField> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.label}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;

        const ids = rows.map(r => r.original.id);
        bulkDeleteRunFields({
          variables: { ids },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Run field(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting run field(s): ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted objects from the cache
              const ids = rows.map(r => cache.identify(r.original));
              ids.forEach(id => cache.evict({ id }));
              cache.gc();
            }
          },
        });
      });
    },
  }];
  const rowActions: RowActions<RunField> = [{
    label: 'Edit',
    value: row => {
      setSelectedRunField(row.original);
      setIsFormDialogOpen(true);
    },
  }, {
    label: 'Clone',
    value: row => {
      setSelectedRunField({
        ...row.original,
        id: undefined,
        positions: row.original.positions?.map(p => ({
          ...p,
          id: undefined,
        })) ?? [],
      });
      setIsFormDialogOpen(true);
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.label}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteRunField({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Run field successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting run field: ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted object from the cache
              cache.evict({ id: cache.identify(row.original) });
              cache.gc();
            }
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setRunFieldsSummaryView({ filters, sorting }));

    getRunFields({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };
  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, 200);

  const onFormSuccess = () => {
    setIsFormDialogOpen(false);
    setSelectedRunField(null);
    refetch();
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Run Fields</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setIsFormDialogOpen(true)}
          text="Create Run Field"
        />
      </div>
      <Table
        id="run-field-summary"
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        persistColumnVisibility
        enableHiding
        enablePagination
        enableRowSelection
        manualFiltering
        manualPagination
        manualSorting
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        rowActions={rowActions}
        totalRowCount={data?.runFields.totalCount}
      />
      <RunFieldModal
        isOpen={isFormDialogOpen}
        onClose={() => {
          setIsFormDialogOpen(false);
          setSelectedRunField(null);
        }}
        onSuccess={onFormSuccess}
        runField={selectedRunField}
      />
    </>
  );
};
