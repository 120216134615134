import { Button, ButtonProps, Intent, MenuItem } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, Select2, Select2Props } from '@blueprintjs/select';
import { Controller, UseControllerProps, useFormContext, useWatch } from 'react-hook-form';

import { SelectItem } from 'types';

interface Props<T> {
  buttonProps?: ButtonProps;
  controllerProps: UseControllerProps;
  disabled?: boolean;
  fill?: boolean;
  intent?: Intent;
  items: SelectItem<T>[];
  noSelectionText?: string;
  selectProps?: Partial<Select2Props<SelectItem<T>>>;
  onChange?: (item: SelectItem<T>) => void;
}

export default <T, >(props: Props<T>) => {
  const { control } = useFormContext();
  const fieldValue = useWatch({ control, name: props.controllerProps.name });
  const selectedItem = props.items.find(i => i.value === fieldValue);

  const filterItem: ItemPredicate<SelectItem<T>> = (query, item) => {
    const normalizedQuery = query.toLowerCase();
    const normalizedTitle = item.label.toLowerCase();
    return normalizedTitle.indexOf(normalizedQuery) >= 0;
  };

  const itemRenderer: ItemRenderer<SelectItem<T>> = (item, { handleClick, index, modifiers }) => {
    if (!modifiers.matchesPredicate) return null;

    return (
      <MenuItem
        key={index}
        onClick={handleClick}
        text={item.label}
      />
    );
  };

  const getSelectedItemText = () => {
    if (selectedItem?.label) return selectedItem.label;
    if (fieldValue) return fieldValue;
    return props.noSelectionText ?? 'Select';
  };

  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => (
        <Select2
          itemPredicate={filterItem}
          itemRenderer={itemRenderer}
          items={props.items}
          onItemSelect={item => {
            props.onChange?.(item);
            field.onChange(item.value);
          }}
          disabled={props.disabled}
          {...props.selectProps}
          fill={props.fill}
        >
          <Button
            disabled={props.disabled}
            intent={props.intent}
            rightIcon="double-caret-vertical"
            text={getSelectedItemText()}
            {...props.buttonProps}
            fill={props.fill}
          />
        </Select2>
      )}
    />
  );
};
