import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, H3, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import {
  CreatePartInput,
  PartProperty,
  Scalars,
  Spec,
  Part,
  useCreatePartMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import styles from '../../pages/PartManagement/AddPart/index.module.css';
import { defaultPart, transformPartPropertyValues } from '../../helpers/part';
import { ErrorMessage } from '@hookform/error-message';
import { RHFDateInput, RHFNumericInput, RHFSelect, RHFTextInput } from '../RHFInputs';
import { organizationSelectItems, seriesItems, specSelectItems, teamSelectItems } from '../../constants';
import { useRouteLoaderData } from 'react-router-dom';
import { find, get } from 'lodash';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onCommitSuccess: (newPart: Part) => void;
  partData: Scalars['JSONObject'];
  partConfigId?: number;
  spec: Spec,
  desc?: string;
}

export default (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const [createPart] = useCreatePartMutation();
  const form = useForm<CreatePartInput>({ defaultValues: defaultPart });
  const { watch, setValue, formState: { errors } } = form;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const teamName = watch('team_name');
  const orgName = watch('organization_name');

  const onClose = () => {
    form.reset(defaultPart);
    props.onClose();
  };

  // if desc is passed a value, use it.
  if (props.desc && props.desc.trim()) {
    form.setValue('description', props.desc);
  }

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  const onSubmit = (input: CreatePartInput) => {
    if (props.partConfigId) input.part_config_id = props.partConfigId;
    if (props.spec)  input.spec = props.spec;
    if (props.partData)  input.data = props.partData;

    const findOrg = find(organizations, org => input.organization_name === org.name);
    const findTeam = find(teams, team => input.team_name === team.name);

    const modifiedInput = {
      ...input,
      data: props.partData,
      properties: transformPartPropertyValues(input.properties as PartProperty[]),
      organization_id: get(findOrg, 'id', null),
      team_id: get(findTeam, 'id', null),
    };

    createPart({
      variables: {
        input: modifiedInput,
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Part successfully added',
        });
        if (data.part) {
          props.onCommitSuccess(data.part as Part);
          onClose();
        }
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error adding part: ${e.message}`,
        });
      },
    });
  };
  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Commit New Part Dialog"
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>

            <div className={styles.headerBar}>
              <H3>Commit New Part</H3>
            </div>
            <div className={styles.mainForm}>
              <div className={styles.columnsContainer}>
                <div className={styles.inputsColumn}>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="organization_name" />}
                    intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
                    label="Organization"
                    labelInfo="(required)"
                  >
                    <RHFSelect
                      controllerProps={{
                        control: form.control as never,
                        name: 'organization_name',
                        rules: {
                          required: 'Organization is required',
                        },
                      }}
                      intent={errors.organization_name && Intent.DANGER}
                      items={organizationSelectItems(organizations)}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="team_name" />}
                    intent={errors.team_name ? Intent.DANGER : Intent.NONE}
                    label="Team"
                  >
                    <RHFSelect
                      controllerProps={{
                        control: form.control as never,
                        name: 'team_name',
                      }}
                      disabled={!orgName}
                      intent={errors.team_name && Intent.DANGER}
                      items={teamSelectItems(teams, orgName)}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="series" />}
                    intent={errors.series ? Intent.DANGER : Intent.NONE}
                    label="Series"
                    labelInfo="(required)"
                  >
                    <RHFSelect
                      controllerProps={{
                        control: form.control as never,
                        name: 'series',
                        rules: {
                          required: 'Series is required',
                        },
                      }}
                      intent={errors.series && Intent.DANGER}
                      items={seriesItems}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="spec" />}
                    label="Spec"
                    labelInfo="(required)"
                  >
                    <RHFSelect
                      controllerProps={{
                        control: form.control as never,
                        name: 'spec',
                        rules: { required: 'Type is required' },
                      }}
                      items={specSelectItems}
                      selectProps={{ fill: true }}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="description" />}
                    label="Description"
                    labelInfo="(required)"
                    intent={errors.description ? Intent.DANGER : Intent.NONE}
                  >
                    <RHFTextInput
                      controllerProps={{
                        control: form.control as never,
                        name: 'description',
                        rules: {
                          required: 'Description is required',
                        },
                      }}
                      inputProps={{
                        intent: errors.description && Intent.DANGER,
                      }}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="part_number" />}
                    intent={errors.part_number ? Intent.DANGER : Intent.NONE}
                    label="Part Number"
                  >
                    <RHFTextInput
                      controllerProps={{
                        control: form.control as never,
                        name: 'part_number',
                      }}
                      inputProps={{
                        intent: errors.part_number && Intent.DANGER,
                      }}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="serial_number" />}
                    intent={errors.serial_number ? Intent.DANGER : Intent.NONE}
                    label="Serial Number"
                  >
                    <RHFTextInput
                      controllerProps={{
                        control: form.control as never,
                        name: 'serial_number',
                      }}
                      inputProps={{
                        intent: errors.serial_number && Intent.DANGER,
                      }}
                    />
                  </FormGroup>

                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="exp_date" />}
                    label="Expiration Date"
                    labelInfo="yyyy-mm-dd"
                  >
                    <RHFDateInput
                      controllerProps={{
                        control: form.control as never,
                        name: 'exp_date',
                        rules: {
                          pattern: {
                            value: /\d{4}-\d{2}-\d{2}/,
                            message: 'Expiration Date must be in the format yyyy-mm-dd',
                          },
                        },
                      }}
                      intent={errors.exp_date && Intent.DANGER}
                    />
                  </FormGroup>
                  <FormGroup
                    helperText={<ErrorMessage errors={errors} name="mileage" />}
                    intent={errors.mileage ? Intent.DANGER : Intent.NONE}
                    label="Mileage"
                  >
                    <RHFNumericInput
                      controllerProps={{
                        control: form.control as never,
                        name: 'mileage',
                        rules: {
                          min: {
                            value: 0,
                            message: 'Mileage cannot be less than zero',
                          },
                        },
                      }}
                      inputProps={{
                        fill: true,
                        intent: errors.mileage && Intent.DANGER,
                      }}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
          </DialogBody>
        </form>
      </FormProvider>
      <DialogFooter
        actions={(
          <>
            <Button
              onClick={onClose}
              text="Cancel"
            />
            <Button
              intent={Intent.PRIMARY}
              onClick={(event) => {
                // Prevents the default form submission behavior
                event.preventDefault();

                // Calls handleSubmit with onSubmit, which internally handles the form data
                form.handleSubmit(onSubmit)();
              }}
              key="submit"
              text="Commit"
            />
          </>
        )}
      />
    </Dialog>
  );
};
