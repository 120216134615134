const closeBrowserWarning = (e: BeforeUnloadEvent) => {
  const event = e;
  event.returnValue = '';
  return event.returnValue;
};

export function addWarningListener() {
  window.addEventListener('beforeunload', closeBrowserWarning);
}

export function removeWarningListener() {
  window.removeEventListener('beforeunload', closeBrowserWarning);
}

export default {
  addWarningListener,
  removeWarningListener,
};
