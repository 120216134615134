import { compact } from 'lodash';

import { TemplateMeta } from 'components/SetupCard';
import getSetupFieldProps from 'helpers/setupField';
import { SetupField } from 'graphql/generated/graphql';

import {
  SetupUITemplateContainerItem,
  SetupUITemplateItem,
  SetupUITemplateItemType,
} from 'types';

export const suitPathToKey = (path: SetupUITemplateContainerItem[]) => {
  return path.map(p => p.name).join('.');
};

// Given an array of `SetupUITemplateItem` representing a nested path, returns a
// flattened array of all of the generated container item paths as strings
//
// e.g.:
// Args:    [{ label: 'Car', items: [{ label: 'Chassis' }] }]
// Returns: ['car', 'car.chassis']
export const getContainerPathKeys = (path: SetupUITemplateItem[]): string[] | null => {
  const item = path[path.length - 1];
  if (item.type === SetupUITemplateItemType.FIELD) return null;
  const children = compact(item.items.map(i => getContainerPathKeys([...path, i]))).flat();
  const key = suitPathToKey(path as SetupUITemplateContainerItem[]);
  return [key, ...children];
};

export const generateTemplateMeta = (path: SetupUITemplateItem[], paths: TemplateMeta, setupFields: SetupField[], fieldVisibility = true) => {
  const item = path[path.length - 1];
  if (item.type === SetupUITemplateItemType.CONTAINER) {
    paths.containers[suitPathToKey(path as SetupUITemplateContainerItem[])] = {
      open: true,
      visible: true,
    };
    item.items.map(i => generateTemplateMeta([...path, i], paths, setupFields, fieldVisibility));
  } else {
    const setupFieldProps = getSetupFieldProps(item.setup_field, setupFields);
    setupFieldProps.forEach(p => {
      paths.fields[p.path] = {
        visible: fieldVisibility,
      };
    });
  }
};
