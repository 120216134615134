import { ReactNode, cloneElement, ReactElement } from 'react';
import styles from './index.module.css';

interface CornerInputsProps {
  label: string;
  children: ReactNode,
}

interface CornerInputsChildRequiredProps {
  id: string;
}

type CornerInputsChildProps = CornerInputsChildRequiredProps & Record<string, unknown>;

export const CornerInputsSuffixes = {
  lf: '_lf',
  rf: '_rf',
  lr: '_lr',
  rr: '_rr',
};

export default (props: CornerInputsProps) => {
  const children = props.children as ReactElement<CornerInputsChildProps>;
  const { id, ...rest } = children.props;

  const childrenElements: ReactNode[] = [];
  Object.keys(CornerInputsSuffixes).forEach(k => {
    const component = cloneElement(children, {
      id: `${id}${CornerInputsSuffixes[k as keyof typeof CornerInputsSuffixes]}`,
      ...rest,
    });
    childrenElements.push(
      <div className={styles.gridFieldValue}>
        {component}
      </div>
    );
  });

  return (
    <div className={styles.fieldGridContainer}>
      <div className={styles.fieldGridLabel}>{props.label}</div>
      <div className={styles.fieldGrid}>
        {childrenElements}
      </div>
    </div>
  );
};
