import { createSlice } from '@reduxjs/toolkit';
import { State } from 'reducers';

export interface SetupCompareState {
  settings: {
    [key: number]: number[];
  };
}

export const initialState: SetupCompareState = {
  settings: {},
};

export const SetupCompareSlice = createSlice({
  name: 'setupCompare',
  initialState,
  reducers: {
    setSetupCompareState: (state, { payload }) => {
      state.settings = payload;
    },
  },
});

export default SetupCompareSlice.reducer;

export const selectSetupCompareState = (state: State) => state.setupCompare.settings;
