import ui, { UIState } from './ui';
import ruit, { RUITState } from './ruit';
import setupColors, { SetupColorsState } from './setupColors';
import setupCompare, { SetupCompareState } from './setupCompare';
import suit, { SUITState } from './suit';
import tableView, { TableViewState } from './tableView';

export interface State {
  ui: UIState;
  ruit: RUITState;
  setupColors: SetupColorsState;
  setupCompare: SetupCompareState;
  suit: SUITState;
  tableView: TableViewState;
}

export default {
  ui,
  ruit,
  setupColors,
  setupCompare,
  suit,
  tableView,
};
