import { Dialog, DialogBody, DialogFooter, Button, FormGroup, Intent } from '@blueprintjs/core';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { RHFSelect, RHFTextInput } from 'components/RHFInputs';
import { CreateSUITInput } from 'graphql/generated/graphql';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import styles from './index.module.css';
import { useRouteLoaderData } from 'react-router-dom';
import { find, get } from 'lodash';
import { specSelectItems, seriesItems, teamSelectItems, organizationSelectItems } from '../../constants';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onOk: (input: CreateSUITInput) => void,
}

const CreateSUITDialog = (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const nameRef = useRef<HTMLInputElement>(null);
  const form = useForm<Partial<CreateSUITInput>>();
  const { reset, setValue, watch } = form;
  const teamName = watch('team_name');
  const orgName = watch('organization_name');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  const onSubmit = (input: Partial<CreateSUITInput>) => {
    const findOrg = find(organizations, org => orgName === org.name);
    const newSUIT = {
      ...input,
      organization_id: get(findOrg, 'id', null),
      team_id: get(findTeam, 'id', null),
    };
    props.onOk(newSUIT as CreateSUITInput);
    reset();
    props.onClose();
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="New SUIT"
      onOpened={() => nameRef.current?.focus()}
      style={{ width: '50%' }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              helperText={<ErrorMessage errors={form.formState.errors} name="organization_name" />}
              intent={form.formState.errors.organization_name ? Intent.DANGER : Intent.NONE}
              label="Organization"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'organization_name',
                  rules: {
                    required: 'Organization is required',
                  },
                }}
                intent={form.formState.errors.organization_name && Intent.DANGER}
                items={organizationSelectItems(organizations)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={form.formState.errors} name="team_name" />}
              intent={form.formState.errors.team_name ? Intent.DANGER : Intent.NONE}
              label="Team"
            >
              <RHFSelect
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'team_name',
                }}
                disabled={!orgName}
                intent={form.formState.errors.team_name && Intent.DANGER}
                items={teamSelectItems(teams, orgName)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={form.formState.errors} name="series" />}
              intent={form.formState.errors.series ? Intent.DANGER : Intent.NONE}
              label="Series"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'series',
                  rules: {
                    required: 'Series is required',
                  },
                }}
                intent={form.formState.errors.series && Intent.DANGER}
                items={seriesItems}
              />
            </FormGroup>
            <FormGroup
              contentClassName={styles.selectFormGroup}
              label="Spec"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  // There's a typing issue with RHF's `Control` type not inheriting the proper
                  // type of the form (`SUITInput` here) so it's being typed as `any`
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'spec',
                }}
                intent={form.formState.errors.spec && Intent.DANGER}
                items={specSelectItems}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={form.formState.errors} name="name" />}
              label="Name"
              labelInfo="(required)"
            >
              <RHFTextInput
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'name',
                  rules: { required: 'Name is required' },
                }}
                inputProps={{
                  intent: form.formState.errors.name && Intent.DANGER,
                }}
              />
            </FormGroup>
            <FormGroup label="Description">
              <RHFTextInput
                controllerProps={{
                  control: form.control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                  name: 'description',
                }}
              />
            </FormGroup>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                text="Cancel"
                onClick={props.onClose}
              />,
              <Button
                intent="primary"
                text="OK"
                type="submit"
              />]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default CreateSUITDialog;
