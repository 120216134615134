import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import { isEmpty, each, keys } from 'lodash';

import Table from 'components/Table';
import {
  Run,
  useSearchRunsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';

const columns = [
  {
    header: 'Setup',
    accessorKey: 'branch.head.name',
  },
  {
    header: 'Year',
    accessorKey: 'branch.head.year',
  },
  {
    header: 'Track',
    accessorKey: 'branch.head.track',
  },
  {
    header: 'Event',
    accessorKey: 'branch.head.event',
  },
  {
    header: 'Session',
    accessorKey: 'branch.head.session',
  },
  {
    header: 'Description',
    accessorKey: 'branch.head.description',
  },
  {
    header: 'Owner',
    accessorKey: 'branch.head.owner',
  },
  {
    header: 'Branch',
    accessorKey: 'branch.name',
  },
] as ColumnDef<Run>[];

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (runs: Run[]) => void;
}

export default (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const [tableData, setTableData] = useState<Run[]>([]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState({});

  const [
    getRuns, {
      data: runsData,
    }] = useSearchRunsLazyQuery();

  useEffect(() => {
    getRuns({ fetchPolicy: 'no-cache' });
  }, []);

  useEffect(() => {
    setTableData(runsData?.runs as Run[]);
  }, [runsData]);

  useEffect(() => {
    if (isEmpty(selectedRows)) {
      setIsAddDisabled(true);
    } else {
      setIsAddDisabled(false);
    }
  }, [selectedRows]);

  const onSelectionChange = (selections: RowSelectionState) => {
    setSelectedRows(selections);
  };

  const onSubmit = () => {
    const selectedRuns: Run[] = [];

    each(keys(selectedRows), (index: string) => {
      selectedRuns.push(tableData[Number(index)]);
    });

    props.onSuccess(selectedRuns);
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Add Run(s)"
      style={{ width: '80%', maxWidth: 'none' }}
    >
      <DialogBody>
        <Table
          columns={columns}
          data={tableData}
          enableRowSelection
          onRowSelect={onSelectionChange}
        />
      </DialogBody>
      <DialogFooter
        actions={[
          <Button key="cancel" onClick={props.onClose} text="Cancel" />,
          <Button
            key="add"
            disabled={isAddDisabled}
            intent={Intent.PRIMARY}
            onClick={onSubmit}
            text="Add"
          />,
        ]}
      />
    </Dialog>
  );
};
