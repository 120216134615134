import { useSelector } from 'react-redux';
import { Dialog, DialogBody } from '@blueprintjs/core';
import { Gitgraph, TemplateName, templateExtend } from '@gitgraph/react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { sortBy } from 'lodash';

import { SetupHistoryQuery, useSetupHistoryQuery } from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import { useEffect } from 'react';

type Setup = SetupHistoryQuery['setupHistory']['setups'][number];
type SetupBranch = SetupHistoryQuery['setupHistory']['branches'][number];

const generateSetupCommit = (setup: Setup) => {
  const subject = format(new Date(setup.updated_at), 'MM/dd/yyyy h:mm a');
  return {
    subject,
    author: setup.meta.user,
  };
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  rootId: number;
}

export default (props: Props) => {
  const darkMode = useSelector(selectDarkMode);

  const { data, refetch } = useSetupHistoryQuery({
    variables: { rootId: props.rootId },
  });

  useEffect(() => {
    if (props.isOpen) refetch();
  }, [props.isOpen]);

  const withoutHash = templateExtend(TemplateName.Metro, {
    branch: {
      lineWidth: 5,
      spacing: 35,
      label: {
        bgColor: 'transparent',
        borderRadius: 5,
        font: '11pt sans-serif',
      },
    },
    commit: {
      spacing: 50,
      message: {
        displayHash: false,
        font: '13pt sans-serif',
      },
      dot: {
        size: 10,
        strokeWidth: 2,
        strokeColor: '#FFF',
      },
    },
    /**
     *   #%%%%%%%%%%@///////////////@,,,,,,,,,,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@# ,
     *   %%%%%%%%%%%%%@///////////////@,,,,,,,,,,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,
     *   %%%%%%%%%%#%#%@///////////////@,,,,,,,,,,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,
     *   %%%%%.                      @                    #@                    .@@@@@,
     *   %%%%%@@@@@@@@@@    @@@@@@@@@    @(%@@@@@@@@@@@@   @   @@@@@@@@@@@@@@    @@@@@,
     *   %%%%%%%%%%%%%%&    @////////    @                @@   @@@@@@@@@@@@@@    @@@@@,
     *   %%%%%%%%%%%%%%&    @////////    @             %@@@@                     @@@@@,
     *   %%%%%%%%%%%%%%&    @////////    @/&@%%%%%%%#     @@                   @@@@@@@,
     *   %%%%%%%%%%%%%%%%%%%%@///////    @///@,,,,,,,,,@     @@@@@@@@@@@@@@@@@@@@@@@@@,
     *   %%%%%%%%%%%%%%%%%%%%%&//////@@@@@////&,,,,,,,,,,@@@@@@@@@@@@@@@@@@@@@@@@@@@@@,
     *  ,%%%%%%%%%%%%%%%%%%%%%%///////////////#,,,,,,,,,,@@@@@@@@@@@@@@@@@@@@@@@@@@@
     */
    // Red, orange, yellow, grey
    colors: ['#B8271C', '#F36307', '#F7C500', '#8C8C8C'],
  });

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode }, styles.container)}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Setup History"
    >
      <DialogBody>
        <Gitgraph
          options={{
            template: withoutHash,
          }}
          key={`gitgraph-${data?.setupHistory.setups.length ?? 0}`}
        >
          {gitGraph => {
            gitGraph.clear();

            if (data) {
              const { branches, setups } = data.setupHistory;

              const sortedSetups = sortBy(setups, ['created_at'], ['desc']) as Setup[];
              const setupsAndBranches: [Setup, SetupBranch][] = [];
              for (let i = 0; i < branches.length; i++) {
                const thisBranch = branches[i];
                let nextSetupId: number | undefined = branches[i].head.id;
                while (nextSetupId) {
                  const setup = sortedSetups.find(s => s.id === nextSetupId); // eslint-disable-line no-loop-func
                  nextSetupId = setup?.parent?.id;
                  if (setup && setup.id !== thisBranch.tail?.id) {
                    setupsAndBranches.push([setup, thisBranch as SetupBranch]);
                  } else if (setup && setup.id === thisBranch.tail?.id) {
                    break;
                  }
                }
              }
              setupsAndBranches.sort(([a], [b]) => {
                if (a.created_at === b.created_at) return a.id - b.id;
                return a.created_at > b.created_at ? 1 : -1;
              });

              const graphBranches = new Map<string, ReturnType<typeof gitGraph.branch>>();
              graphBranches.set('main', gitGraph.branch('main'));
              setupsAndBranches.forEach(([setup, branch]) => {
                const commitInfo = generateSetupCommit(setup);
                const graphBranch = graphBranches.get(branch.name);

                if (!graphBranch) return;

                // In GitGraph, merges create their own commit via `.merge`,
                // otheruse it's a straightforward `.commit`
                const mergeSetup = setupsAndBranches.find(([s]) => s.id === setup.meta.merged_from_setup?.id);
                if (mergeSetup) {
                  const mergeFromBranch = graphBranches.get(mergeSetup[1].name);
                  if (!mergeFromBranch) return;
                  graphBranch.merge({
                    branch: mergeFromBranch,
                    commitOptions: { ...commitInfo },
                  });
                } else {
                  graphBranch.commit(commitInfo);
                }

                const branchesFromThisCommit = branches.filter(b => b.tail?.id === setup.id);
                branchesFromThisCommit.forEach(b => {
                  graphBranches.set(b.name, gitGraph.branch(b.name));
                });
              });
            }
          }}
        </Gitgraph>
      </DialogBody>
    </Dialog>
  );
};
