import axios from 'axios';
import { apiBasePath } from 'config';
import { getTokenFromCookies } from '../graphql';

export const call = (
  verb: string,
  route: string,
  body?: Record<string, unknown>,
) => {
  let path;
  // Need to call auth server without api base path (/api)
  if (route.includes('/auth')) {
    path = route;
  } else {
    path = `${apiBasePath}/${route.startsWith('/') ? route.slice(1) : route}`;
  }
  const headers = {
    'Cookie': `apex_platform_token=${getTokenFromCookies()}`,
    'Content-Type': 'application/json',
  };
  switch (verb) {
    case 'put':
      return axios.put(path, body, { headers });
    case 'post':
      return axios.post(path, body, { headers });
    case 'delete':
      return axios.delete(path, { headers });
    case 'get':
    default:
      return axios.get(path);
  }
};

export const ApiRequest = {
  get: (route: string) => {
    return call('get', route);
  },
  put: (route: string, body?: Record<string, unknown>) => {
    return call('put', route, body);
  },
  post: (route: string, body?: Record<string, unknown>) => {
    return call('post', route, body);
  },
  delete: (route: string) => {
    return call('delete', route);
  },
};
