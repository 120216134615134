import { Provider } from 'react-redux';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import client from './graphql';
import { store } from './store';
import routes from './routes';
import { baseName } from './config';
import { AlertProvider } from 'components/Alert';
import { SubscriptionProvider } from './context/SubscriptionContext';

const router = createBrowserRouter(routes, { basename: baseName });

export default () => (
  <ApolloProvider client={client}>
    <SubscriptionProvider>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <AlertProvider>
            <RouterProvider router={router} />
          </AlertProvider>
        </DndProvider>
      </Provider>
    </SubscriptionProvider>
  </ApolloProvider>
);
