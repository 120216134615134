import { createSlice } from '@reduxjs/toolkit';
import { State } from 'reducers';

export interface RUITState {
  settings: {
    activeRUITId?: number;
  };
}

export const initialState: RUITState = {
  settings: {
    activeRUITId: undefined,
  },
};

export const RUITSlice = createSlice({
  name: 'suit',
  initialState,
  reducers: {
    setActiveRUITId: (state, { payload }) => {
      state.settings.activeRUITId = payload;
    },
  },
});

export default RUITSlice.reducer;

export const selectActiveRUITId = (state: State) => state.ruit.settings.activeRUITId;
