import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import {
  apiBasePath,
  env,
} from '../config';

const socketPath = document.location.host.includes('local')
  ? 'ws://localhost:4000'
  : `wss://${document.location.host}`;

const httpLink = createHttpLink({
  uri: `${apiBasePath}/graphql`,
});

export const getTokenFromCookies = () => {
  const cookies = document.cookie.split('; ').map(c => c.split('='));
  return cookies.find(([cookieName]) => cookieName === 'apex_platform_token')?.[1];
};

const authLink = setContext((_, { headers }) => {
  const token = getTokenFromCookies();
  if (!token) throw new Error('Not logged in');

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logoutLink = onError((err: any) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (err.networkError?.result?.errors?.some((e: any) => e.extensions.code === 'UNAUTHENTICATED')) {
    // toaster.show({ bg: 'error' }, 'Unauthenticated', 'Session expired. Please log in again.');
    // localStorage.removeItem('user_details');
    // setTimeout(() => window.location.reload(), 3000); // Give the user a second to see why they're being logged out.
  }
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${socketPath}/api/subscriptions`,
    connectionParams: () => {
      const token = getTokenFromCookies();
      return {
        authorization: `Bearer ${token}`,
      };
    },
  }),
);

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  wsLink, // web socket connection for subscriptions
  httpLink // http connection for query and mutation
);

const client = new ApolloClient({
  link: from([
    authLink,
    logoutLink,
    // Strips the GraphQL internal __typename field from any outgoing payloads
    new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = JSON.parse(
          JSON.stringify(operation.variables),
          (key: string, value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
            return key === '__typename' ? undefined : value;
          },
        );
      }
      return forward(operation);
    }),
    splitLink,
  ]),
  cache: new InMemoryCache(),
  connectToDevTools: env !== 'production',
});

export default client;
