import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AccessorKeyColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Button, Checkbox, H3, Intent } from '@blueprintjs/core';
import _ from 'lodash';

import { useAlert } from 'components/Alert';
import { seriesItems, SETUP_FIELD_TYPES, setupFieldTypeSelectItems, specItems } from '../../constants';
import SetupFieldModal from 'components/SetupFieldModal';
import Table, { BulkRowActions, ParamsChangeFn, RowActions } from 'components/Table';
import {
  PartConfig,
  SetupField,
  useBulkDeleteSetupFieldsMutation,
  useDeleteSetupFieldMutation,
  usePartConfigsQuery,
  useSetupFieldManagementTableLazyQuery,
} from 'graphql/generated/graphql';
import { FilterType, SetupFieldFormInput } from 'types';
import AppToaster from 'helpers/toaster';
import { selectSetupFieldsSummaryView, tableViewSlice } from 'reducers/tableView';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

const columnHelper = createColumnHelper<SetupField>();
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('label', {
    header: 'Label',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('organization_name', {
    header: 'Organization',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('team_name', {
    header: 'Team',
    cell: info => info.getValue(),
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('series', {
    header: 'Series',
    cell: info => {
      const getSeries = _.find(seriesItems, series => series.value === info.getValue());
      if (!getSeries) return '';
      return getSeries.label;
    },
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: seriesItems,
        multiSelect: true,
      },
    },
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('specs', {
    header: 'Spec',
    cell: info => info.getValue(),
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: specItems,
        multiSelect: true,
      },
    },
    enableColumnFilter: true,
    size: 100,
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: info => SETUP_FIELD_TYPES[info.getValue()],
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.SELECT,
        selectItems: setupFieldTypeSelectItems,
        multiSelect: true,
      },
    },
    size: 100,
  }),
  columnHelper.accessor('part_config', {
    header: 'Part Type',
    cell: info => info.getValue()?.display_name,
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.SELECT,
        multiSelect: true,
      },
    },
    size: 200,
  }),
  columnHelper.accessor('path', {
    header: 'Path',
    cell: info => info.getValue(),
    enableColumnFilter: true,
  }),
  columnHelper.accessor('positions', {
    header: 'Positions',
    cell: info => info.getValue()?.map(p => p.label).join(', '),
    enableSorting: false,
    size: 180,
  }),
  columnHelper.accessor('options', {
    header: 'Options',
    cell: info => {
      const options = info.getValue();
      return options && options.length > 0 ? `${options.length} option(s)` : undefined;
    },
    enableSorting: false,
    size: 180,
  }),
  columnHelper.accessor('use_in_sim', {
    header: 'Sim',
    cell: info => (
      <Checkbox
        checked={info.getValue()}
        className={styles.tableCheckbox}
        disabled
      />
    ),
    enableColumnFilter: true,
    meta: {
      filter: {
        type: FilterType.BOOLEAN,
      },
    },
    size: 75,
  }),
] as AccessorKeyColumnDef<SetupField>[];

export default () => {
  useDocumentTitle('Apex Setup - Setup Fields');
  const dispatch = useDispatch();
  const { tableFilters, tableSorting } = useSelector(selectSetupFieldsSummaryView);
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [partConfigs, setPartConfigs] = useState<PartConfig[]>([]);
  const [selectedSetupField, setSelectedSetupField] = useState<SetupFieldFormInput | null>(null);
  const [tableData, setTableData] = useState<SetupField[]>([]);

  usePartConfigsQuery({
    onCompleted: data => setPartConfigs(data.partConfigs.rows as PartConfig[]),
  });
  const [getSetupFields, { data, refetch }] = useSetupFieldManagementTableLazyQuery();
  const [deleteSetupField] = useDeleteSetupFieldMutation();
  const [bulkDeleteSetupFields] = useBulkDeleteSetupFieldsMutation();

  const alert = useAlert();

  // Once, since possible part types is coming from API and cannot be statically
  // defined, adds them to the filter meta once the component is initialized
  useEffect(() => {
    const partConfigColumn = columns.find(c => c.accessorKey === 'part_config');
    if (partConfigColumn?.meta?.filter) {
      partConfigColumn.meta.filter.selectItems = partConfigs.map(c => ({
        label: c.display_name,
        value: c.id,
      }));
    }
  }, [partConfigs]);

  useEffect(() => {
    if (data) setTableData(data.setupFields.rows as SetupField[]);
  }, [data]);

  const bulkRowActions: BulkRowActions<SetupField> = [{
    intent: Intent.DANGER,
    label: 'Delete',
    value: rows => {
      const content = (
        <>
          <p>Delete these?</p>
          <ul>
            {rows.map(r => <li>{r.original.label}</li>)}
          </ul>
        </>
      );
      alert.showAlert(content, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;

        bulkDeleteSetupFields({
          variables: { ids: rows.map(r => r.original.id) },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Setup field(s) successfully deleted',
            });
            rows.forEach(r => r.toggleSelected());
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting setup field(s): ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted objects from the cache
              const ids = rows.map(r => cache.identify(r.original));
              ids.forEach(id => cache.evict({ id }));
              cache.gc();
            }
          },
        });
      });
    },
  }];
  const rowActions: RowActions<SetupField> = [{
    label: 'Edit',
    value: row => {
      setSelectedSetupField(row.original);
      setIsFormDialogOpen(true);
    },
  }, {
    label: 'Clone',
    value: row => {
      setSelectedSetupField({
        ...row.original,
        id: undefined,
        positions: row.original.positions?.map(p => ({
          ...p,
          id: undefined,
        })) ?? [],
      });
      setIsFormDialogOpen(true);
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      alert.showAlert(`Delete "${row.original.label}"?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteSetupField({
          variables: { id: row.original.id },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Setup field successfully deleted',
            });
            if (row.getIsSelected()) row.toggleSelected();
            refetch();
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Error deleting setup field: ${e.message}`,
            });
          },
          update: (cache, { data: mutationData }) => {
            if (mutationData?.deleteCount) {
              // Evicts all references to the deleted object from the cache
              cache.evict({ id: cache.identify(row.original) });
              cache.gc();
            }
          },
        });
      });
    },
  }];

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setSetupFieldsSummaryView({ filters, sorting }));

    getSetupFields({
      variables: {
        input: {
          filters: _.mapValues(_.keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };
  const debouncedOnTableParamsChange = _.debounce(onTableParamsChange, 200);

  const onFormSuccess = () => {
    setIsFormDialogOpen(false);
    setSelectedSetupField(null);
    refetch();
  };

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Setup Fields</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setIsFormDialogOpen(true)}
          text="Create Setup Field"
        />
      </div>
      <Table
        bulkRowActions={bulkRowActions}
        columns={columns}
        data={tableData}
        enableHiding
        enablePagination
        enableRowSelection
        id="setup_field_management"
        manualFiltering
        manualPagination
        manualSorting
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        persistColumnVisibility
        rowActions={rowActions}
        totalRowCount={data?.setupFields.totalCount}
      />
      <SetupFieldModal
        isOpen={isFormDialogOpen}
        onClose={() => {
          setIsFormDialogOpen(false);
          setSelectedSetupField(null);
        }}
        onSuccess={onFormSuccess}
        partConfigs={partConfigs}
        setupField={selectedSetupField}
      />
    </>
  );
};
