import { Intent } from '@blueprintjs/core';
import { BlueprintIcons_16Id } from '@blueprintjs/icons/lib/esm/generated-icons/16px/blueprint-icons-16';
import { RowData } from '@tanstack/react-table';
import { ReactNode } from 'react';

import {
  CreateSetupFieldInput,
  CreateSUITInput,
  UpdateSetupFieldInput,
  UpdateSUITInput,
  SetupByIdQuery,
  CreatePartConfigInput,
  UpdatePartConfigInput,
  SetupBranchHeadByBranchIdQuery,
  Setup,
  CreatePropertyInput,
  CreatePartCategoryInput,
  UpdatePropertyInput,
  SetupField,
  SetupFieldPosition,
  RunFieldPosition,
  CreateRunFieldInput,
  UpdateRunFieldInput,
  RunField,
  CreateRUITInput,
  UpdateRUITInput,
  SUIT,
  SimWorksheetByIdQuery,
  useSimOutlineByIdQuery,
  SimOutlineByIdQuery,
  EnvironmentByIdQuery,
} from 'graphql/generated/graphql';
import { Optional } from 'utility-types';

export type PromiseType<T> = T extends Promise<infer U> ? U : never;

export enum FilterType {
  BOOLEAN,
  NUMBER,
  SELECT,
  TEXT,
}
declare module '@tanstack/table-core' {
  // Merge with the default `ColumnMeta` to provide acceptable typings for
  // `ColumnDef.meta`
  // See: https://tanstack.com/table/v8/docs/api/core/column-def#meta
  //
  // NOTE: The generics need to remain in place even when unused
  interface ColumnMeta<TData extends RowData, TValue> {
    initiallyHidden?: boolean;
    filter?: {
      type: FilterType;
      selectItems?: SelectItem<unknown>[];
      multiSelect?: boolean;
    },
  }
}

/**
 * Part
 */
export interface Part {
  id: number;
  type: string;
  description: string;
}
export type PartConfigInput = CreatePartConfigInput | UpdatePartConfigInput;

/**
 * Setup UI Template
 */
export enum SetupUITemplateItemType {
  CONTAINER = 'container',
  FIELD = 'field',
}
export interface SetupUITemplateContainerItem {
  type: SetupUITemplateItemType.CONTAINER;
  name: string;
  label?: string;
  items: SetupUITemplateItem[];
}
export interface SetupUITemplateFieldItem {
  type: SetupUITemplateItemType.FIELD;
  label?: string;
  setup_field: string;
  num_columns?: number;
}
export type SetupUITemplateItem = SetupUITemplateContainerItem | SetupUITemplateFieldItem;
export interface SetupUITemplate {
  items: SetupUITemplateItem[];
}

/**
 * Run UI Template
 */
export enum RunUITemplateItemType {
  COLUMN = 'column',
  FIELD = 'field',
}
export interface RunUITemplateColumnItem {
  type: RunUITemplateItemType.COLUMN;
  col_span?: number;
  items: RunUITemplateItem[];
}
export interface RunUITemplateFieldItem {
  type: RunUITemplateItemType.FIELD;
  label?: string;
  run_field: string;
  num_columns?: number;
}
export type RunUITemplateItem = RunUITemplateColumnItem | RunUITemplateFieldItem;
export interface RunUITemplate {
  items: RunUITemplateItem[];
}

/**
 * Setup
 */
export type GQLSetup = SetupByIdQuery['setup'];
export type GQLSetupHead = SetupBranchHeadByBranchIdQuery['branchHead'] & {
  subRows?: GQLSetupHead[];
};
export interface SetupMainData {
  setup: any;
  initialBranch: any;
  branches: any[];
  suit: any;
  setupFields: any[];
  suitList: any[]
}
export interface SetupMergeData {
  setup: any;
  initialBranch: any;
  branches: any[];
  suit: any;
  setupFields: any;
}
export interface SetupCompareLoadedData {
  setup: Setup;
  compareSetups: Setup[];
}
export type SetupSelection = {
  branch: {
    id: number;
    name: string;
  };
  setup: GQLSetup;
}

/**
 * Setup Field
 */
export type SetupFieldFormInput = Optional<Omit<SetupField, 'positions'>, 'id'> & {
  positions?: Optional<SetupFieldPosition, 'id'>[] | null;
}

/**
 * Run Field
 */
export type RunFieldFormInput = Optional<Omit<RunField, 'positions'>, 'id'> & {
  positions?: Optional<RunFieldPosition, 'id'>[] | null;
}

/**
 * SimWorksheet
 */
export type GQLSimWorksheet = NonNullable<SimWorksheetByIdQuery['simWorksheet']>;
export type GQLSimOutline = SimOutlineByIdQuery['simOutline'];

/**
 * Environment
 */
export type GQLEnvironment = NonNullable<EnvironmentByIdQuery['environment']>;

/**
 * Sim Documents
 */
export type SimDocumentType = {
  displayName: string;
  name: string;
}

export type SimDocumentCategory = {
  displayName: string;
  name: string;
  types: SimDocumentType[];
}

/**
 * Misc
 */
export interface SelectItem<T> {
  intent?: Intent;
  label: string;
  value: T;
  id?: string,
  labelElement?: ReactNode;
}

export interface TableRowAction<T> {
  intent?: Intent
  label: React.ReactNode
  icon?: (arg: T) => BlueprintIcons_16Id | undefined | null
  value: (arg: T) => void
}

export type SetupFieldInput = CreateSetupFieldInput | UpdateSetupFieldInput;
export type SUITInput = CreateSUITInput | UpdateSUITInput;
export type PropertyInput = CreatePropertyInput | UpdatePropertyInput;
export type PartCategoryInput = CreatePartCategoryInput;
export type RunFieldInput = CreateRunFieldInput | UpdateRunFieldInput;
export type RUITInput = CreateRUITInput | UpdateRUITInput;

export type SUITNoTemplate = Optional<SUIT, 'template'>;

interface GraphQLErrorLocation {
  line: number;
  column: number;
}

interface GraphQLErrorExtension {
  code: string;
  stacktrace: string[];
}

interface GraphQLError {
  message: string;
  locations?: GraphQLErrorLocation[];
  extensions?: GraphQLErrorExtension;
}

export interface GraphQLErrorResponse {
  graphQLErrors: GraphQLError[];
}

export type GenericRunField = {
  label: string;
  name: string;
}

export type ValidRunField = RunField | GenericRunField;
