import { Dialog, DialogBody, DialogFooter, Button, FormGroup, Intent } from '@blueprintjs/core';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { RHFNumericInput, RHFSelect, RHFTextInput } from 'components/RHFInputs';
import { SimWorksheet } from 'graphql/generated/graphql';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import { GQLSimWorksheet } from 'types';
import styles from './index.module.css';
import { useRouteLoaderData } from 'react-router-dom';
import { organizationSelectItems, seriesItems, teamSelectItems } from '../../constants';
import { find } from 'lodash';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onOk: (input: GQLSimWorksheet) => void,
}

const AddSimWorksheetDialog = (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const nameRef = useRef<HTMLInputElement>(null);
  const form = useForm<Partial<SimWorksheet>>();
  const { control, reset, watch, setValue, formState: { errors } } = form;

  const teamName = watch('team_name');
  const orgName = watch('organization_name');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  const onSubmit = (input: Partial<SimWorksheet>) => {
    props.onOk(input as GQLSimWorksheet);
    reset();
    props.onClose();
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="New worksheet"
      onOpened={() => nameRef.current?.focus()}
      style={{ width: '50%' }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="organization_name" />}
              intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
              label="Organization"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'organization_name',
                  rules: {
                    required: 'Organization is required',
                  },
                }}
                intent={errors.organization_name && Intent.DANGER}
                items={organizationSelectItems(organizations)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="team_name" />}
              intent={errors.team_name ? Intent.DANGER : Intent.NONE}
              label="Team"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'team_name',
                }}
                disabled={!orgName}
                intent={errors.team_name && Intent.DANGER}
                items={teamSelectItems(teams, orgName)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="series" />}
              intent={errors.series ? Intent.DANGER : Intent.NONE}
              label="Series"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'series',
                  rules: {
                    required: 'Series is required',
                  },
                }}
                intent={errors.series && Intent.DANGER}
                items={seriesItems}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="name" />}
              label="Name"
              labelInfo="(required)"
            >
              <RHFTextInput
                controllerProps={{
                  control,
                  name: 'name',
                  rules: { required: 'Name is required' },
                }}
                autoFocus
              />
            </FormGroup>
            <div className={styles.inlineInputs}>
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="year" />}
                label="Year"
              >
                <RHFNumericInput
                  controllerProps={{
                    control,
                    name: 'year',
                  }}
                  inputProps={{
                    fill: true,
                  }}
                />
              </FormGroup>
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="track" />}
                label="Track"
                className={styles.inlineNumericInput}
              >
                <RHFTextInput
                  controllerProps={{
                    control,
                    name: 'track',
                  }}
                  inputProps={{
                    fill: true,
                  }}
                />
              </FormGroup>
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="event" />}
                label="Event"
              >
                <RHFTextInput
                  controllerProps={{
                    control,
                    name: 'event',
                  }}
                  inputProps={{
                    fill: true,
                  }}
                />
              </FormGroup>
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="session" />}
                label="Session"
              >
                <RHFTextInput
                  controllerProps={{
                    control,
                    name: 'session',
                  }}
                  inputProps={{
                    fill: true,
                  }}
                />
              </FormGroup>
            </div>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="description" />}
              label="Description"
            >
              <RHFTextInput
                controllerProps={{
                  control,
                  name: 'description',
                }}
                inputProps={{
                  fill: true,
                }}
              />
            </FormGroup>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                text="Cancel"
                onClick={props.onClose}
              />,
              <Button
                intent="primary"
                text="OK"
                type="submit"
              />]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AddSimWorksheetDialog;
