import styles from './index.module.css';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import AppToaster from 'helpers/toaster';
import { EditableText, Intent } from '@blueprintjs/core';
import { SweepPayloadGroup, SweepPayloadParameter } from './types';
import { flatten } from 'lodash';
import { SweepParameterApplicationMethod } from 'graphql/generated/graphql';

type SweepPayloadTableProps = {
  sweepPayload?: SweepPayloadGroup[];
  setSweepPayload: (input: SweepPayloadGroup[]) => void;
}

const SweepPayloadTable = (props : SweepPayloadTableProps) => {
  const { sweepPayload, setSweepPayload } = props;
  const [groups, setGroups] = useState<SweepPayloadGroup[]>([]);
  const [tableLength, setTableLength] = useState<number>(0);

  useEffect(() => {
    if (!sweepPayload) return;
    try {
      setTableLength(sweepPayload.reduce((acc, sp) => acc + sp.sweepDataLength, 0));
      setGroups(sweepPayload);
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Error importing payload',
      });
    }
  }, [sweepPayload]);

  const onCellValueChange = useCallback((parameter: SweepPayloadParameter, value: string, rowIndex: number) => {
    if (sweepPayload) {
      const newSweepPayload = sweepPayload;
      let valueIndex = rowIndex;
      const newGroups = sweepPayload.map((sweepGroup, groupIndex) => {
        const newGroup = {
          parameters: sweepGroup.parameters.map((p, parameterIndex) => {
            if (p.path === parameter.path) {
              parameter.values[valueIndex] = value;
              newSweepPayload[groupIndex].parameters[parameterIndex].values = parameter.values;
              return parameter;
            }
            newSweepPayload[groupIndex].parameters[parameterIndex].values = p.values;
            return p;
          }),
          sweepDataLength: sweepGroup.sweepDataLength,
        };

        valueIndex -= sweepGroup.sweepDataLength;
        return newGroup;
      });
      setGroups(newGroups);
      setSweepPayload(newSweepPayload);
    }
  }, [sweepPayload]);

  const renderSweepDataTable = (
    tableLength: number,
    groups: SweepPayloadGroup[],
    payload: SweepPayloadGroup[],
  ): ReactNode => {
    let currentGroupIndex = 0;
    let groupDataIndex = 0;
    return [...Array(tableLength)].map((item, rowIndex) => {
      const currentGroup = payload[currentGroupIndex];
      const tableRow = (
        <tr className={styles.textCenterRow}>
          <td>{rowIndex + 1}</td>
          {flatten(groups.map(g => g.parameters)).map(parameter => {
            if (currentGroup?.parameters.find(p => p.path === parameter.path)) {
              return (
                <td>
                  {(parameter.method === SweepParameterApplicationMethod.DELTA) && (<>Δ</>)}
                  <EditableText
                    value={parameter.values[groupDataIndex]?.toString()}
                    onChange={value => {
                      onCellValueChange(parameter, value, rowIndex);
                    }}
                    selectAllOnFocus
                  />
                </td>
              );
            }
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            return (<td />);
          })}
        </tr>
      );

      if ((groupDataIndex + 1 >= currentGroup?.sweepDataLength) && (currentGroupIndex < payload.length)) {
        currentGroupIndex++;
        groupDataIndex = 0;
      } else {
        groupDataIndex++;
      }
      return tableRow;
    });
  };

  return (
    <table className={styles.payloadTable}>
      {groups.length > 0 && sweepPayload && (
        <>
          <tr className={styles.textCenterColumnHeader}>
            <th>Run</th>
            {flatten(groups.map(g => g.parameters)).map((parameter) => (
              <th>{parameter.path}</th>
            ))}
          </tr>
          {renderSweepDataTable(tableLength, groups, sweepPayload)}
        </>
      )}
    </table>
  );
};

export default SweepPayloadTable;
