import { ChangeEvent, MouseEvent, FocusEvent } from 'react';
import { InputGroup, Icon } from '@blueprintjs/core';
import classNames from 'classnames';
import styles from './index.module.css';

interface InputCheckboxProps {
  checked: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleCheck: (e: MouseEvent<HTMLButtonElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  value: string;
  id: string;
  isDifferent: boolean;
  readOnly?: boolean;
}

export default (props: InputCheckboxProps) => {
  const triangleStyles = classNames({
    [styles.inputCheckboxTriangle]: true,
    [styles.inputCheckboxTriangleChecked]: props.checked,
    [styles.inputCheckboxTriangleUnchecked]: !props.checked,
  });

  const inputGroupClassNames = classNames(styles.inputCheckboxText, {
    [styles.inputCheckboxYellowBackground]: props.isDifferent,
  });

  // Inline style for the text color
  const textStyle = props.isDifferent ? { color: 'black' } : {};

  return (
    <div className={styles.inputCheckbox}>
      <InputGroup
        onBlur={props.onBlur}
        id={props.id}
        className={inputGroupClassNames}
        value={props.value}
        onChange={props.handleChange}
        style={textStyle}
        disabled={props.readOnly}
      />
      <button
        aria-label="Save"
        type="button"
        className={styles.inputCheckboxButton}
        onClick={props.handleCheck}
        disabled={props.readOnly}
      >
        <div className={triangleStyles} />
        {props.checked ? (<Icon className={styles.inputCheckboxIcon} icon="small-tick" />) : null}
      </button>
    </div>
  );
};
