import { Callout, Intent } from '@blueprintjs/core';

export default () => {
  return (
    <Callout
      intent={Intent.WARNING}
      title="En construcción!"
    >
      Your advertising budget dollars at work!
      <br />
      Building Apex Setup!
    </Callout>
  );
};
