import { Intent, TagInput, TagInputProps } from '@blueprintjs/core';
import { Controller, UseControllerProps, useFieldArray, useFormContext, useWatch } from 'react-hook-form';

interface Props {
  controllerProps: UseControllerProps;
  inputProps?: Partial<TagInputProps>;
  intent?: Intent;
}

export default (props: Props) => {
  const { control, setValue } = useFormContext();
  useFieldArray({ control, name: props.controllerProps.name });
  const values = useWatch({ control, name: props.controllerProps.name });

  const onChange = (items: string[]) => setValue(props.controllerProps.name, items);

  return (
    <Controller
      {...props.controllerProps}
      render={() => {
        return (
          <TagInput
            addOnBlur
            intent={props.intent}
            onChange={items => onChange(items as string[])}
            values={values ?? []}
            {...props.inputProps}
          />
        );
      }}
    />
  );
};
