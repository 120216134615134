import { LoaderFunctionArgs } from 'react-router-dom';

import {
  PartCategoryDocument,
  UserDataDocument,
} from 'graphql/generated/graphql';
import apolloClient from '../graphql';

// eslint-disable-next-line import/prefer-default-export
export const partCategory = async ({ params }: LoaderFunctionArgs) => {
  const { data } = await apolloClient.query({
    query: PartCategoryDocument,
    variables: {
      categoryName: params.categoryName,
    },
  });
  return data;
};

export const getUserData = async () => {
  try {
    const { data } = await apolloClient.query({
      query: UserDataDocument,
    });

    return data;
  } catch (e) {
    return { getUser: { teams: [], organizations: [] } };
  }
};
