import { Button, H3, Intent } from '@blueprintjs/core';
import { useState } from 'react';

import EnvironmentTable from '../../components/EnvironmentTable';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

export default () => {
  useDocumentTitle('Apex Setup - Environments');
  const [isAddEnvironmentDialogOpen, setAddEnvironmentDialogOpen] = useState<boolean>(false);

  return (
    <>
      <div className={styles.actionsHeader}>
        <H3>Environment Summary</H3>
        <Button
          icon="plus"
          intent={Intent.PRIMARY}
          onClick={() => setAddEnvironmentDialogOpen(true)}
          text="Create Environment"
        />
      </div>
      <EnvironmentTable
        isAddEnvironmentDialogOpen={isAddEnvironmentDialogOpen}
        onAddEnvironmentDialogClose={() => setAddEnvironmentDialogOpen(false)}
      />
    </>
  );
};
