import { createSlice } from '@reduxjs/toolkit';
import { State } from 'reducers';

export interface SUITState {
  settings: {
    activeSUITId?: number;
  };
}

export const initialState: SUITState = {
  settings: {
    activeSUITId: undefined,
  },
};

export const SUITSlice = createSlice({
  name: 'suit',
  initialState,
  reducers: {
    setActiveSUITId: (state, { payload }) => {
      state.settings.activeSUITId = payload;
    },
  },
});

export default SUITSlice.reducer;

export const selectActiveSUITId = (state: State) => state.suit.settings.activeSUITId;
