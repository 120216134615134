import { Dialog, DialogBody, DialogFooter, Button, FormGroup, InputGroup, PopoverPosition, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { CreateEnvironmentInput, Environment, Spec, Track, useTracksLazyQuery } from 'graphql/generated/graphql';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import { SelectItem } from 'types';
import { ErrorMessage } from '@hookform/error-message';
import { organizationSelectItems, seriesItems, teamSelectItems } from '../../constants';
import Select from 'components/Select';
import styles from './index.module.css';
import { useRouteLoaderData } from 'react-router-dom';
import { find, get } from 'lodash';

const DefaultWeatherValues = {
  wind_scalar: 1,
  temperature: 60,
  humidity: 45,
  pressure: 30,
  wind_speed: 0,
  wind_direction: 0,
  use_lap_wind_data: false,
  traffic_wind: 0,
  traffic_wind_angle: 0,
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleCreateEnvironment: (input: CreateEnvironmentInput) => void;
}

const AddEnvironmentDialog = (props: Props) => {
  const { isOpen, onClose, handleCreateEnvironment } = props;
  const nameRef = useRef<HTMLInputElement>(null);
  const darkMode = useSelector(selectDarkMode);
  const [trackSelectItems, setTrackSelectItems] = useState<SelectItem<Track>[]>([]);
  const [selectedTrack, setSelectedTrack] = useState<Track>();
  const [environmentName, setEnvironmentName] = useState<string>('');
  const [orgName, setOrgName] = useState<string | null>(null);
  const [teamName, setTeamName] = useState<string | null>(null);
  const [series, setSeries] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const form = useForm<Partial<Environment>>();
  const { reset, formState: { errors } } = form;

  const [getTracks, { data: tracksData }] = useTracksLazyQuery();

  const onSubmit = (input: Partial<Environment>) => {
    if (selectedTrack && orgName && series) {
      const { short_name: shortName, elevation, latitude, longitude } = selectedTrack;
      const findOrg = find(organizations, org => orgName === org.name);
      const findTeam = find(teams, team => teamName === team.name);
      const createEnvironmentInput: CreateEnvironmentInput = {
        name: environmentName || '',
        track: shortName || '',
        elevation: elevation || 0,
        latitude: latitude || 0,
        longitude: longitude || 0,
        spec: input.spec || Spec.GEN7,
        organization_name: orgName,
        organization_id: get(findOrg, 'id', null),
        team_id: get(findTeam, 'id', null),
        team_name: teamName,
        series,
        ...DefaultWeatherValues,
      };

      handleCreateEnvironment(createEnvironmentInput);
    }
    reset();
    onClose();
  };

  useEffect(() => {
    getTracks();
  }, []);

  useEffect(() => {
    if (tracksData) {
      setTrackSelectItems(tracksData.tracks.map(t => {
        return {
          label: t.short_name,
          value: t,
        } as SelectItem<Track>;
      }));
    }
  }, [tracksData]);

  useEffect(() => {
    const findTeam = find(teams, team => team.name === teamName);
    if (findTeam && findTeam.organization.name !== orgName) {
      setTeamName(null);
    }
  }, [orgName]);

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={isOpen}
      onClose={onClose}
      title="New environment"
      onOpened={() => nameRef.current?.focus()}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              label="Organization"
              helperText={<ErrorMessage errors={errors} name="organization_name" />}
              intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
              labelInfo="(required)"
            >
              <Select
                items={organizationSelectItems(organizations)}
                onChange={item => setOrgName(item.value)}
                intent={errors.organization_name && Intent.DANGER}
              />
            </FormGroup>
            <FormGroup
              label="Team"
              helperText={<ErrorMessage errors={errors} name="team_name" />}
            >
              <Select
                items={teamSelectItems(teams, orgName || undefined)}
                onChange={item => setTeamName(item.value)}
                disabled={!orgName}
              />
            </FormGroup>
            <FormGroup
              label="Series"
              helperText={<ErrorMessage errors={errors} name="series" />}
              labelInfo="(required)"
              intent={errors.series ? Intent.DANGER : Intent.NONE}
            >
              <Select
                items={seriesItems}
                onChange={item => setSeries(item.value)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="name" />}
              label="Name"
            >
              <InputGroup
                onChange={e => setEnvironmentName(e.target.value)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="track" />}
              label="Track"
              labelInfo="(required)"
              intent={errors.track ? Intent.DANGER : Intent.NONE}
            >
              <div className={styles.trackSelect}>
                <Select
                  fill
                  items={trackSelectItems}
                  onChange={item => setSelectedTrack(item.value)}
                  popoverProps={{ position: PopoverPosition.RIGHT_TOP }}
                />
              </div>
            </FormGroup>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                text="Cancel"
                onClick={onClose}
              />,
              <Button
                intent="primary"
                text="OK"
                type="submit"
              />]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AddEnvironmentDialog;
