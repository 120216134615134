import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button, InputGroup, Overlay, Spinner } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import Table, { ParamsChangeFn, RowActions } from 'components/Table';
import { useAlert } from 'components/Alert';
import AppToaster from 'helpers/toaster';
import {
  Environment,
  useDeleteEnvironmentMutation,
  useCloneEnvironmentMutation,
  CreateEnvironmentInput,
  useCreateEnvironmentMutation,
  useEnvironmentsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';
import { selectEnvironmentSummaryView, tableViewSlice } from 'reducers/tableView';
import { cloneDeep, debounce, find, keyBy, mapValues } from 'lodash';
import AddEnvironmentDialog from 'pages/EnvironmentViews/AddEnvironmentDialog';
import Select from 'components/Select';
import { organizationSelectItems, teamSelectItems, seriesItems } from '../../constants';
import styles from './index.module.css';

interface Props {
  isAddEnvironmentDialogOpen: boolean;
  onAddEnvironmentDialogClose: () => void;
}

export default (props: Props) => {
  const { isAddEnvironmentDialogOpen, onAddEnvironmentDialogClose } = props;
  const dispatch = useDispatch();
  const darkMode = useSelector(selectDarkMode);
  const { tableFilters, tableSorting } = useSelector(selectEnvironmentSummaryView);
  const [tableData, setTableData] = useState<Environment[]>([]);
  const [cloneSource, setCloneSource] = useState<Environment>();
  const [isCloneModalOpen, setCloneModalOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const cloneName = useRef<HTMLInputElement>(null);
  const alert = useAlert();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const [cloneEnvironment] = useCloneEnvironmentMutation();
  const [deleteEnvironment] = useDeleteEnvironmentMutation();
  const [createEnvironment] = useCreateEnvironmentMutation();

  const [getEnvironments, { refetch: refetchEnvironments, data: environmentsData, loading: isLoading }] = useEnvironmentsLazyQuery({
    onCompleted: data => {
      setTableData(data.environments.rows as Environment[]);
      setIsInitialLoad(false);
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleCreateEnvironment = (input: CreateEnvironmentInput) => {
    createEnvironment({
      variables: {
        input: {
          ...input,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Environment successfully created',
        });
        refetchEnvironments();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating Environment: ${e.message}`,
        });
      },
    });
  };

  const columnHelper = createColumnHelper<Environment>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('track', {
      header: 'Track',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Environment>[];

  const rowActions: RowActions<Environment> = [{
    label: 'Edit',
    value: row => {
      navigate(`/environments/${row.original.id}`);
    },
  }, {
    label: 'Clone',
    value: row => {
      setCloneSource(row.original);
      setCloneModalOpen(true);
      cloneName.current?.focus();
    },
  }, {
    intent: Intent.DANGER,
    label: 'Delete',
    value: row => {
      const envId = row.original.id;
      if (!envId) return;
      alert.showAlert(`Delete Environment "${row.original.name}?`, {
        intent: Intent.DANGER,
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
      }).then((yes) => {
        if (!yes) return;
        deleteEnvironment({
          variables: {
            id: envId,
          },
          onCompleted: () => {
            AppToaster.show({
              intent: Intent.SUCCESS,
              message: 'Successfully deleted environment',
            });
          },
          onError: e => {
            AppToaster.show({
              intent: Intent.DANGER,
              message: `Failed to delete environment: ${e.message}`,
            });
          },
          refetchQueries: ['Environments'],
        });
      });
    },
  }];

  const handleClone = () => {
    if (!cloneSource) return;
    cloneEnvironment({
      variables: {
        id: cloneSource.id,
        name: cloneName.current?.value || `${cloneSource.name}@${Date.now()}`,
        orgName: cloneSource.organization_name,
        orgId: cloneSource.organization_id,
        teamName: cloneSource.team_name,
        teamId: cloneSource.team_id,
        series: cloneSource.series,
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Successfully cloned environment',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to clone environment: ${e.message}`,
        });
      },
      refetchQueries: ['Environments'],
    });
    setCloneModalOpen(false);
  };

  useEffect(() => {
    const onEnter = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleClone();
      }
    };

    if (isCloneModalOpen) {
      document.addEventListener('keydown', onEnter);
    }

    return () => {
      document.removeEventListener('keydown', onEnter);
    };
  }, [isCloneModalOpen]);

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    dispatch(tableViewSlice.actions.setEnvironmentSummaryView({ filters, sorting }));

    getEnvironments({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSelectItem = (item: any, name: string) => {
    const clone = cloneDeep(cloneSource);

    if (clone) {
      if (name === 'series') {
        clone.series = item.value;
      }

      if (name === 'org') {
        clone.organization_name = item.value;
        clone.organization_id = item.id;

        // Reset team selections if org changes.
        const findTeam = find(teams, team => team.name === cloneSource?.team_name);
        if (findTeam && findTeam.organization.name !== item.value) {
          clone.team_name = null;
          clone.team_id = null;
        }
      }

      if (name === 'team') {
        clone.team_name = item.value;
        clone.team_id = item.id;
      }

      setCloneSource(clone);
    }
  };

  return (
    <div>
      <Table
        id="environment-summary"
        columns={columns}
        data={tableData}
        rowActions={rowActions}
        enableHiding
        enablePagination
        manualFiltering
        manualSorting
        manualPagination
        persistColumnVisibility
        initialColumnFilters={tableFilters}
        initialSorting={tableSorting}
        onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
        totalRowCount={environmentsData?.environments.totalCount || 0}
      />
      <Dialog
        className={classNames({ 'bp4-dark': darkMode })}
        isCloseButtonShown
        isOpen={isCloneModalOpen}
        onClose={() => setCloneModalOpen(false)}
        title={`Cloning from "${cloneSource?.name}"`}
        onOpened={() => cloneName.current?.focus()}
      >
        <DialogBody>
          <div>
            <div style={{ paddingBottom: '5px' }}>Organization</div>
            <Select
              initialItem={{ label: cloneSource?.organization_name || 'None', value: cloneSource?.organization_name || null }}
              items={organizationSelectItems(organizations)}
              noSelectionText="Organization"
              onChange={item => setSelectItem(item, 'org')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Team</div>
            <Select
              initialItem={{ label: cloneSource?.team_name || 'None', value: cloneSource?.team_name || null }}
              items={teamSelectItems(teams, cloneSource?.organization_name)}
              noSelectionText="Team"
              onChange={item => setSelectItem(item, 'team')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Series</div>
            <Select
              initialItem={seriesItems.find(i => cloneSource?.series === i.value)}
              items={seriesItems}
              noSelectionText="Series"
              onChange={item => setSelectItem(item, 'series')}
            />
          </div>
          <div style={{ paddingTop: '10px' }}>
            <div style={{ paddingBottom: '5px' }}>Name</div>
            <InputGroup
              placeholder="Enter new environment name"
              inputRef={cloneName}
              defaultValue={`${cloneSource?.name} CLONE`}
            />
          </div>
        </DialogBody>
        <DialogFooter
          actions={(
            <Button
              intent="primary"
              text="OK"
              onClick={() => handleClone()}
            />
          )}
        />
      </Dialog>
      <AddEnvironmentDialog
        isOpen={isAddEnvironmentDialogOpen}
        onClose={onAddEnvironmentDialogClose}
        handleCreateEnvironment={handleCreateEnvironment}
      />
      <Overlay
        isOpen={isLoading && isInitialLoad}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
