import { SweepParameterApplicationMethod } from 'graphql/generated/graphql';

export type SweepPayloadGroup = {
  parameters: SweepPayloadParameter[],
  sweepDataLength: number,
}

export type SweepPayloadParameter = {
  path: string;
  values: number[] | string[];
  method: SweepParameterApplicationMethod;
}

export type ParameterDefinition = {
  starting_value: number;
  min: number,
  max: number,
}

export enum SweepApiMethods {
  FULLFACTORIAL = 'Full Factorial',
  RANDOM = 'Random Discrete',
  IMPORT = 'Import'
}

export type SweepImportDocument = {
  sim_engine: string,
  sweep_parameters: { [key: string]: string[] | number[] },
}

export enum SweepDataType {
  PART = 'part',
  NUMBER = 'number',
}
