import PlatformToolbar from '@apex/react-toolkit/components/PlatformToolbar';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { env } from 'config';
import ErrorBoundary from 'components/ErrorBoundary';
import SettingsMenu from 'components/SettingsMenu';
import GlobalNavigationMenu from 'components/GlobalNavigationMenu';
import { selectDarkMode } from 'reducers/ui';

import styles from './index.module.css';
import SimProgressBar from 'components/SimProgressBar';

export default () => {
  const darkMode = useSelector(selectDarkMode);
  const location = useLocation();

  const classes = classNames(
    {
      [styles.dark]: darkMode, // Used for this specific component's styles
      'dark': darkMode,          // Used for local components, since `styles.dark` is scoped to this component
      'bp4-dark': darkMode,    // Used for Blueprint components
      // Contextualized setup views have different padding due to the SetupNavigationMenu being anchored to the left side
      [styles.setupView]: /^\/setup\/\d/.test(location.pathname),
    },
    styles.content,
  );

  return (
    <div>
      <PlatformToolbar
        icon={<img src={`${process.env.PUBLIC_URL}/apexlogo.png`} className="mb-1" style={{ height: '20px' }} alt="logo" />}
        name="Apex Setup"
        href={env === 'local' ? '/apex-setup' : '/'}
        settingsComponent={<SettingsMenu />}
        navigationItems={<GlobalNavigationMenu />}
      />
      <main className={classes}>
        <ErrorBoundary>
          <SimProgressBar />
          <Outlet />
        </ErrorBoundary>
      </main>
    </div>
  );
};
