import { Button, ControlGroup, Intent } from '@blueprintjs/core';
import { FieldValues, useFieldArray, useForm, useFormContext } from 'react-hook-form';

import RHFTextInput from '../RHFInputs/TextInput';

import styles from './index.module.css';

export default () => {
  const { control, formState: { errors }, handleSubmit, reset } = useForm();
  const { control: outerControl, formState: { errors: outerErrors }, getValues } = useFormContext();
  const { fields, append, update } = useFieldArray({ control: outerControl, name: 'positions' });

  const addPosition = (position: FieldValues) => {
    append(position);
    reset({ label: '' });
  };
  const removePosition = (index: number) => {
    const position = getValues(`positions.${index}`);
    update(index, { ...position, destroy: true });
  };

  return (
    <>
      <ControlGroup
        className={styles.addPositionContainer}
        fill
      >
        <RHFTextInput
          controllerProps={{
            control,
            name: 'label',
            rules: { required: true },
          }}
          inputProps={{
            intent: errors.label && Intent.DANGER,
            placeholder: 'Label',
          }}
        />
        <Button
          icon="plus"
          minimal
          onClick={handleSubmit(addPosition)}
        />
      </ControlGroup>
      <ul className={styles.positionsList} key="positionsList">
        {fields.map((field, index) => {
          if (getValues(`positions.${index}`).destroy) return null;

          return (
            <li key={field.id}>
              <ControlGroup fill>
                <RHFTextInput
                  controllerProps={{
                    control: outerControl,
                    name: `positions.${index}.label`,
                    rules: { required: true },
                  }}
                  inputProps={{
                    intent: outerErrors.positions && Intent.DANGER,
                  }}
                />
                <Button
                  icon="trash"
                  intent={Intent.DANGER}
                  minimal
                  onClick={() => removePosition(index)}
                />
              </ControlGroup>
            </li>
          );
        })}
      </ul>
    </>
  );
};
