import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, Intent } from '@blueprintjs/core';

import IconTooltip from 'components/IconTooltip';
import RHFCheckbox from 'components/RHFInputs/Checkbox';
import RHFNumericInput from 'components/RHFInputs/NumericInput';
import RHFSelect from 'components/RHFInputs/Select';
import RHFTextInput from 'components/RHFInputs/TextInput';
import { usePartConfigFormQuery } from 'graphql/generated/graphql';
import PropertiesTable from './PropertiesTable';
import { seriesItems, organizationSelectItems, teamSelectItems } from '../../constants';

import styles from './index.module.css';
import { useRouteLoaderData } from 'react-router-dom';
import { find } from 'lodash';

export default () => {
  const { control, setValue, watch, formState: { errors } } = useFormContext();
  const { data } = usePartConfigFormQuery({ fetchPolicy: 'no-cache' });
  const teamName = watch('team_name');
  const orgName = watch('organization_name');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const partCategoryItems = data?.partCategories.map(category => ({
    label: category.display_name,
    value: category.id,
  }));

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  return (
    <>
      <div className={styles.mainForm}>
        <FormGroup
          contentClassName={styles.selectFormGroup}
          helperText={<ErrorMessage errors={errors} name="part_category_id" />}
          label="Category"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'part_category_id',
              rules: { required: 'Category is required' },
            }}
            intent={errors.part_category_id && Intent.DANGER}
            items={partCategoryItems ?? []}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="organization_name" />}
          intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
          label="Organization"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'organization_name',
              rules: {
                required: 'Organization is required',
              },
            }}
            intent={errors.organization_name && Intent.DANGER}
            items={organizationSelectItems(organizations)}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="team_name" />}
          intent={errors.team_name ? Intent.DANGER : Intent.NONE}
          label="Team"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'team_name',
            }}
            disabled={!orgName}
            intent={errors.team_name && Intent.DANGER}
            items={teamSelectItems(teams, orgName) ?? [{ value: null, label: 'None' }]}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="series" />}
          intent={errors.series ? Intent.DANGER : Intent.NONE}
          label="Series"
          labelInfo="(required)"
        >
          <RHFSelect
            controllerProps={{
              control,
              name: 'series',
              rules: {
                required: 'Series is required',
              },
            }}
            intent={errors.series && Intent.DANGER}
            items={seriesItems}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="name" />}
          label="Name"
          labelInfo={(
            <>
              <IconTooltip content="Used in Setup Fields when identifying a part type. String without spaces." />
              <span> (required)</span>
            </>
          )}
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'name',
              rules: {
                required: 'Name is required',
                pattern: {
                  value: /^\S*$/,
                  message: 'Name must not contain spaces',
                },
              },
            }}
            inputProps={{
              intent: errors.name && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="display_name" />}
          label="Display Name"
          labelInfo="(required)"
        >
          <RHFTextInput
            controllerProps={{
              control,
              name: 'display_name',
              rules: { required: 'Display Name is required' },
            }}
            inputProps={{
              intent: errors.display_name && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup
          helperText={<ErrorMessage errors={errors} name="mileage_limit" />}
          label="Mileage Limit"
        >
          <RHFNumericInput
            controllerProps={{
              control,
              name: 'mileage_limit',
              rules: {
                min: {
                  value: 1,
                  message: 'Mileage Limit cannot be less than 1',
                },
              },
            }}
            inputProps={{
              fill: true,
              intent: errors.mileage_limit && Intent.DANGER,
            }}
          />
        </FormGroup>
        <FormGroup label="Expires" inline>
          <RHFCheckbox
            controllerProps={{
              name: 'expires',
            }}
          />
        </FormGroup>
      </div>
      <PropertiesTable properties={data?.properties.rows ?? []} />
    </>
  );
};
