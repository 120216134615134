import { configureStore } from '@reduxjs/toolkit';

import { env } from './config';
import reducers, { State } from './reducers';

const saveToLocalStorage = (state: State) => {
  const settings: Record<string, unknown> = {};

  Object.keys(state).forEach(k => {
    const keyofState = k as keyof State;
    if (state[keyofState].settings) {
      settings[k] = state[keyofState].settings;
    }
  });

  try {
    localStorage.setItem('settings', JSON.stringify(settings));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error saving settings to localStorage', e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const str = localStorage.getItem('settings');
    if (!str) return {};

    const settings: Record<string, unknown> = JSON.parse(str);
    const retState: Record<string, unknown> = {};
    Object.keys(settings).forEach(k => {
      retState[k] = {
        settings: settings[k],
      };
    });

    return retState;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Error loading settings to localStorage', e);
    return {};
  }
};

export const store = configureStore({
  reducer: reducers,
  devTools: env !== 'prod',
  preloadedState: loadFromLocalStorage(),
});

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
