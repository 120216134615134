import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { H3, Button } from '@blueprintjs/core';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
import { map, debounce } from 'lodash';
import classNames from 'classnames';

import {
  useClearSimStatusMutation,
  useClearSweepStatusMutation,
} from 'graphql/generated/graphql';
import {
  selectSimReportingSummaryView,
  selectSweepReportingSummaryView,
  tableViewSlice,
} from 'reducers/tableView';
import Table, { ParamsChangeFn } from 'components/Table';
import {
  useSubscriptionContext,
  SimStatus,
  BatchStatus,
} from '../../context/SubscriptionContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';
import SimButton from './SimButton';

type SimStatusTableRow = SimStatus & { stages: string }

type SweepStatusTableRow = BatchStatus

export default () => {
  useDocumentTitle('Apex Setup - Reporting');
  const dispatch = useDispatch();
  const subscriptionData = useSubscriptionContext();
  const { clearSimStatusData, clearSweepStatusData } = subscriptionData;
  const { tableSorting: simTableSorting } = useSelector(selectSimReportingSummaryView);
  const { tableSorting: sweepTableSorting } = useSelector(selectSweepReportingSummaryView);
  const [simStatusTableData, setSimStatusTableData] = useState<SimStatusTableRow[]>([]);
  const [sweepStatusTableData, setSweepStatusTableData] = useState<SweepStatusTableRow[]>([]);
  const [clearSimStatus] = useClearSimStatusMutation();
  const [clearSweepStatus] = useClearSweepStatusMutation();

  const displayDate = (dateString:string) => {
    try {
      const date = new Date(dateString);
      return dateString ? format(date, 'MM/dd/yy HH:mm:ss') : '';
    } catch (e) {
      return '';
    }
  };

  const simStatusColumnHelper = createColumnHelper<SimStatusTableRow>();
  const simStatusColumns = [
    simStatusColumnHelper.display({
      id: 'cancel-button',
      cell: info => {
        const { cancelId, state } = info.row.original;
        return renderSimButton(cancelId, state);
      },
      size: 40,
      enableHiding: false,
    }),
    simStatusColumnHelper.accessor('state', {
      header: 'Status',
      cell: info => info.getValue(),
    }),
    simStatusColumnHelper.accessor('vehicle', {
      header: 'Setup',
      cell: info => info.getValue(),
    }),
    simStatusColumnHelper.accessor('displayName', {
      header: 'Sim',
      cell: info => info.getValue(),
    }),
    simStatusColumnHelper.accessor('simEngine', {
      header: 'Sim Engine',
      cell: info => info.getValue(),
    }),
    simStatusColumnHelper.accessor('stages', {
      header: 'Stages',
      cell: info => info.getValue(),
    }),
    simStatusColumnHelper.accessor('percentage', {
      header: 'Progress',
      cell: info => info.getValue(),
    }),
    simStatusColumnHelper.accessor('submitted', {
      header: 'Submitted',
      cell: info => displayDate(info.getValue() as string),
    }),
    simStatusColumnHelper.accessor('started', {
      header: 'Started',
      cell: info => displayDate(info.getValue() as string),
    }),
    simStatusColumnHelper.accessor('finished', {
      header: 'Completed',
      cell: info => displayDate(info.getValue() as string),
    }),
  ] as ColumnDef<SimStatusTableRow>[];

  const sweepStatusColumnHelper = createColumnHelper<SweepStatusTableRow>();
  const sweepStatusColumns = [
    sweepStatusColumnHelper.display({
      id: 'cancel-button',
      cell: info => {
        const { cancelId, state } = info.row.original;
        return renderSimButton(cancelId, state);
      },
      size: 140,
      enableHiding: false,
    }),
    sweepStatusColumnHelper.accessor('state', {
      header: 'Status',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('displayName', {
      header: 'Setup and Sweep Name',
      cell: info => info.getValue(),
      size: 200,
    }),
    sweepStatusColumnHelper.accessor('simEngine', {
      header: 'Sim Engine',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('type', {
      header: 'Type',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('totalSims', {
      header: 'Total',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('completed', {
      header: 'Completed',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('failed', {
      header: 'Failed',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('lost', {
      header: 'Lost',
      cell: info => info.getValue(),
    }),
    sweepStatusColumnHelper.accessor('submitted', {
      header: 'Submitted',
      cell: info => displayDate(info.getValue() as string),
    }),
    sweepStatusColumnHelper.accessor('started', {
      header: 'Started',
      cell: info => displayDate(info.getValue() as string),
    }),
    sweepStatusColumnHelper.accessor('finished', {
      header: 'Completed',
      cell: info => displayDate(info.getValue() as string),
    }),
  ] as ColumnDef<SweepStatusTableRow>[];

  const renderSimButton = (cancelId: string, state: string) => {
    return (
      <SimButton cancelId={cancelId} state={state} />
    );
  };

  useEffect(() => {
    const simStatusData = subscriptionData?.simStatus || [];

    const formattedSimStatusData: SimStatusTableRow[] = map(simStatusData, (o: SimStatus) => ({
      ...o,
      submitted: o.started,
      stages: `${o.curr}/${o.total}`,
    }));
    setSimStatusTableData(formattedSimStatusData);
  }, [subscriptionData?.simStatus]);

  useEffect(() => {
    const sweepStatusData = subscriptionData?.batchStatus || [];
    setSweepStatusTableData(sweepStatusData);
  }, [subscriptionData?.batchStatus]);

  const handleClearSimStatus = () => {
    clearSimStatus(); // clear sim status data in redis
    clearSimStatusData(); // clear local sim status data
  };

  const handleClearSweepStatus = () => {
    clearSweepStatus(); // clear sweep status data in redis
    clearSweepStatusData(); // clear local sweep status data
  };

  const onSimTableParamsChange: ParamsChangeFn = async (_filters, _pagination, sorting) => {
    dispatch(tableViewSlice.actions.setSimReportingSummaryView({ sorting }));
  };

  const debouncedOnSimTableParamsChange = debounce(onSimTableParamsChange, 200);

  const onSweepTableParamsChange: ParamsChangeFn = async (_filters, _pagination, sorting) => {
    dispatch(tableViewSlice.actions.setSweepReportingSummaryView({ sorting }));
  };

  const debouncedOnSweepTableParamsChange = debounce(onSweepTableParamsChange, 200);

  return (
    <div className={styles.mainContainer}>
      <div className={classNames(styles.reportingTableContainer, styles.marginBottom)}>
        <div className={styles.actionsHeader}>
          <H3>Sim Reporting</H3>
          <Button
            onClick={() => handleClearSimStatus()}
            text="Clear"
          />
        </div>
        <Table
          id="sim_reporting"
          columns={simStatusColumns}
          data={simStatusTableData}
          persistColumnVisibility
          enableHiding
          enablePagination
          initialSorting={simTableSorting}
          onParamsChange={debouncedOnSimTableParamsChange as ParamsChangeFn}
          totalRowCount={subscriptionData?.simStatus.length}
        />
      </div>
      <div className={styles.reportingTableContainer}>
        <div className={styles.actionsHeader}>
          <H3>Sweep Reporting</H3>
          <Button
            onClick={() => handleClearSweepStatus()}
            text="Clear"
          />
        </div>
        <Table
          id="batch_reporting"
          columns={sweepStatusColumns}
          data={sweepStatusTableData}
          persistColumnVisibility
          enableHiding
          enablePagination
          initialSorting={sweepTableSorting}
          onParamsChange={debouncedOnSweepTableParamsChange as ParamsChangeFn}
          totalRowCount={subscriptionData?.batchStatus.length}
        />
      </div>
    </div>
  );
};
