import { Controller, UseControllerProps } from 'react-hook-form';
import { NumericInput, NumericInputProps } from '@blueprintjs/core';

interface Props {
  controllerProps: UseControllerProps;
  inputProps?: NumericInputProps;
  useStringValue?: boolean;
}

export default (props: Props) => {
  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => (
        <NumericInput
          {...props.inputProps}
          defaultValue={field.value ?? ''}
          name={field.name}
          buttonPosition="none"
          onValueChange={(valAsNumber, valAsString) => {
            if (props.useStringValue) field.onChange(valAsString);
            else if (!valAsString) field.onChange(undefined);
            else field.onChange(valAsNumber);
          }}
        />
      )}
    />
  );
};
