import { FC, createContext, useContext, useState, ReactNode, useMemo, CSSProperties } from 'react';
import { Alert, IconName, Intent, MaybeElement } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import classNames from 'classnames';

interface AlertProps {
  cancelButtonText?: string,
  canEscapeKeyCancel?: boolean,
  canOutsideClickCancel?: boolean,
  className?: string,
  confirmButtonText?: string,
  intent?: Intent,
  style?: CSSProperties,
  transitionDuration?: number,
  icon?: IconName | MaybeElement
}

interface AlertContextType {
  showAlert: (content: string | ReactNode, options: AlertProps) => Promise<boolean>,
}

const AlertContext = createContext<AlertContextType>({
  showAlert: async () => false,
});

export const useAlert = () => useContext(AlertContext);

interface AlertProviderProps {
  children: ReactNode;
}

/**
 * A React component that provides the Alert context
 */
export const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alertContent, setAlertContent] = useState<ReactNode>(null);
  const [alertOptions, setAlertOptions] = useState<AlertProps>();
  const [resolvePromise, setResolvePromise] = useState<(val: boolean) => void>(() => { });  // eslint-disable-line @typescript-eslint/no-empty-function
  const darkMode = useSelector(selectDarkMode);

  const handleOnClose = (confirm: boolean) => {
    resolvePromise(confirm);
    setIsOpen(false);
  };

  const showAlert = async (content: string | ReactNode, options: AlertProps = {}) => {
    const {
      canEscapeKeyCancel = true,
      canOutsideClickCancel = true,
    } = options;
    setIsOpen(true);
    setAlertOptions({
      canEscapeKeyCancel,
      canOutsideClickCancel,
      ...options,
    });

    if (typeof content === 'string') {
      setAlertContent(<span>{content}</span>);
    } else {
      setAlertContent(content);
    }

    return new Promise<boolean>((resolve) => {
      setResolvePromise(() => resolve);   // function that passes a boolean to 'resolve'
    });
  };

  // The object passed to the provider is used/referenced in child components.
  // The object is recreated on each re-render of AlertProvider.
  // This would also re-render child components unnecessarily.
  // To prevent that, useMemo is used so that the object is not re-created on each re-render of
  // AlertProvider, such as when its state changes.
  const contextVal = useMemo(() => ({ showAlert }), [showAlert]);

  return (
    <AlertContext.Provider value={contextVal}>
      {children}
      <Alert
        className={classNames({ 'bp4-dark': darkMode })}
        isOpen={isOpen}
        onClose={handleOnClose}
        {...alertOptions}
      >
        {alertContent}
      </Alert>
    </AlertContext.Provider>
  );
};
