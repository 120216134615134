import React, { useCallback, useState } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import AppToaster from 'helpers/toaster';
import { useCancelRunningSimsMutation } from 'graphql/generated/graphql';
import styles from './index.module.css';

interface SimButtonProps {
  cancelId: string
  state: string
}

type icons = 'disable' | 'tick' | 'cross';

const SimButton : React.FC<SimButtonProps> = ({ cancelId, state }: SimButtonProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [cancelRunningSims] = useCancelRunningSimsMutation();

  const renderIcon = useCallback((): icons => {
    let icon: icons = 'disable';
    if (state === 'completed') {
      icon = 'tick';
    } else if (state === 'failed') {
      icon = 'cross';
    }
    return icon;
  }, [state]);

  const renderColor = useCallback((): Intent => {
    let color: Intent = Intent.NONE;
    if (state === 'completed') {
      color = Intent.SUCCESS;
    } else if (state === 'failed' || state === 'canceled') {
      color = Intent.DANGER;
    }
    return color;
  }, [state]);

  const cancelSim = async () => {
    setIsLoading(true);

    try {
      if (state === 'running') {
        cancelRunningSims({ variables: { cancelId } });
      }
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Error cancelling running sim',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={cancelSim}
      intent={renderColor()}
      minimal
      small
      loading={isLoading}
      icon={renderIcon()}
      className={styles.simButton}
    />
  );
};

export default SimButton;
