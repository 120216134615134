import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  NonIdealState,
  Intent,
  H6,
  Callout,
  Switch,
} from '@blueprintjs/core';
import { uniqBy, map, get } from 'lodash';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';

import AddRunsModal from './AddRunsModal';
import RUITForm from 'components/RUITForm';
import Select from 'components/Select';
import VerticalRunLogCard from './VerticalRunLogCard';
import { RUITSlice, selectActiveRUITId } from 'reducers/ruit';
import {
  Run,
  RUIT,
  RunField,
  useRUITByIdQuery,
  useRunFieldsQuery,
  useRUITsQuery,
} from 'graphql/generated/graphql';
import {
  SelectItem,
} from 'types';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

interface RunsInput {
  runs: Run[];
}

export default () => {
  useDocumentTitle('Apex Setup - Run Compare');
  const dispatch = useDispatch();
  const activeRUITId = useSelector(selectActiveRUITId);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [compareRuns, setCompareRuns] = useState<Run[]>([]);
  const [activeRUIT, setActiveRUIT] = useState<RUIT>();
  const [runFields, setRunFields] = useState<RunField[]>([]);
  const [ruitItems, setRUITItems] = useState<SelectItem<RUIT>[]>([]);
  const [isHorizontalLayout, setIsHorizontalLayout] = useState(true);
  const [isBaselineCompare, setIsBaselineCompare] = useState(true);

  const form = useForm<RunsInput>({ defaultValues: { runs: [] } });

  useRUITsQuery({
    onCompleted: data => setRUITItems(data.ruits.rows.map(r => ({ label: r.name, value: r }))),
  });

  useRunFieldsQuery({
    onCompleted: data => setRunFields(data.runFields.rows),
  });

  useRUITByIdQuery({
    variables: { id: activeRUITId || -1 },
    skip: !activeRUITId,
    onCompleted: data => setActiveRUIT(data.ruit as RUIT),
  });

  useEffect(() => {
    form.reset({ runs: compareRuns });
  }, [compareRuns]);

  const onModalSuccess = (rows: Run[]) => {
    setIsDialogOpen(false);
    setCompareRuns(uniqBy([...compareRuns, ...rows], 'id'));
  };

  const onPromoteRunToBaseline = (index: number) => {
    const oldCompareRuns = [...compareRuns];
    const newBaselineRun = compareRuns[index];
    oldCompareRuns.splice(index, 1);
    setCompareRuns([newBaselineRun, ...oldCompareRuns]);
  };

  const onRemoveRun = (index: number) => {
    const newCompareRuns = [...compareRuns];
    newCompareRuns.splice(index, 1);
    setCompareRuns(newCompareRuns);
  };

  const onRUITChange = (item: SelectItem<RUIT>) => {
    dispatch(RUITSlice.actions.setActiveRUITId(item.value.id));
  };

  const renderHorizontalRunCompare = () => {
    return (
      <FormProvider {...form}>
        <form>
          {map(compareRuns, (run, index) => {
            const runToCompare = (isBaselineCompare || (index === 0))
              ? compareRuns[0]
              : compareRuns[index - 1];

            return (
              <div key={`run-${run.id}`} className={styles.runsContainer}>
                {activeRUIT && (
                  <div className={styles.runContainer}>
                    <div className={styles.runHeader}>
                      <H6>{`${get(run, 'branch.head.name', '')} - ${get(run, 'branch.name', '')}`}</H6>
                      <div>
                        {(index !== 0) && (
                          <Button
                            icon="circle-arrow-up"
                            minimal
                            onClick={() => onPromoteRunToBaseline(index)}
                          />
                        )}
                        <Button
                          icon="cross"
                          intent={Intent.DANGER}
                          minimal
                          onClick={() => onRemoveRun(index)}
                        />
                      </div>
                    </div>
                    <RUITForm
                      runFields={runFields}
                      runIndex={index}
                      template={activeRUIT?.template}
                      baseline={runToCompare}
                      readOnly
                    />
                  </div>
                )}
              </div>
            );
          })}
        </form>
      </FormProvider>
    );
  };

  const renderVerticalRunCompare = () => {
    if (!activeRUIT || !compareRuns.length) return null;

    return (
      <ScrollSync>
        <div className={styles.verticalCompareContainer}>
          <ScrollSyncPane>
            <div className={styles.baselineRunLogContainer}>
              <VerticalRunLogCard
                run={compareRuns[0]}
                index={0}
                activeRUIT={activeRUIT}
                runFields={runFields}
                onPromoteRunToBaseline={onPromoteRunToBaseline}
                onRemoveRun={onRemoveRun}
              />
            </div>
          </ScrollSyncPane>
          <ScrollSyncPane>
            <div className={styles.comparedRunLogsContainer}>
              {map(compareRuns, (run: Run, index) => {
                if (index === 0) return null;
                const runToCompare = isBaselineCompare ? compareRuns[0] : compareRuns[index - 1];
                return (
                  <div className={styles.comparedRunLogContainer}>
                    <VerticalRunLogCard
                      run={run}
                      baseline={runToCompare}
                      index={index}
                      activeRUIT={activeRUIT}
                      runFields={runFields}
                      onPromoteRunToBaseline={onPromoteRunToBaseline}
                      onRemoveRun={onRemoveRun}
                    />
                  </div>
                );
              })}
            </div>
          </ScrollSyncPane>
        </div>
      </ScrollSync>
    );
  };

  return (
    <div>
      <div className={styles.headerBar}>
        <Button
          text="Add Runs"
          icon="plus"
          onClick={() => setIsDialogOpen(true)}
        />
        <div className={styles.headerBarRight}>
          <Switch
            className={styles.layoutSwitch}
            checked={isBaselineCompare}
            innerLabelChecked="baseline"
            innerLabel="sequential"
            onChange={() => setIsBaselineCompare(!isBaselineCompare)}
          />
          <Switch
            className={styles.layoutSwitch}
            checked={isHorizontalLayout}
            innerLabelChecked="horizontal"
            innerLabel="vertical"
            onChange={() => setIsHorizontalLayout(!isHorizontalLayout)}
          />
          <Select
            value={ruitItems.find(ri => ri.value.id === activeRUITId)}
            items={ruitItems}
            noSelectionText="Select RUIT"
            onChange={onRUITChange}
          />
        </div>
      </div>
      <div>
        {!activeRUIT && (
          <div className={styles.emptyMainSection}>
            <Callout
              intent={Intent.WARNING}
              title="No active RUIT selected!"
            />
          </div>
        )}
        {(activeRUIT && compareRuns.length === 0) && (
          <div className={styles.emptyMainSection}>
            <NonIdealState
              icon="search"
              title="No runs selected"
              description="No runs have been selected to compare yet"
            />
          </div>
        )}
        {(activeRUIT && compareRuns.length !== 0) && (
          isHorizontalLayout ? renderHorizontalRunCompare() : renderVerticalRunCompare()
        )}
      </div>
      <AddRunsModal
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSuccess={onModalSuccess}
      />
    </div>
  );
};
