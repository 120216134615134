import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FormGroup, H4, Intent, NonIdealState } from '@blueprintjs/core';
import { get, isNil, size } from 'lodash';

import { PartConfigProperty, PropertyType } from 'graphql/generated/graphql';
import { RHFCheckbox, RHFNumericInput, RHFSelect, RHFTextInput } from 'components/RHFInputs';

import styles from './index.module.css';

export default () => {
  const { control, formState: { errors }, getValues, watch } = useFormContext();
  const spec = watch('spec');
  const { fields } = useFieldArray({
    control,
    name: 'properties',
  });
  const filteredFieldCount = useMemo(() => {
    return fields.filter((field, index) => {
      const configProperty = getValues(`properties.${index}.config_property`);
      return configProperty.specs.length === 0 || configProperty.specs.includes(spec);
    }).length;
  }, [fields, spec]);

  const renderInput = (configProperty: PartConfigProperty, index: number) => {
    const { required } = configProperty;
    const { display_name: displayName, min, max, type } = configProperty.property;
    const rules: Record<string, unknown> = {};
    if (required) rules.required = `${displayName} is required`;

    switch (type) {
      case PropertyType.BOOLEAN:
        return (
          <RHFCheckbox
            controllerProps={{
              control,
              defaultValue: false,
              name: `properties.${index}.value`,
              rules,
            }}
          />
        );
      case PropertyType.COORDINATE:
        return (
          <div className={styles.coordinateContainer}>
            <RHFNumericInput
              controllerProps={{
                control,
                name: `properties.${index}.value.x`,
                rules,
              }}
              inputProps={{
                inputClassName: styles.coordinateInput,
                intent: get(errors, `properties.${index}.value.x`) && Intent.DANGER,
                placeholder: 'X',
              }}
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `properties.${index}.value.y`,
                rules,
              }}
              inputProps={{
                inputClassName: styles.coordinateInput,
                intent: get(errors, `properties.${index}.value.y`) && Intent.DANGER,
                placeholder: 'Y',
              }}
            />
            <RHFNumericInput
              controllerProps={{
                control,
                name: `properties.${index}.value.z`,
                rules,
              }}
              inputProps={{
                inputClassName: styles.coordinateInput,
                intent: get(errors, `properties.${index}.value.z`) && Intent.DANGER,
                placeholder: 'Z',
              }}
            />
          </div>
        );
      case PropertyType.NUMBER: {
        if (!isNil(min)) rules.min = { value: min, message: `Must be greater than ${min}` };
        if (!isNil(max)) rules.max = { value: max, message: `Must be less than ${max}` };
        return (
          <RHFNumericInput
            controllerProps={{
              control,
              name: `properties.${index}.value`,
              rules,
            }}
            inputProps={{
              intent: get(errors, `properties.${index}.value`) && Intent.DANGER,
            }}
            useStringValue
          />
        );
      }
      case PropertyType.STRING: {
        if (size(configProperty.values) || size(configProperty.property.values)) {
          let propertyValues = configProperty.values;
          if (propertyValues?.length === 0) {
            propertyValues = configProperty.property.values;
          }
          const items = propertyValues?.map(v => ({
            label: v,
            value: v,
          })) ?? [];
          return (
            <RHFSelect
              controllerProps={{
                control,
                name: `properties.${index}.value`,
                rules,
              }}
              intent={get(errors, `properties.${index}.value`) && Intent.DANGER}
              items={items}
            />
          );
        }
        return (
          <RHFTextInput
            controllerProps={{
              control,
              name: `properties.${index}.value`,
              rules,
            }}
            inputProps={{
              intent: get(errors, `properties.${index}.value`) && Intent.DANGER,
            }}
          />
        );
      }
      default: return null;
    }
  };

  return (
    <>
      <H4>Part Properties</H4>
      {filteredFieldCount === 0 && (
        <NonIdealState
          className={styles.noProperties}
          description="Either no properties exist for this part's spec, or no properties have been added to this part's config (yet!)"
          icon="clean"
          title="No properties"
        />
      )}
      {filteredFieldCount > 0 && (
        <ol className={styles.propertyList}>
          {fields.map((field, index) => {
            const configProperty = getValues(`properties.${index}.config_property`);
            if (configProperty.specs.length !== 0 && !configProperty.specs.includes(spec)) return null;

            return (
              <li key={field.id}>
                <FormGroup
                  className={styles.propertyFormGroup}
                  contentClassName={styles.propertyFormGroupContent}
                  helperText={<ErrorMessage errors={errors} name={`properties.${index}.value`} />}
                  inline
                  intent={get(errors, `properties.${index}.value`) ? Intent.DANGER : Intent.NONE}
                  label={configProperty.property.display_name}
                >
                  {renderInput(configProperty, index)}
                </FormGroup>
              </li>
            );
          })}
        </ol>
      )}
    </>
  );
};
