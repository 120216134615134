import { GraphQLErrorResponse } from '../types';

export const isGraphQLErrorResponse = (error: any): error is GraphQLErrorResponse => { // eslint-disable-line @typescript-eslint/no-explicit-any
  return error && Array.isArray(error.graphQLErrors);
};

export const extractInvalidFields = (error: GraphQLErrorResponse): string[] => {
  const invalidFields: string[] = [];

  error.graphQLErrors.forEach(err => {
    const match = err.message.match(/Field "([^"]+)" of required type "([^"]+)" was not provided/);
    if (match) {
      invalidFields.push(match[1]);
    }
  });

  return invalidFields;
};
