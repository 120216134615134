import { useState, useEffect } from 'react';
import {
  useLocation,
  useParams,
  unstable_useBlocker,      // eslint-disable-line camelcase
  unstable_BlockerFunction, // eslint-disable-line camelcase
  useSearchParams,
  useRouteLoaderData,
} from 'react-router-dom';
import { SimResult } from 'context/SubscriptionContext';
import { Overlay, Spinner, Intent, Button } from '@blueprintjs/core';
import { get, find, cloneDeep, set, isEqual, isEmpty, join } from 'lodash';
import {
  Part,
  SetupBranch,
  useCommitSetupMutation,
  SetupByBranchIdDocument,
  useSUITFormPartsLazyQuery,
  usePartsCompareDataLazyQuery,
  usePartConfigIdByTypeNameQuery,
  SetupByIdDocument,
  SetupBranchesByRootIdDocument,
  useSetupBranchesByIdsQuery,
  useRequestPartPlottingMutation,
  useGetSimResultSubscription,
  Scalars,
  Spec,
} from 'graphql/generated/graphql';
import { SetupSelection, GQLSetup } from 'types';
import TitleBar from '../TitleBar';
import { useSelector } from 'react-redux';
import ShockChart, { ChartDataItem, PartInput } from './ShockChart';
import { SetupToSetupInput } from 'helpers/converter';
import { excludeSetupMeta } from 'helpers/setup';
import { addWarningListener, removeWarningListener } from 'helpers/browserCloseWarning';
import AppToaster from 'helpers/toaster';
import styles from './index.module.css';
import { baseName } from '../../../config';
import { selectSetupCompareState  } from '../../../reducers/setupCompare';
import { selectSetupColorsState } from '../../../reducers/setupColors';
import { hasPermission } from '../../../helpers/permissions';
import { PermissionName, defaultColor } from '../../../constants';
import useDocumentTitle from '../../../hooks/useDocumentTitle';

const shockTypeName = 'shock';

interface SetupParts {
  [key: string]: Part;
}

export default () => {
  const location = useLocation();
  const params = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { permissions } } = useRouteLoaderData('root') as any;
  const { data: shockPlotData } = useGetSimResultSubscription();
  const [searchParams] = useSearchParams();
  const branchId = Number(params.branchId);
  const compareIds = searchParams.get('ids');
  const [branchIds, setBranchIds] = useState<number []>([...compareIds?.split(',').map(Number) ?? [], branchId]);
  const [cleanBaselineSetup, setCleanBaselineSetup] = useState<GQLSetup>();
  const [branches, setBranches] = useState<SetupSelection[]>([]);
  const [parts, setParts] = useState<Part[]>([]);
  const [partConfigId, setPartConfigId] = useState<number>();
  const [customPlottingParts, setCustomPlottingParts] = useState<PartInput []>([]);
  const [baselineLF, setBaselineLF] = useState<Part>();
  const [baselineRF, setBaselineRF] = useState<Part>();
  const [baselineLR, setBaselineLR] = useState<Part>();
  const [baselineRR, setBaselineRR] = useState<Part>();
  const [fetchParts, setFetchParts] = useState<boolean>(true);
  const [typeLF, setTypeLF] = useState<string>('');
  const [typeRF, setTypeRF] = useState<string>('');
  const [typeLR, setTypeLR] = useState<string>('');
  const [typeRR, setTypeRR] = useState<string>('');
  const [setupPartsData, setSetupPartsData] = useState<SetupParts>();
  const [chartDataLF, setChartDataLF] = useState<ChartDataItem[]>([]); // ChartDataLF Contains charting data for all setups
  const [chartDataRF, setChartDataRF] = useState<ChartDataItem[]>([]); // ChartDataRF Contains charting data for all setups
  const [chartDataLR, setChartDataLR] = useState<ChartDataItem[]>([]); // ChartDataLR Contains charting data for all setups
  const [chartDataRR, setChartDataRR] = useState<ChartDataItem[]>([]); // ChartDataRR Contains charting data for all setups
  const [isDirty, setIsDirty] = useState(false);
  const selectedSetups = useSelector(selectSetupCompareState);
  const activeSetupColorsState = useSelector(selectSetupColorsState);
  const [isSaving, setIsSaving] = useState(false);

  useDocumentTitle(
    cleanBaselineSetup && cleanBaselineSetup.name ? `Apex Setup - ${cleanBaselineSetup.name}` : 'Apex Setup'
  );

  const { loading: partConfigLoading } = usePartConfigIdByTypeNameQuery({
    variables: { typeName: shockTypeName },
    onCompleted: data => setPartConfigId(data.partConfig?.id),
  });

  // Query for VRP/COEFF plotting api request
  const [requestPartPlotting] = useRequestPartPlottingMutation();

  // Query to fetch new Branches/setups
  const { loading: setupsLoading, data: branchData } = useSetupBranchesByIdsQuery({
    variables: { branchIds },
    fetchPolicy: 'network-only',
  });

  // Query for part charting data used for graph plotting
  const [getPartData, { called, loading, data: partData }] = usePartsCompareDataLazyQuery({
    fetchPolicy: 'network-only', // Ensures we always fetch the latest data
  });

  // Query for parts used for Part selection component.
  const [getParts, { loading: partsLoading }] = useSUITFormPartsLazyQuery({ fetchPolicy: 'network-only', onCompleted: data => setParts(data.parts.rows as Part[]) });

  const [commitSetup] = useCommitSetupMutation();

  const getBranchColor = (branchId: number) => (activeSetupColorsState[branchId] || defaultColor);

  // Get ChartDataItems for Coeff/VRP shock data
  const createVRPCoeffChartDataItems = (plotName: string, setupId: number, setupColor: string, setupName: string, shockData?: number[][]) => {
    const plotArray = shockData || [];

    const ChartDataItems = plotArray.map((item: number[]) => ({
      x: item[0],
      y: item[1],
      name: plotName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // Get ChartDataItems for tabular shock data
  const applyTabularChartDataItems = (plotName: string, setupId: number, setupColor: string, setupName: string, shockData?: number[][]) => {
    const tabularArray = shockData || [];

    const ChartDataItems = tabularArray.map((item: number[]) => ({
      x: Math.abs(item[0]),
      y: item[1],
      name: plotName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // This function added a  temp data item to the chart, so that specific setup/position/part is shown even if it has no valid charting data.
  const AddTempChartDataItems = (plotName: string, setupId: number, setupColor: string, setupName: string, shockData?: number[][]) => {
    const tabularArray = shockData || [];

    const ChartDataItems = tabularArray.map((item: number[]) => ({
      x: item[0],
      y: item[1],
      name: plotName,
      displayName: setupName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  // This function locates existing chartDataItems for a setup in a position LR/RF/LR/RR of the car with returned api plotting data
  const updateCoeffVrpChartData = (updatedChartData: ChartDataItem[], result: SimResult): ChartDataItem[] => {
    const foundCD: ChartDataItem | undefined = updatedChartData.find(cdi => cdi.id.toString() === result.setupId);

    let updatedArray: ChartDataItem[] = [];

    if (foundCD) {
      const clonedFoundCD = JSON.parse(JSON.stringify(foundCD));
      updatedArray = [
        ...updatedChartData.filter(item => item.id !== foundCD.id),
        ...createVRPCoeffChartDataItems(clonedFoundCD.name, clonedFoundCD.id, clonedFoundCD.color, get(clonedFoundCD, 'displayName', ''), get(result, 'output.velocities', [])),
      ];
    } else {
      // If foundCD is undefined, simply return the original array
      updatedArray = [...updatedChartData];
    }

    return updatedArray;
  };

  // This useEffect updates the graph chartdata with VRP/Coeff results.
  useEffect(() => {
    if (shockPlotData && shockPlotData.simResult && shockPlotData.simResult.dataType === 'shock_plot') {
      switch (shockPlotData.simResult.pos) {
        case 'LF':
          setChartDataLF(prevState => updateCoeffVrpChartData([...prevState], shockPlotData.simResult));
          break;
        case 'RF':
          setChartDataRF(prevState => updateCoeffVrpChartData([...prevState], shockPlotData.simResult));
          break;
        case 'LR':
          setChartDataLR(prevState => updateCoeffVrpChartData([...prevState], shockPlotData.simResult));
          break;
        case 'RR':
          setChartDataRR(prevState => updateCoeffVrpChartData([...prevState], shockPlotData.simResult));
          break;
        default:
          break;
      }
    }
  }, [shockPlotData]);

  // Appends selected setup IDs to the URL query params
  useEffect(() => {
    if (selectedSetups && selectedSetups[branchId]) {
      let newCompareIds: number [] = [];
      let compareUrl = baseName === '/' ? `${window.location.origin}${location.pathname}` : `${window.location.origin}${baseName}${location.pathname}`;
      if (selectedSetups[branchId].length > 0) {
        newCompareIds = selectedSetups[branchId];
        if (!isEmpty(newCompareIds)) compareUrl += `?ids=${join(newCompareIds)}`;
        window.history.replaceState(null, '', compareUrl);
      }
      setBranchIds([...newCompareIds, branchId]);
    }
  }, [selectedSetups]);

  // Use effect to query server for plotting vrp/coeff shocks request
  useEffect(() => {
    if (customPlottingParts.length > 0) {
      requestPartPlotting({
        variables: { input: customPlottingParts, partType: 'shock' },
        fetchPolicy: 'network-only',
      })
        .catch(error => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error submitted plotting request: ${error.message}`,
          });
        });
    }
  }, [customPlottingParts, requestPartPlotting]);

  // UseEffect for branch data loaded
  useEffect(() => {
    if (branchData && branchIds.length > 0) {
      // Process the data fetched by the hook
      const branchHeads = branchData.setupBranches.map(b => ({
        branch: {
          id: b.id,
          name: b.name,
        },
        setup: { ...b.head },
      }));

      const [...branches] = branchHeads.reduce((acc, b) => {
        if (b.branch.id === branchId) acc.unshift(b);
        else acc.push(b);
        return acc;
      }, [] as SetupSelection[]);

      const baseline = branches.find(b => b.branch.id === branchId);
      if (baseline && baseline.setup) {
        setCleanBaselineSetup(baseline.setup);
      }
      setBranches([...branches]);
      setTypeLF('');
      setTypeRF('');
      setTypeLR('');
      setTypeRR('');
      setChartDataLF([]);
      setChartDataRF([]);
      setChartDataLR([]);
      setChartDataRR([]);
    }
  }, [branchIds, branchData]);

  // This useEffect responds to partData being loaded and setupsPartsData needing to be returned for ChartData plotting.
  // In the future this foreach look can be updated to a single request with variable of Ids instead of single api requests
  useEffect(() => {
    // Get unique list of parts, if multiple setups own the same part, only fetch 1 of that part.
    if (setupPartsData && Object.keys(setupPartsData).length > 0) {
      const uniqueParts = Object.values(setupPartsData).filter((part, index, self) => index === self.findIndex((t) => t.id === part.id));
      const uniquePartIds = uniqueParts.map(p => p.id);
      getPartData({
        variables: {
          input: {
            ids: uniquePartIds,
          },
        },
        fetchPolicy: 'network-only',
      });
    }
  }, [setupPartsData, getPartData, called]);

  // Gets Shock fields from Branch/Setup
  const getSetupShockFields = (branch: SetupSelection) => {
    return {
      lfId: get(branch, 'setup.data.vehicle.shocks.lf'),
      rfId: get(branch, 'setup.data.vehicle.shocks.rf'),
      lrId: get(branch, 'setup.data.vehicle.shocks.lr'),
      rrId: get(branch, 'setup.data.vehicle.shocks.rr'),
    };
  };

  // VRP/COEFF Plotting PartInput
  const getPlotPartInput = (partId: number, setupId: number, pos: string, partFormat: string, description: string, spec: Spec, data: Scalars) => {
    return {
      partId,
      setupId,
      pos,
      partFormat,
      description,
      spec,
      data,
    };
  };

  // This useEffect responds to loaded branches/setups, and gathers shock data needed
  // It will gather all parts needed in separate useEffect
  useEffect(() => {
    if (branches.length > 0 && parts.length > 0) {
      const setupParts: SetupParts = {};
      branches.forEach(branch => {
        // Extracting part IDs
        const setupShockFields = getSetupShockFields(branch);
        // Finding all position setup parts
        const partLF = find(parts, (o) => (o.id === setupShockFields.lfId));
        if (partLF && branch) {
          setupParts[`${branch.branch.id.toString()}_LF`] = partLF;
        }
        const partRF = find(parts, (o) => (o.id === setupShockFields.rfId));
        if (partRF && branch) {
          setupParts[`${branch.branch.id.toString()}_RF`] = partRF;
        }
        const partLR = find(parts, (o) => (o.id === setupShockFields.lrId));
        if (partLR && branch) {
          setupParts[`${branch.branch.id.toString()}_LR`] = partLR;
        }
        const partRR = find(parts, (o) => (o.id === setupShockFields.rrId));
        if (partRR && branch) {
          setupParts[`${branch.branch.id.toString()}_RR`] = partRR;
        }
      });

      // Set parts to be plotted based on position/graph
      setSetupPartsData(setupParts);
    }
  }, [branches, parts, partData]);

  // getPartFormat function determines the format Tabular/VRP or Coeff currently
  const getPartFormat = (part: Part): string => {
    if (part.data.shock_data) {
      return 'tabular';
    } else if (part.data.base_model && part.data.base_model.model_type && part.data.base_model.model_type === 'vrp') {
      return part.data.base_model.model_type;
    } else if (part.data && part.data.CBleed) {
      return 'coeff';
    }
    return '';
  };

  // Update Chart data for VRP and Coeff
  const updateChartData = (chartArray: ChartDataItem[], chartType: string, desc: string, branchId: number, setupName: string, addTemp: boolean,  shockData?: number [][]) => {
    const filteredData = chartArray.filter(item => item.id !== branchId);
    let newChartDataItem: ChartDataItem [];
    if (addTemp) {
      newChartDataItem = AddTempChartDataItems(desc, branchId, getBranchColor(branchId), setupName, [[0, 0]]);
    } else {
      newChartDataItem = applyTabularChartDataItems(desc, branchId, getBranchColor(branchId), setupName, shockData);
    }

    return [...filteredData, ...newChartDataItem];
  };

  // useEffect responds to part data returned from Graphql requests.
  // If not Tabular, we will make second API call to request VRP or COEFF plotting data
  // This data will be sent via WebSocket message.
  useEffect(() => {
    if (branches && partData && partData.parts && partData.parts.rows.length > 0) {
      const customPlottingParts: PartInput[] = [];
      let updatedChartDataLF: ChartDataItem[] = [...chartDataLF];
      let updatedChartDataRF: ChartDataItem[] = [...chartDataRF];
      let updatedChartDataLR: ChartDataItem[] = [...chartDataLR];
      let updatedChartDataRR: ChartDataItem[] = [...chartDataRR];

      branches.forEach(branch => {
        // Extracting Tabular part plotting data for each setup
        const returnedParts = partData.parts.rows;
        const isBaseline = branch.branch.id === branchId;
        returnedParts.forEach(part => {
          const setupShockFields = getSetupShockFields(branch);
          if (setupShockFields.lfId && setupShockFields.lfId === part.id) {
            if (part && isBaseline) {
              setBaselineLF(part as Part);
              setTypeLF(getPartFormat(part as Part));
            }
            if (part.data.shock_data) { // Is Tabular Shock
              updatedChartDataLF = updateChartData(updatedChartDataLF, 'LF', `${part.description} - Tabular`, branch.branch.id, branch.setup.name, false, part.data.shock_data);
            } else if (part.data.base_model && part.data.base_model.model_type && part.data.base_model.model_type === 'vrp') { // Is VRP Shock
              updatedChartDataLF = updateChartData(updatedChartDataLF, 'LF', `${part.description} - VRP`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'LF', 'vrp', part.description, part.spec, part.data) as PartInput);
            } else if (part.data && part.data.CBleed) { // Is Coeff Shock
              updatedChartDataLF = updateChartData(updatedChartDataLF, 'LF', `${part.description} - Coeff`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'LF', 'coeff', part.description, part.spec, part.data) as PartInput);
            }
          }
          if (setupShockFields.rfId && setupShockFields.rfId === part.id) {
            if (part && isBaseline) {
              setBaselineRF(part as Part);
              setTypeRF(getPartFormat(part as Part));
            }
            if (part.data.shock_data) { // Is Tabular Shock
              updatedChartDataRF = updateChartData(updatedChartDataRF, 'RF', `${part.description} - Tabular`, branch.branch.id, branch.setup.name, false, part.data.shock_data);
            } else if (part.data.base_model && part.data.base_model.model_type && part.data.base_model.model_type === 'vrp') { // Is VRP Shock
              updatedChartDataRF = updateChartData(updatedChartDataRF, 'RF', `${part.description} - VRP`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'RF', 'vrp', part.description, part.spec, part.data) as PartInput);
            } else if (part.data && part.data.CBleed) { // Is Coeff Shock
              updatedChartDataRF = updateChartData(updatedChartDataRF, 'RF', `${part.description} - Coeff`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'RF', 'coeff', part.description, part.spec, part.data) as PartInput);
            }
          }
          if (setupShockFields.lrId && setupShockFields.lrId === part.id) {
            if (part && isBaseline) {
              setBaselineLR(part as Part);
              setTypeLR(getPartFormat(part as Part));
            }
            if (part.data.shock_data) { // Is Tabular Shock
              updatedChartDataLR = updateChartData(updatedChartDataLR, 'LR', `${part.description} - Tabular`, branch.branch.id, branch.setup.name, false, part.data.shock_data);
            } else if (part.data.base_model && part.data.base_model.model_type && part.data.base_model.model_type === 'vrp') { // Is VRP Shock
              updatedChartDataLR = updateChartData(updatedChartDataLR, 'LR', `${part.description} - VRP`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'LR', 'vrp', part.description, part.spec, part.data) as PartInput);
            } else if (part.data && part.data.CBleed) { // Is Coeff Shock
              updatedChartDataLR = updateChartData(updatedChartDataLR, 'LR', `${part.description} - Coeff`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'LR', 'coeff', part.description, part.spec, part.data) as PartInput);
            }
          }
          if (setupShockFields.rrId && setupShockFields.rrId === part.id) {
            if (part && isBaseline) {
              setBaselineRR(part as Part);
              setTypeRR(getPartFormat(part as Part));
            }
            if (part.data.shock_data) { // Is Tabular Shock
              updatedChartDataRR = updateChartData(updatedChartDataRR, 'RR', `${part.description} - Tabular`, branch.branch.id, branch.setup.name, false, part.data.shock_data);
            } else if (part.data.base_model && part.data.base_model.model_type && part.data.base_model.model_type === 'vrp') { // Is VRP Shock
              updatedChartDataRR = updateChartData(updatedChartDataRR, 'RR', `${part.description} - VRP`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'RR', 'vrp', part.description, part.spec, part.data) as PartInput);
            } else if (part.data && part.data.CBleed) { // Is Coeff Shock
              updatedChartDataRR = updateChartData(updatedChartDataRR, 'RR', `${part.description} - Coeff`, branch.branch.id, branch.setup.name, true);
              customPlottingParts.push(getPlotPartInput(part.id, branch.branch.id, 'RR', 'coeff', part.description, part.spec, part.data) as PartInput);
            }
          }
        });
      });

      // Set Custom Plotting Data for Coeff/VRP request.
      setCustomPlottingParts(customPlottingParts);

      // Set shock Tabular data on position/graph
      setChartDataLF(updatedChartDataLF);
      setChartDataRF(updatedChartDataRF);
      setChartDataLR(updatedChartDataLR);
      setChartDataRR(updatedChartDataRR);
    }
  }, [partData, setupPartsData]);

  // Get Parts based on part Config id to determine the type
  useEffect(() => {
    if (partConfigId && fetchParts) {
      getParts({
        variables: {
          input: {
            filters: {
              part_config: [partConfigId],
            },
          },
        },
      })
        .then((data) => {
          if (data.data?.parts.__typename === 'PartsResult') {
            setFetchParts(false);
          }
        });
    }
  }, [partConfigId, getParts, fetchParts]);

  // If Baseline setup is not dirty/edited compare with Clean and Warn user.
  useEffect(() => {
    if (!branches || !branches.length || !cleanBaselineSetup) return;
    const baselineBranch = branches.find(b => b.branch.id === branchId);
    if (baselineBranch && baselineBranch.setup && !isSaving) {
      const isDirtyCheck = !isEqual(excludeSetupMeta(cleanBaselineSetup), excludeSetupMeta(baselineBranch.setup));
      const prevIsDirty = isDirty;
      setIsDirty(isDirtyCheck);
      if (isDirtyCheck !== prevIsDirty) {
        if (isDirtyCheck) addWarningListener();
        else removeWarningListener();
      }
    }
  }, [branches, cleanBaselineSetup, isSaving, isDirty]);

  // Removes the listener when deconstructing this component
  useEffect(() => removeWarningListener, []);

  const clearStatePositionFormat = (pos: string) => {
    switch (pos) {
      case 'LF':
        setTypeLF('');
        break;
      case 'RF':
        setTypeRF('');
        break;
      case 'LR':
        setTypeLR('');
        break;
      case 'RR':
        setTypeRR('');
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line
  const blockerFunc: unstable_BlockerFunction = () => {
    if (isDirty) {
      // eslint-disable-next-line
      return !window.confirm(
        'There are unsaved changes. Navigate away from this view?'
      );
    }
    return false;
  };
  unstable_useBlocker(blockerFunc);

  // On change baseline setup handler   DOES THIS CHANGE THE BASELINE SETUP OR THE BASELINE XX Baseline Part
  const onChangeHandler = (path: string, value: number, pos: string, newPart?: Part) => {
    if (!branches || !branches.length) return;

    const updatedBranches = cloneDeep(branches);
    const baselineBranch = updatedBranches.find(b => b.branch.id === branchId);
    if (baselineBranch && baselineBranch.setup) {
      // Update SelectedPart for location
      // Update the Baseline Branch with the change.
      set(baselineBranch.setup, `data.${path}`, value);

      setBranches(updatedBranches);
      clearStatePositionFormat(pos);

      // if new part, issue refetch of parts
      if (newPart) {
        setFetchParts(true);
      }
    }
  };

  const onUpdatePlotHandler = (part: PartInput) => {
    // Check if a part with the same pos already exists in the array
    const existingPartIndex = customPlottingParts.findIndex(p => p.pos === part.pos);

    if (existingPartIndex !== -1) {
      // If found, update the existing part
      customPlottingParts[existingPartIndex] = part;
    } else {
      // If not found, push the new part into the array
      customPlottingParts.push(part);
    }

    // Use a functional update to ensure the effect runs after setting the state
    setCustomPlottingParts(prevState => [...prevState]);
  };

  // Handler for Saving Baseline Setup
  const onSave = async () => {
    if (!baselineBranch) return;

    setIsSaving(true);

    try {
      await commitSetup({
        variables: {
          branchId,
          setup: SetupToSetupInput(baselineBranch.setup),
        },
        onCompleted: (updatedBaselineSetup) => {
          if (updatedBaselineSetup) {
            setCleanBaselineSetup(updatedBaselineSetup.commitedSetup as GQLSetup);
          }
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Successfully saved setup',
          });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error saving setup: ${e.message}`,
          });
        },
        update: (cache, { data: mutationData }, { variables }) => {
          if (mutationData?.commitedSetup) {
            cache.writeQuery({
              query: SetupByBranchIdDocument,
              variables: { branchId: variables?.branchId },
              data: {
                setup: { ...mutationData.commitedSetup },
              },
            });

            cache.writeQuery({
              query: SetupByIdDocument,
              variables: { id: mutationData.commitedSetup.id },
              data: {
                setup: mutationData.commitedSetup,
              },
            });

            cache.updateQuery({
              query: SetupBranchesByRootIdDocument,
              variables: { rootId: baselineBranch?.setup.root?.id },
            }, queryData => {
              if (!queryData) return undefined;
              return {
                branches: queryData.branches.map((b: SetupBranch) => {
                  if (b.id !== branchId) return b;
                  return {
                    ...b,
                    head: {
                      ...b.head,
                      id: mutationData.commitedSetup?.id,
                    },
                  };
                }),
              };
            });
          }
        },
      });
    } catch (error) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'An unexpected error occurred while saving setup',
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (!branches || !branches.length) return null;
  const baselineBranch = branches.find(b => b.branch.id === branchId);
  if (!baselineBranch) return null;

  if (!hasPermission(PermissionName.SETUP_WRITE, permissions)
    && !hasPermission(PermissionName.SETUP_READ, permissions)
    && !hasPermission(PermissionName.SETUP_SHOCKS_READ, permissions)
    && !hasPermission(PermissionName.SETUP_SHOCKS_WRITE, permissions)) return null;

  let readOnly = true;
  if (hasPermission(PermissionName.SETUP_WRITE, permissions) || hasPermission(PermissionName.SETUP_SHOCKS_WRITE, permissions)) readOnly = false;

  return (
    <div className={styles.mainContainer}>
      <TitleBar setup={baselineBranch.setup} branch={baselineBranch.branch.name}>
        <div className={styles.controlsContainer}>
          <Button
            disabled={!isDirty}
            icon="floppy-disk"
            intent={Intent.PRIMARY}
            onClick={onSave}
            text="Save"
          />
        </div>
      </TitleBar>
      <div className={styles.chartContainer}>
        <div className={styles.chartRow}>
          <ShockChart
            position="LF"
            chartData={chartDataLF ? chartDataLF.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineLF}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.shocks.lf"
            partType={typeLF}
            readOnly={readOnly}
          />
          <ShockChart
            position="RF"
            chartData={chartDataRF ? chartDataRF.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineRF}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.shocks.rf"
            partType={typeRF}
            readOnly={readOnly}
          />
        </div>
        <div className={styles.chartRow}>
          <ShockChart
            position="LR"
            chartData={chartDataLR ? chartDataLR.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineLR}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.shocks.lr"
            partType={typeLR}
            readOnly={readOnly}
          />
          <ShockChart
            position="RR"
            chartData={chartDataRR ? chartDataRR.sort((a, b) => {
              return branchIds.indexOf(a.id) - branchIds.indexOf(b.id);
            }) : []}
            setupId={branchId}
            partConfigId={partConfigId}
            spec={baselineBranch.setup.spec}
            selectedPart={baselineRR}
            parts={parts}
            onChangeHandler={onChangeHandler}
            onUpdatePlotData={onUpdatePlotHandler}
            path="vehicle.shocks.rr"
            partType={typeRR}
            readOnly={readOnly}
          />
        </div>
      </div>
      <Overlay
        isOpen={setupsLoading || loading || partConfigLoading || partsLoading}
        className="bp3-overlay-scroll-container"
      >
        <div className={styles.loadingSpinner}>
          <Spinner size={50} />
        </div>
      </Overlay>
    </div>
  );
};
