import { Intent, ProgressBar, Spinner } from '@blueprintjs/core';
import { SimStatus } from 'context/SubscriptionContext';
import React, { useEffect, useState } from 'react';

type Visual = 'bar' | 'spinner';

interface SimProgressVisualProps {
  visualType: Visual;
  cancelId: string;
  percentage: number;
  status: string;
  curr?: number;
  total?: number;
  simStatus?: SimStatus;
}

const SimProgressVisual : React.FC<SimProgressVisualProps> = (props : SimProgressVisualProps) => {
  const { visualType, cancelId, percentage, status, curr, total, simStatus } = props;
  const [color, setColor] = useState<Intent>(Intent.NONE);
  const [isRunning, setIsRunning] = useState<boolean>(true);
  const [currentPercentage, setCurrentPercentage] = useState<number>(0);
  const [prevCancelId, setPrevCancelId] = useState<string>(cancelId);

  useEffect(() => {
    // Reset when sim ID changes
    if (cancelId !== prevCancelId) {
      setIsRunning(true);
      setColor(Intent.NONE);
      setCurrentPercentage(0);
      setPrevCancelId(cancelId);
    }
  }, [cancelId]);

  useEffect(() => {
    if (curr === total && status === 'Finished') {
      setColor(Intent.SUCCESS);
      setCurrentPercentage(1);
      setIsRunning(false);
    } else {
      if (percentage !== 0) {
        setCurrentPercentage(percentage / 100);
      } else {
        setCurrentPercentage(0);
      }

      if (status === 'Failed' || simStatus?.state === 'failed') {
        setColor(Intent.DANGER);
        setIsRunning(false);
      } if (simStatus?.state === 'canceled' || simStatus?.state === 'canceling') {
        setColor(Intent.NONE);
        setIsRunning(false);
      } else {
        setColor(Intent.PRIMARY);
        setIsRunning(true);
      }
    }
  }, [status, percentage, simStatus]);

  return visualType === 'bar' ? <ProgressBar value={currentPercentage} intent={color} animate={isRunning} stripes={isRunning} />
    : <Spinner value={currentPercentage} intent={color} size={20} />;
};

export default SimProgressVisual;
