import React, { createContext, useContext, useState, useMemo, useEffect, ReactNode, useCallback } from 'react';
import {
  useGetSimStatusSubscription,
  useGetSimResultSubscription,
  useGetSimProgressSubscription,
  useGetBatchStatusSubscription,
  useSimProgressesByUserLazyQuery,
  useSimStatusesByUserLazyQuery,
  useClearSimProgressMutation,
} from 'graphql/generated/graphql';
import { find, indexOf } from 'lodash';

interface SubscriptionProviderProps {
  children: ReactNode;
}

export type SimStatus = {
  isBatch: boolean;
  state: string;
  cancelId: string;
  groupId: string;
  requestId: string;
  userId: string;
  simEngine: string;
  setupId: string;
  applyOptimizationResult?: boolean;
  vehicle: string;
  type: string;
  displayName: string;
  series: string;
  submitted: string;
  started: string;
  finished: string;
  curr: number;
  total: number;
  percentage: number;
}

export type SimResult = {
  outputType: string;
  output: object;
  isBatch: boolean;
  requestId: string;
  simEngine: string;
  simId: string;
  partId: string;
  pos: string;
  type: string;
  dataType: string;
  userId: string;
  setupId: string;
  setupName: string;
  exeTime: string;
  submitted: string;
  started: string;
  finished: string;
}

export type SimProgress = {
  isBatch: boolean;
  state: string;
  status: string;
  cancelId: string;
  groupId: string;
  requestId: string;
  userId: string;
  simEngine: string;
  setupId: string;
  applyOptimizationResult?: boolean;
  vehicle: string;
  type: string;
  displayName: string;
  series: string;
  submitted: string;
  started: string;
  finished: string;
  curr: number;
  total: number;
  percentage: number;
}

export type BatchStatus = {
  state: string;
  isBatch: boolean;
  cancelId: string;
  groupId: string;
  type: string;
  displayName: string;
  simEngine: string;
  lost: string;
  failed: string;
  completed: string;
  totalSims: number;
  submitted: string;
  started: string;
  finished: string;
}

interface SubscriptionContextType {
  simStatus: SimStatus[];
  simResult: SimResult[];
  simProgress: SimProgress[];
  batchStatus: BatchStatus[];
  clearSimStatusData: () => void;
  clearSweepStatusData: () => void;
  clearSimProgressData: () => void;
  getExistingProgress: () => void;
  getExistingStatus: () => void;
}

// Create a context to hold the subscription data
const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const [simStatus, setSimStatus] = useState<SimStatus[]>([]);
  const [simResult, setSimResult] = useState<SimResult[]>([]);
  const [simProgress, setSimProgress] = useState<SimProgress[]>([]);
  const [batchStatus, setBatchStatus] = useState<BatchStatus[]>([]);

  // Subscribe to the sim subscriptions
  const { data: simStatusSubData } = useGetSimStatusSubscription();
  const { data: simResultSubData } = useGetSimResultSubscription();
  const { data: simProgressSubData } = useGetSimProgressSubscription();
  const { data: batchStatusSubData } = useGetBatchStatusSubscription();

  const [clearSimProgress] = useClearSimProgressMutation();

  const [getSimProgress, { refetch: refetchProgress, called: progressCalled }] = useSimProgressesByUserLazyQuery();
  const [getSimStatus, { refetch: refetchStatus, called: statusCalled }] = useSimStatusesByUserLazyQuery();

  const clearSimStatusData = () => {
    setSimStatus([]);
  };

  const clearSweepStatusData = () => {
    setBatchStatus([]);
  };

  const timeout = (delay: number) => {
    return new Promise(res => { setTimeout(res, delay); });
  };

  const sortProgress = (a: SimProgress, b: SimProgress): number => {
    const time1 = new Date(a?.started).getTime();
    const time2 = new Date(b?.started).getTime();
    return time2 - time1;
  };

  const getExistingProgress = useCallback(async () => {
    // Often this is called to update the progress after some operations are done in redis
    // This timeout is to give those redis operations time to complete
    await timeout(500);
    const { data } = progressCalled ? await refetchProgress() : await getSimProgress();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 2);

    const existingProgress = data?.simProgressesByUser
      .map(p => p as SimProgress)
      .filter(p => new Date(p.started) > yesterday);

    // Get all running sims with only the most recent five completed/failed sims
    const runningProgress = existingProgress?.filter(p => p.status && (p.status === 'Running'));
    const lastFiveFinished = existingProgress?.filter(p => p.status && (p.status === 'Failed' || p.status === 'Finished')).sort((a, b) => sortProgress(a, b)).slice(0, 5);

    const sortedProgress = runningProgress?.concat(lastFiveFinished ?? [])?.sort((a, b) => sortProgress(a, b));

    setSimProgress(sortedProgress ?? []);
  }, [progressCalled]);

  const getExistingStatus = useCallback(async () => {
    const { data } = statusCalled ? await refetchStatus() : await getSimStatus();

    const existingStatus = data?.simStatusesByUser
      .map(p => p as SimStatus);

    setSimStatus(existingStatus ?? []);
  }, [statusCalled]);

  const clearSimProgressData = () => {
    clearSimProgress();
    getExistingProgress();
  };

  useEffect(() => {
    getExistingProgress();
    getExistingStatus();
  }, []);

  useEffect(() => {
    if (simStatusSubData && simStatusSubData.simStatus) {
      setSimStatus((prevStatus: SimStatus[]) => {
        const simStatus = simStatusSubData.simStatus as SimStatus;
        const index = indexOf(prevStatus, find(prevStatus, { groupId: simStatus.cancelId }));
        if (index >= 0) {
          prevStatus.splice(index, 1, simStatus);
          return [...prevStatus];
        }
        return [...prevStatus, simStatus];
      });
    }
  }, [simStatusSubData]);

  useEffect(() => {
    if (simResultSubData) {
      setSimResult((prevResult: SimResult[]) => [...prevResult, simResultSubData.simResult]);
    }
  }, [simResultSubData]);

  useEffect(() => {
    if (simProgressSubData && simProgressSubData.simProgress) {
      setSimProgress((prevProgress: SimProgress[]) => {
        const simProgress = simProgressSubData.simProgress as SimProgress;
        const index = indexOf(prevProgress, find(prevProgress, { groupId: simProgress.groupId }));
        if (index >= 0) {
          prevProgress.splice(index, 1, simProgress);
          return [...prevProgress];
        }
        return [simProgress, ...prevProgress];
      });
    }
  }, [simProgressSubData]);

  useEffect(() => {
    if (batchStatusSubData && batchStatusSubData.batchStatus) {
      setBatchStatus((prevStatus: BatchStatus[]) => {
        const batchStatus = batchStatusSubData.batchStatus as BatchStatus;
        const index = indexOf(prevStatus, find(prevStatus, { groupId: batchStatus.cancelId }));
        if (index >= 0) {
          prevStatus.splice(index, 1, batchStatus);
          return [...prevStatus];
        }
        return [...prevStatus, batchStatus];
      });
    }
  }, [batchStatusSubData]);

  const contextValue = useMemo(() => ({
    simStatus,
    simResult,
    simProgress,
    batchStatus,
    clearSimStatusData,
    clearSweepStatusData,
    clearSimProgressData,
    getExistingProgress,
    getExistingStatus,
  }), [simStatus, simResult, simProgress, batchStatus, clearSimStatusData, clearSweepStatusData, clearSimProgressData, getExistingProgress, getExistingStatus]);

  return (
    <SubscriptionContext.Provider value={contextValue}>
      {children}
    </SubscriptionContext.Provider>
  );
};

const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);
  if (!context) {
    throw new Error('useSubscriptionContext must be used within a SubscriptionProvider');
  }
  return context;
};

export { SubscriptionProvider, useSubscriptionContext };
