import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbProps, IBreadcrumbProps, Menu, MenuItem } from '@blueprintjs/core';
import { Breadcrumbs2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import { find } from 'lodash';

import { simDocumentCategories  } from '../../../constants';
import { SimDocumentType, SimDocumentCategory } from '../../../types';
import styles from './index.module.css';

enum Mode {
  CATEGORY,
  CONFIG,
}

interface Props {
  className?: string;
}

export default (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState(Mode.CATEGORY);
  const [selectedCategory, setSelectedCategory] = useState<SimDocumentCategory | null>(null);
  const [selectedConfig, setSelectedConfig] = useState<SimDocumentType | null>(null);
  const [simDocumentTypes, setSimDocumentTypes] = useState<SimDocumentType[]>([]);

  useEffect(() => {
    if (params.categoryName && params.configName) {
      setMode(Mode.CONFIG);
    } else {
      setMode(Mode.CATEGORY);
    }
  }, [params]);

  useEffect(() => {
    if (params.categoryName) {
      const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
      if (category) {
        setSelectedCategory(category as SimDocumentCategory);
        setSimDocumentTypes(category.types as SimDocumentType[]);
      }
    }
  }, [params.categoryName]);

  useEffect(() => {
    if (params.categoryName && params.configName) {
      const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
      if (category) {
        const type = find(category.types, (o) => (o.name === params.configName));
        if (type) setSelectedConfig(type as SimDocumentType);
      }
    }
  }, [params.configName]);

  const renderCategoryHeader = () => (<span>Choose a category</span>);

  const breadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={breadcrumbProps.className}
      disabled={breadcrumbProps.disabled}
      onClick={breadcrumbProps.onClick}
      text={breadcrumbProps.text}
    />
  );
  const currentBreadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={breadcrumbProps.className}
      text={breadcrumbProps.text}
      current
    />
  );

  const renderConfigHeader = () => {
    const entries: IBreadcrumbProps[] = [{
      text: 'All',
      onClick: () => navigate('/sims/documents'),
    }];
    if (selectedCategory && selectedConfig) {
      entries.push({
        className: styles.previousBreadcrumb,
        text: selectedCategory.displayName,
      }, {
        className: styles.currentBreadcrumb,
        text: selectedConfig.displayName,
        disabled: true,
      });
    }

    return (
      <Breadcrumbs2
        breadcrumbRenderer={breadcrumbRenderer}
        className={styles.breadcrumbsContainer}
        currentBreadcrumbRenderer={currentBreadcrumbRenderer}
        items={entries}
        minVisibleItems={1}
      />
    );
  };

  const renderCategoryItems = () => {
    return simDocumentCategories
      .map((category: SimDocumentCategory) => {
        const categoryName = encodeURIComponent(category.name);
        const typeName = encodeURIComponent(category.types[0].name);
        return (
          <MenuItem
            className={styles.menuItem}
            key={`category-menu-${category.name}`}
            onClick={() => navigate(`/sims/documents/category/${categoryName}/${typeName}`)}
            tagName="div"
            text={category.displayName}
          />
        );
      });
  };

  const renderConfigItems = () => {
    if (!simDocumentTypes || !selectedCategory) return null;
    return simDocumentTypes.map(type => {
      const categoryName = encodeURIComponent(selectedCategory.name);
      const typeName = encodeURIComponent(type.name);
      return (
        <MenuItem
          className={styles.menuItem}
          key={`config-menu-${typeName}`}
          onClick={() => navigate(`/sims/documents/category/${categoryName}/${typeName}`)}
          tagName="div"
          text={type.displayName}
        />
      );
    });
  };

  const containerClasses = classNames(styles.navContainer, props.className);
  return (
    <div className={containerClasses}>
      <div className={styles.menuHeader}>
        {mode === Mode.CATEGORY ? renderCategoryHeader() : renderConfigHeader()}
      </div>
      <Menu className={styles.menu}>
        {mode === Mode.CATEGORY ? renderCategoryItems() : renderConfigItems()}
      </Menu>
    </div>
  );
};
