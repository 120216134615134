import _ from 'lodash'; // Corrected to use ES6 import syntax

export const csvJSON = (csv: string) => {
  const linesStr = csv.replace('\r\n', '\n').split('\n');
  _.pull(linesStr, ''); // disregard empty lines.

  const linesArr = [];
  for (let i = 0; i < linesStr.length; i++) {
    const lineArr = linesStr[i].split(',');
    if (lineArr && lineArr.length === 2 && (lineArr[0].length > 0 && lineArr[1].length > 0)) {
      linesArr.push([parseFloat(lineArr[0]), parseFloat(lineArr[1])]);
    }
  }
  return linesArr;
};

export const readCSVFile = (file: File): Promise<{ procData: number[][], objectType: string }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      if (!arrayBuffer) {
        reject(new Error('Failed to read file as ArrayBuffer'));
        return;
      }
      const fileContent = new TextDecoder('utf-8').decode(arrayBuffer);
      const csvContent = csvJSON(fileContent); // Assuming csvJSON is defined elsewhere
      resolve({ procData: csvContent, objectType: 'CSV' });
    };
    reader.onerror = (e) => reject(e);
    reader.readAsArrayBuffer(file);
  });
};

export default readCSVFile;
