import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';

import Accordion from 'components/Accordion';
import styles from './index.module.css';

interface MutationDocsProps {
  mutationName: string;
  description: string;
  returnType: string;
  args: Array<{ name: string; type: string; description: string }>;
  examples: { type: string; mutation: string; variables: string, additionalInfo?: string }[];
}

const MutationDocs: React.FC<MutationDocsProps> = ({ mutationName, description, returnType, args, examples }) => {
  return (
    <div>
      <h3>Mutation: {mutationName}</h3>
      <p>Description: {description}</p>
      <p>Return Type: {returnType}</p>
      <h4>Arguments:</h4>
      <ul>
        {args.map(arg => (
          <li key={arg.name}>
            <strong>{arg.name}</strong> ({arg.type}): {arg.description}
          </li>
        ))}
      </ul>
      <h4>Examples:</h4>
      {examples.map((example, index) => (
        <Accordion
          className={styles.itemContainer}
          id={`section-${example.type}`}
          initialOpen={false}
          key={example.type}
          title={example.type}
          buttonProps={{
            className: styles.accordionHeader,
          }}
        >
          <div key={index} className={styles.sectionContainer}>
            <p><strong>Mutation:</strong></p>
            <SyntaxHighlighter language="graphql" style={tomorrow} className={styles.codeBlock}>
              {example.mutation}
            </SyntaxHighlighter>
            <p><strong>Variables:</strong></p>
            {example.additionalInfo && (
              <p>{example.additionalInfo}</p>
            )}
            <SyntaxHighlighter language="json" style={tomorrow} className={styles.codeBlock}>
              {example.variables}
            </SyntaxHighlighter>
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default MutationDocs;
