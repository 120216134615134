import { To, useNavigate } from 'react-router-dom';
import { Button, ButtonProps } from '@blueprintjs/core';

interface Props {
  buttonProps?: ButtonProps;
  to: To;
  query?: { [k: string]: string };
}

export default (props: Props) => {
  const navigate = useNavigate();

  return (
    <Button
      {...props.buttonProps}
      onClick={() => navigate(props.to)}
    />
  );
};
