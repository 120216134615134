import { CSSProperties, ReactNode, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Alignment, Card, InputGroup, Button, Intent } from '@blueprintjs/core';
import classNames from 'classnames';
import { isNil, snakeCase, get, isEmpty } from 'lodash';

import {
  SelectItem,
  RunUITemplate,
  RunUITemplateFieldItem,
  RunUITemplateItem,
  RunUITemplateItemType,
  RunUITemplateColumnItem,
} from 'types';
import {
  RHFCheckbox,
  RHFSelect,
  RHFTextArea,
  RHFTextInput,
} from 'components/RHFInputs';
import IconTooltip from 'components/IconTooltip';
import {
  RunField,
  RunFieldPosition,
  RunFieldType,
  Run,
  RunLapTime,
} from 'graphql/generated/graphql';

import styles from './index.module.css';

interface SetupViewProps {
  runFields: RunField[];
  runIndex: number;
  template: RunUITemplate;
  baseline?: Run;
  readOnly?: boolean;
}

const getColItems = (item: RunUITemplate | RunUITemplateColumnItem): RunUITemplateColumnItem[] => {
  return item.items.filter(i => i.type === RunUITemplateItemType.COLUMN) as RunUITemplateColumnItem[];
};

const getColumnGridStyles = (item: RunUITemplate | RunUITemplateColumnItem): CSSProperties => {
  const colItems = getColItems(item);
  return {
    gridTemplateColumns: colItems.map(i => `${i.col_span ?? 1}fr`).join(' '),
  };
};

export default (props: SetupViewProps) => {
  const { runFields, runIndex, template, baseline, readOnly } = props;
  const { control, getValues, setValue } = useFormContext();

  const getFieldKey = (fieldName: string, position?: RunFieldPosition) => {
    const prefix = `runs.${runIndex}.data`;
    if (!position) return `${prefix}.${fieldName}`;
    return `${prefix}.${fieldName}_${snakeCase(position.label)}`;
  };

  const getBaselineValue = (fieldName: string, position?: RunFieldPosition) => {
    let baselineValue = '';
    if (!position) {
      baselineValue = get(baseline, `data.${fieldName}`, '');
    } else {
      baselineValue = get(baseline, `data.${fieldName}_${snakeCase(position.label)}`, '');
    }
    return baselineValue;
  };

  const renderRunField = (field: RunField, position?: RunFieldPosition): ReactNode => {
    const key = getFieldKey(field.name, position);

    const baselineValue: unknown = (baseline && runIndex !== 0) ? getBaselineValue(field.name, position) : undefined;
    const highlightClass = baselineValue !== undefined && baselineValue !== getValues(key) ? styles.highlight : undefined;

    switch (field.type) {
      case RunFieldType.STRING: {
        if (field.options && field.options.length > 0) {
          const options: SelectItem<string>[] = field.options.map(o => {
            return { label: o, value: o };
          });
          return (
            <RHFSelect
              key={key}
              controllerProps={{
                control,
                name: key,
              }}
              fill
              items={options}
              noSelectionText={position && `${`${position.label} `}Option`}
              disabled={readOnly}
              buttonProps={{
                className: highlightClass,
              }}
            />
          );
        }

        return (
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.TEXT: {
        return (
          <RHFTextArea
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            textAreaProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.INT: {
        return (
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.FLOAT: {
        return (
          // Use an InputGroup (Text Input) rather than Numeric Input to support
          // expressions
          <RHFTextInput
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            inputProps={{
              fill: true,
              placeholder: position?.label,
              small: true,
              readOnly,
              className: highlightClass,
            }}
          />
        );
      }
      case RunFieldType.BOOLEAN: {
        const cbHighlightClass = baselineValue !== undefined && !!baselineValue !== !!getValues(key) ? styles.highlight : undefined;
        return (
          <RHFCheckbox
            key={key}
            controllerProps={{
              control,
              name: key,
            }}
            checkboxProps={{
              alignIndicator: Alignment.RIGHT,
              label: position && position.label,
              className: classNames(styles.booleanInput, cbHighlightClass),
              disabled: readOnly,
            }}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderLapTimeTable = () => {
    const runLapTimes = getValues(`runs.${runIndex}.lap_times`);

    const [laps, setLaps] = useState(() => {
      if (runLapTimes && runLapTimes.length > 0) {
        return runLapTimes.map((lap: RunLapTime) => ({
          lap: lap.lap,
          time: lap.time || '',
          lapTime: lap.lapTime || '',
        }));
      } return [{ lap: 1, time: '', lapTime: '' }];
    });

    const getFastestLapIndex = () => {
      let fastestIndex = -1;
      let fastestTime = Infinity;

      laps.forEach((lap: RunLapTime, i: number) => {
        const lapTime = parseFloat(lap.lapTime || '');
        if (!Number.isNaN(lapTime) && (lapTime < fastestTime)) {
          fastestTime = lapTime;
          fastestIndex = i;
        }
      });

      return fastestIndex;
    };

    const handleInputChange = (index: number, field: string, value: string) => {
      setLaps((prevLaps: RunLapTime[]) => {
        const newLaps = [...prevLaps];
        const newLapData = {
          ...newLaps[index],
          [field]: value,
        };
        newLaps[index] = newLapData;

        // Automatically add a new lap if this is the last row
        if (index === laps.length - 1 && (field === 'time' || field === 'lapTime') && value) {
          newLaps.push({ lap: newLaps.length + 1, time: '', lapTime: '' });
        }

        setValue(`runs.${runIndex}.lap_times`, newLaps, { shouldDirty: true });
        return newLaps;
      });
    };

    const handleDeleteLap = (index: number) => {
      setLaps((prevLaps: RunLapTime[]) => {
        const newLaps = prevLaps.filter((_, i) => i !== index);
        const orderedNewLaps = newLaps.map((lap, i) => ({ ...lap, lap: i + 1 }));

        setValue(`runs.${runIndex}.lap_times`, orderedNewLaps, { shouldDirty: true });
        return orderedNewLaps;
      });
    };

    const fastestLapIndex = getFastestLapIndex();

    return (
      <div key="lap-time-table" className={styles.inputRow}>
        <div className={styles.lapTimeTableRow}>
          <div className={styles.lapTimeTableLapCell}>Lap</div>
          <div className={styles.lapTimeTableHeader}>Time</div>
          <div className={styles.lapTimeTableHeader}>Lap Time</div>
          <div className={styles.lapTimeTableEmptyHeader} />
        </div>
        {laps.map((lap: RunLapTime, index: number) => (
          <div key={index} className={styles.lapTimeTableRow}>
            <div className={styles.lapTimeTableLapCell}>{lap.lap}</div>
            <InputGroup
              value={lap.time || ''}
              onChange={(e) => handleInputChange(index, 'time', e.target.value)}
              fill
            />
            <InputGroup
              value={lap.lapTime || ''}
              onChange={(e) => handleInputChange(index, 'lapTime', e.target.value)}
              fill
              className={index === fastestLapIndex ? styles.fastestLap : undefined}
            />
            <Button
              icon="cross"
              intent={Intent.DANGER}
              minimal
              onClick={() => handleDeleteLap(index)}
              small
              disabled={(index === 0)}
            />
          </div>
        ))}
      </div>
    );
  };

  const renderTemplateFieldItem = (
    item: RunUITemplateFieldItem,
    runFields: RunField[],
  ) => {
    if (item.run_field === 'lap_time_table') {
      // handle case for Lap Time Table
      return renderLapTimeTable();
    }

    const field = runFields.find(f => f.name === item.run_field);
    if (!field) return null;

    let inputs: ReactNode[];
    let inputClass;
    let inputStyles: CSSProperties = {};
    // If this field has `positions`, generates one field for each, passing the
    // position to the field render function and modifies the label for relevant
    // display
    if (field.positions && field.positions.length > 0) {
      inputs = field.positions.map(pos => {
        const expandedField = {
          ...field,
          path: getFieldKey(field.name, pos),
        };
        if (field.label) expandedField.label += ` ${pos}`;
        return renderRunField(expandedField, pos);
      });
      inputClass = classNames({
        [styles.grid]: true,
        [styles[`positions${field.positions.length}`]]: isNil(item.num_columns),
      });

      if (!isNil(item.num_columns)) {
        inputStyles = {
          gridTemplateColumns: `repeat(${item.num_columns}, 1fr)`,
        };
      }
    } else {
      inputs = [renderRunField(field, undefined)];
    }

    return (
      <div key={field.name} className={styles.inputRow}>
        <div>
          <span className="bp4-ui-text">{item.label ?? field.label}</span>
          {field.tooltip && (
            <IconTooltip
              className={styles.tooltipIcon}
              content={field.tooltip}
            />
          )}
        </div>
        <div className={classNames(styles.inputs, inputClass)} style={inputStyles}>{inputs}</div>
      </div>
    );
  };

  const renderTemplateItem = (
    path: RunUITemplateItem[],
    runFields: RunField[],
  ): ReactNode => {
    const item = path[path.length - 1];
    if (item.type === RunUITemplateItemType.COLUMN) {
      return (
        <div
          className={styles.sectionContainer}
          key={JSON.stringify(path)} // TODO: Fix this junk?
          style={getColumnGridStyles(item)}
        >
          {item.items.map(innerItem => renderTemplateItem([...path, innerItem], runFields))}
        </div>
      );
    }
    return renderTemplateFieldItem(item, runFields);
  };

  return (
    <Card>
      {!isEmpty(control._defaultValues.runs)
        ? (
          <div className={styles.runHeaderRow}>
            <div className={styles.runNameInput}>
              <span>Run Name</span>
              <RHFTextInput
                key={runIndex}
                controllerProps={{
                  control,
                  name: `runs.${runIndex}.branch.name`,
                }}
                inputProps={{
                  fill: true,
                  placeholder: 'Run Name',
                  small: true,
                  readOnly,
                }}
              />
            </div>
            <div className={styles.runSessionInput}>
              <span>Session</span>
              <RHFTextInput
                key={runIndex}
                controllerProps={{
                  control,
                  name: `runs.${runIndex}.session`,
                }}
                inputProps={{
                  fill: true,
                  placeholder: 'Session',
                  small: true,
                  readOnly: true,
                }}
              />
            </div>
          </div>
        ) : undefined}
      <div
        className={styles.innerContainer}
        style={getColumnGridStyles(template)}
      >
        {template?.items.map(item => renderTemplateItem([item], runFields))}
      </div>
    </Card>
  );
};
