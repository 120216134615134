import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormGroup, H4, NonIdealState } from '@blueprintjs/core';
import classNames from 'classnames';
import { maxBy } from 'lodash';

import IconTooltip from 'components/IconTooltip';
import Select from 'components/Select';
import { PartConfigPropertyInput, Property } from 'graphql/generated/graphql';
import { SelectItem } from 'types';
import PropertiesTableRow from './PropertiesTableRow';

import styles from './PropertiesTable.module.css';

type PartConfigPropertyFormInput = (PartConfigPropertyInput & { property: Property });

interface Props {
  className?: string;
  properties: Property[];
}

export default (props: Props) => {
  const { control, getValues, setValue, watch } = useFormContext();
  const { fields, append, move, swap, update } = useFieldArray({
    control,
    name: 'properties',
  });

  const configProperties = watch('properties') as PartConfigPropertyFormInput[];
  const activeProperties = configProperties.filter(p => !p.destroy);
  const configPropertyIds = activeProperties.map(p => p.property.id);
  const unassignedProperties = props.properties.filter(p => !configPropertyIds.includes(p.id));
  const unassignedSelectItems = unassignedProperties.map(p => ({
    label: p.display_name,
    value: p,
  })) as SelectItem<Property>[];

  const reorderProperties = () => {
    const properties = getValues('properties') as PartConfigPropertyFormInput[];
    setValue('properties', properties.map((p, i) => ({
      ...p,
      order: i + 1,
    })));
  };

  const onPropertySelected = ({ value: selectedProperty }: typeof unassignedSelectItems[number]) => {
    const nextOrdinal = maxBy(configProperties, p => p.order)?.order ?? 0;

    // If this property was removed *and then added back without reloading*, reuse the
    // removed property; this will preserve the ID and any associations on the server
    const removedIndex = configProperties.findIndex(p => p.property.id === selectedProperty.id);
    if (removedIndex > -1) {
      update(removedIndex, {
        ...configProperties[removedIndex],
        destroy: false,
        order: nextOrdinal + 1,
        description: null,
        min: null,
        max: null,
        values: [],
        required: false,
      });
      move(removedIndex, configProperties.length - 1);
    } else {
      append({
        property_id: selectedProperty.id,
        order: nextOrdinal + 1,
        property: selectedProperty,
        min: null,
        max: null,
        values: [],
        required: false,
      });
    }
    reorderProperties();
  };

  const onRowDragged = (dragIndex: number, hoverIndex: number) => {
    swap(dragIndex, hoverIndex);
    setValue(`properties.${dragIndex}.order`, dragIndex + 1);
    setValue(`properties.${hoverIndex}.order`, hoverIndex + 1);
  };

  const onRowRemoved = (index: number) => {
    setValue(`properties.${index}.destroy`, true);
    move(index, configProperties.length - 1);
  };

  return (
    <div className={props.className}>
      <H4 className={styles.heading}>Config Properties & <span className={styles.override}>Overrides</span></H4>
      <FormGroup label="Add Property" inline>
        <Select
          ignoreSelection
          items={unassignedSelectItems}
          onChange={onPropertySelected}
        />
      </FormGroup>
      {activeProperties.length === 0 && (
        <NonIdealState
          className={styles.noResults}
          description="No properties have been added to this part config (yet!)"
          icon="clean"
          title="No properties"
        />
      )}
      {activeProperties.length > 0 && (
        <table className={classNames(styles.table, 'bp4-html-table')}>
          <thead>
            <tr>
              <th />{/* eslint-disable-line */}
              <th>Name</th>
              <th>Type</th>
              <th>
                <span className={styles.columnTitle}>Specs</span>
                <IconTooltip content="Leave empty for all specs" />
              </th>
              <th className={styles.override}>Description</th>
              <th className={styles.override}>Path</th>
              <th className={styles.override}>Min</th>
              <th className={styles.override}>Max</th>
              <th className={styles.override}>Values</th>
              <th>Required</th>
              <th />{/* eslint-disable-line */}
            </tr>
          </thead>
          <tbody>
            {fields.map((field, index) => {
              if (getValues(`properties.${index}.destroy`)) return null;

              return (
                <PropertiesTableRow
                  field={field}
                  index={index}
                  key={field.id}
                  onRowDragged={onRowDragged}
                  onRowRemoved={onRowRemoved}
                />
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
