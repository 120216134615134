import { GQLSetup, GQLSimWorksheet } from 'types';
import { SetupBranchInput, CreateSetupInput, SetupBranch, SimWorksheetInput } from 'graphql/generated/graphql';

export const SetupToSetupInput = (setup: GQLSetup) => {
  const translated: Record<string, unknown> = {
    ...setup,
    parent_id: setup.parent?.id ?? null,
    root_id: setup.root?.id,
  };

  delete translated.parent;
  delete translated.root;
  delete translated.id;
  delete translated.parts;
  return translated as CreateSetupInput;
};

export const SetupBranchToSetupBranchInput = (branch: SetupBranch) => {
  const translated: Record<string, unknown> = {
    ...branch,
    root_id: branch.root.id,
    head_id: branch.head.id,
  };

  delete translated.id;
  delete translated.root;
  delete translated.setup;

  return translated as SetupBranchInput;
};

export const SimWorksheetToSimWorksheetInput = (simWorksheet: GQLSimWorksheet) => {
  const translated: Record<string, unknown> = {
    ...simWorksheet,
    environment_id: simWorksheet.environment?.id,
  };

  delete translated.id;
  delete translated.created_at;
  delete translated.updated_at;
  delete translated.environment;
  delete translated.sim_outlines;

  return translated as SimWorksheetInput;
};
