import Ajv, { JSONSchemaType } from 'ajv';
import _ from 'lodash';

import { RunUITemplate, RunUITemplateItem, RunUITemplateItemType, ValidRunField } from 'types';

const ajv = new Ajv({ allErrors: true });

/* eslint-disable @typescript-eslint/no-explicit-any */
const schema: JSONSchemaType<RunUITemplate> = {
  $defs: {
    template_item_array: {
      type: 'array',
      minItems: 1,
      items: {
        anyOf: [
          { $ref: '#/$defs/template_column_item' } as any,
          { $ref: '#/$defs/template_field_item' } as any,
        ],
      },
    },
    template_column_item: {
      type: 'object',
      properties: {
        type: { type: 'string', pattern: 'column' },
        col_span: { type: 'number' },
        items: { $ref: '#/$defs/template_item_array' },
      },
      required: ['type', 'items'],
      additionalProperties: false,
    },
    template_field_item: {
      type: 'object',
      properties: {
        type: { type: 'string', pattern: 'field' },
        label: { type: 'string' },
        run_field: { type: 'string' },
        num_columns: { type: 'integer' },
      },
      required: ['type', 'run_field'],
      additionalProperties: false,
    },
  },
  type: 'object',
  properties: {
    items: { $ref: '#/$defs/template_item_array' } as any,
  },
  required: ['items'],
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const schemaValidate = ajv.compile(schema);

const getDuplicates = (arr: string[]) => {
  return _.filter(arr, (val, i, iteratee) => _.includes(iteratee, val, i + 1));
};

export const customValidate = (template: RunUITemplate, runFields: ValidRunField[]): Error[] | undefined => {
  const runFieldNames = runFields.map(f => f.name);
  const templateRunFields: string[] = [];
  const errors = [];

  const processTemplateItem = (item: RunUITemplateItem, depth: number) => {
    if (item.type === RunUITemplateItemType.COLUMN) {
      if (depth > 3) {
        errors.push(new Error('Maximum container depth exceeded'));
      }
      item.items.forEach(i => processTemplateItem(i, depth + 1));
    } else {
      if (!runFieldNames.includes(item.run_field)) {
        errors.push(new Error(`Run field '${item.run_field}' not a valid run field`));
      }
      templateRunFields.push(item.run_field);
    }
  };

  template.items.forEach(i => processTemplateItem(i, 1));

  errors.push(...getDuplicates(templateRunFields).map(d => {
    return new Error(`Duplicate run field '${d}' used`);
  }));

  return errors.length > 0 ? errors : undefined;
};
