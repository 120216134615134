import { useRef } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Checkbox, CheckboxProps } from '@blueprintjs/core';

interface Props {
  checkboxProps?: CheckboxProps;
  controllerProps: UseControllerProps;
}

export default (props: Props) => {
  const ref = useRef(null);

  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => (
        <Checkbox
          checked={field.value}
          onBlur={field.onBlur}
          onChange={field.onChange}
          name={field.name}
          {...props.checkboxProps}
          ref={ref}
        />
      )}
    />
  );
};
