import { ReactNode } from 'react';
import {
  Button,
  Intent,
  H6,
  Card,
  InputGroup,
  ControlGroup,
  Label,
  TextArea,
  Checkbox,
} from '@blueprintjs/core';
import { map, get, snakeCase } from 'lodash';
import classNames from 'classnames';

import {
  Run,
  RUIT,
  RunField,
  RunFieldType,
} from 'graphql/generated/graphql';
import {
  RunUITemplateFieldItem,
  RunUITemplateItem,
  RunUITemplateItemType,
} from 'types';
import styles from './index.module.css';

interface Props {
  run: Run;
  baseline?: Run;
  index: number,
  activeRUIT?: RUIT;
  runFields: RunField[];
  onPromoteRunToBaseline: (index: number) => void;
  onRemoveRun: (index: number) => void;
}

export default (props: Props) => {
  const {
    run,
    baseline,
    index,
    activeRUIT,
    runFields,
    onPromoteRunToBaseline,
    onRemoveRun,
  } = props;

  const renderRunLogField = (runFieldPath: string, runField: RunField, placeHolder?: string) => {
    const val = get(run, `${runFieldPath}`, '');

    let highlightClass: string | undefined;

    if (index !== 0) {
      const baselineValue = get(baseline, `${runFieldPath}`, '');
      if (val !== baselineValue) {
        highlightClass = styles.highlight;
      }
    }

    switch (runField.type) {
      case RunFieldType.STRING:
      case RunFieldType.INT:
      case RunFieldType.FLOAT: {
        return (
          <InputGroup
            value={val}
            readOnly
            fill
            small
            placeholder={placeHolder}
            className={highlightClass}
          />
        );
      }
      case RunFieldType.TEXT: {
        return (
          <TextArea
            value={val}
            readOnly
            fill
            small
            placeholder={placeHolder}
            className={highlightClass}
          />
        );
      }
      case RunFieldType.BOOLEAN: {
        return (
          <Checkbox
            checked={!!val}
            readOnly
            className={classNames(styles.fieldCheckbox, highlightClass)}
          />
        );
      }
      default:
        return null;
    }
  };

  const renderRunLogPositionFields = (item: RunUITemplateFieldItem, runField: RunField) => {
    return (
      <>
        {map(runField.positions, (position) => {
          const runFieldPath = `data.${runField.name}_${snakeCase(position.label)}`;
          return renderRunLogField(runFieldPath, runField, position.label);
        })}
      </>
    );
  };

  const renderRunLogTemplateField = (item: RunUITemplateFieldItem): ReactNode => {
    const field = runFields.find(f => f.name === item.run_field);
    if (!field) return null;

    if (field.positions && field.positions.length > 0) {
      return (
        <ControlGroup className={styles.fieldInputGroup}>
          {(index === 0) && (
            <Label
              className={styles.fieldLabel}
              title={field.label}
            >
              {field.label}
            </Label>
          )}
          <div className={classNames(styles.positionFieldsContainer, styles[`positions${field.positions.length}`])}>
            {renderRunLogPositionFields(item, field)}
          </div>
        </ControlGroup>
      );
    }

    const runFieldPath = `data.${item.run_field}`;

    return (
      <ControlGroup className={styles.fieldInputGroup}>
        {(index === 0) && (
          <Label
            className={styles.fieldLabel}
            title={field.label}
          >
            {field.label}
          </Label>
        )}
        {renderRunLogField(runFieldPath, field)}
      </ControlGroup>
    );
  };

  const renderRunLogTemplateItem = (item: RunUITemplateItem): ReactNode => {
    if (item.type === RunUITemplateItemType.COLUMN) {
      return (<>{item.items.map(innerItem => renderRunLogTemplateItem(innerItem))}</>);
    }
    return renderRunLogTemplateField(item);
  };

  const cardClasses = index === 0 ? classNames(styles.runCard, styles.baseline) : styles.runCard;

  return (
    <div className={styles.cardContainer}>
      <div className={styles.runCardHeaderContainer}>
        <H6 className={styles.runCardHeader}>
          {`${get(run, 'branch.head.name', '')} - ${get(run, 'branch.name', '')}`}
        </H6>
        <div>
          {(index !== 0) && (
            <Button
              icon="circle-arrow-left"
              minimal
              onClick={() => onPromoteRunToBaseline(index)}
            />
          )}
          <Button
            icon="cross"
            intent={Intent.DANGER}
            minimal
            onClick={() => onRemoveRun(index)}
          />
        </div>
      </div>
      <Card className={cardClasses}>
        {map(activeRUIT?.template.items, (item: RunUITemplateItem) => {
          return renderRunLogTemplateItem(item);
        })}
      </Card>
    </div>
  );
};
