import { Navigate, useParams } from 'react-router-dom';
import { find, first } from 'lodash';

import { simDocumentCategories  } from '../../../constants';

export default () => {
  const params = useParams();
  const category = find(simDocumentCategories, (o) => (o.name === params.categoryName));
  const firstDocumentType = first(category?.types);
  if (!firstDocumentType) return <Navigate to="/sims/documents" />;
  return <Navigate to={firstDocumentType.name} replace />;
};
