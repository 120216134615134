import { Button, Icon, Intent, Tag } from '@blueprintjs/core';
import { useFormContext } from 'react-hook-form';
import { Identifier } from 'dnd-core';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';

import { RHFCheckbox, RHFMultiSelect, RHFNumericInput, RHFTagInput, RHFTextInput } from 'components/RHFInputs';
import { PartConfigProperty, PropertyType } from 'graphql/generated/graphql';

import Select from 'components/Select';
import styles from './PropertiesTable.module.css';
import { PROPERTY_TYPES, specSelectItems } from '../../constants';
import { useRef } from 'react';
import IconTooltip from 'components/IconTooltip';

interface DragItem {
  id: string;
  index: number;
  type: string;
}

interface Props {
  field: Record<'id', string>;
  index: number;
  onRowDragged: (dragIndex: number, hoverIndex: number) => void;
  onRowRemoved: (rowIndex: number) => void;
}

export default (props: Props) => {
  const { control, getValues } = useFormContext();
  const configProperty = getValues(`properties.${props.index}`) as PartConfigProperty;
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'property',
    collect: monitor => {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover: (item, monitor) => {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = props.index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      // Time to actually perform the action
      props.onRowDragged(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'property',
    item: () => {
      return {
        id: props.field.id,
        index: props.index,
      };
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const ref = useRef<HTMLTableRowElement>(null);
  drag(drop(ref));

  return (
    <tr
      className={classNames({ [styles.dragging]: isDragging })}
      data-handler-id={handlerId}
      key={props.field.id}
      ref={ref}
    >
      <td className={styles.drag} aria-label="drag">
        <Icon className={styles.dragHandle} icon="drag-handle-vertical" />
      </td>
      <td className={styles.name}>
        <span className={classNames(styles.nameText, 'bp4-ui-text')}>{configProperty.property.name}</span>
        <IconTooltip
          content={(
            <div className={styles.defaultsPopover}>
              <p><b>Default Property Values</b></p>
              <ul className={styles.defaultsList} key="defaultsList">
                <li key="Description">Description: {configProperty.property.description ? <code className="bp4-code">{configProperty.property.description}</code> : <i>Not set</i>}</li>
                <li key="Path">Path: {configProperty.property.path ? <code className="bp4-code">{configProperty.property.path}</code> : <i>Not set</i>}</li>
                <li key="Min">Min: {configProperty.property.min ? <code className="bp4-code">{configProperty.property.min}</code> : <i>Not set</i>}</li>
                <li key="Max">Max: {configProperty.property.max ? <code className="bp4-code">{configProperty.property.max}</code> : <i>Not set</i>}</li>
                <li key="Values">Values: {configProperty.property.values
                  ? configProperty.property.values.map((v, i) => (
                    <Tag
                      className={styles.defaultValue}
                      key={`${configProperty.property.id}-${i}`}
                    >
                      {v}
                    </Tag>
                  ))
                  : <i>Not set</i>}
                </li>
              </ul>
            </div>
          )}
          icon="info-sign"
        />
      </td>
      <td className={styles.type} aria-label="type">
        <Select
          disabled
          fill
          items={[]}
          initialItem={{
            label: PROPERTY_TYPES[configProperty.property.type],
            value: undefined,
          }}
        />
      </td>
      <td className={styles.specs} aria-label="specs">
        <RHFMultiSelect
          controllerProps={{
            control,
            name: `properties.${props.index}.specs`,
          }}
          items={specSelectItems}
          selectProps={{ fill: true }}
        />
      </td>
      <td className={styles.description} aria-label="description">
        <RHFTextInput
          controllerProps={{
            control,
            name: `properties.${props.index}.description`,
          }}
        />
      </td>
      <td className={styles.path} aria-label="path">
        <RHFTextInput
          controllerProps={{
            control,
            name: `properties.${props.index}.path`,
          }}
          inputProps={{
            placeholder: configProperty.property.path ?? configProperty.property.name,
          }}
        />
      </td>
      <td className={styles.min} aria-label="min">
        <RHFNumericInput
          controllerProps={{
            control,
            name: `properties.${props.index}.min`,
          }}
          inputProps={{
            disabled: configProperty.property.type === PropertyType.STRING,
            inputClassName: styles.minInput,
          }}
        />
      </td>
      <td className={styles.max} aria-label="max">
        <RHFNumericInput
          controllerProps={{
            control,
            name: `properties.${props.index}.max`,
          }}
          inputProps={{
            disabled: configProperty.property.type === PropertyType.STRING,
            inputClassName: styles.maxInput,
          }}
        />
      </td>
      <td className={styles.values} aria-label="values">
        <RHFTagInput
          controllerProps={{
            control,
            name: `properties.${props.index}.values`,
          }}
          inputProps={{
            disabled: configProperty.property.type === PropertyType.NUMBER,
          }}
        />
      </td>
      <td className={styles.required} aria-label="required">
        <RHFCheckbox
          checkboxProps={{
            className: styles.requiredCheckbox,
            disabled: configProperty.property.type === PropertyType.BOOLEAN,
          }}
          controllerProps={{
            control,
            name: `properties.${props.index}.required`,
          }}
        />
      </td>
      <td className={styles.remove} aria-label="remove">
        <Button
          icon="cross"
          intent={Intent.DANGER}
          minimal
          onClick={() => props.onRowRemoved(props.index)}
        />
      </td>
    </tr>
  );
};
