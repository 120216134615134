import React, { FormEvent, useState, useEffect } from 'react';
import { InputGroup, Button, Intent } from '@blueprintjs/core';
import Chart from '../Chart';
import FilePicker from 'components/Common/FilePicker';
import PartSelector from 'components/SUITForm/PartSelector';
import SavePartModal from 'components/SavePartModal';
import InputCheckbox from 'components/InputCheckbox';
import styles from './index.module.css';
import { Part, Scalars, Spec, useImportPartFileMutation, useRequestPartFitMutation, ImportPartResult, useGetSimResultSubscription } from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import _ from 'lodash';
import { readCSVFile } from '../../../helpers/PartImport/Parts';

export interface ChartDataItem {
  name: string;
  id: number,
  color: string,
  x: number;
  y: number;
  rate?: number;
}

export interface PartInput {
  partId: number,
  setupId: number,
  pos: string,
  partType: string,
  partFormat: string,
  description: string,
  spec: Spec,
  data: Scalars['JSONObject'],
}

interface SpringChartProps {
  position: string;
  chartData: ChartDataItem[];
  setupId: number,
  selectedPart?: Part;
  parts: Part[];
  onChangeHandler?: (path: string, value: number, pos: string, newPart?: Part | undefined) => void;
  onUpdatePlotData?: (updatePart: PartInput) => void;
  path: string;
  partType: string;
  partConfigId?: number,
  spec: Spec,
  readOnly: boolean;
}

interface DataPoint {
  displayName?: string,
  name: string;
  id: number,
  color: string,
  x: number;
  y: number;
}

interface ProcSpringObj {
  objectType: string;
  procData: number[][];
  runConstants?: Array<{ fieldName: string; value: number }>;
}

interface FormattedCSVResult {
  data: number[][];
  success: boolean;
  type: string;
  freelength?: number;
}

interface CoeffDataType {
  engage_rubber: number,
  initial_disp: number,
  initial_force: number,
  initial_smooth: number,
  prog_base: number,
  prog_rubber: number,
  rate_base: number,
  rate_rubber: number,
  scale_factor: number,
  smooth_factor: number,
}

export default (props: SpringChartProps) => {
  const [isSavePartDialogOpen, setIsSavePartDialogOpen] = useState(false);

  // Baseline part type
  const [partType, setPartType] = useState(props.partType);
  const [importedFileName, setImportedFileName] = useState('');
  const [useInitialDisp, setUseInitialDisp] = useState(false);
  const [useInitialSmooth, setUseInitialSmooth] = useState(false);
  const [useBaseRate, setUseBaseRate] = useState(false);
  const [useBaseProgress, setUseBaseProgress] = useState(false);
  const [useRubberEngage, setUseRubberEngage] = useState(false);
  const [useRubberSmooth, setUseRubberSmooth] = useState(false);
  const [useRubberRate, setUseRubberRate] = useState(false);
  const [useRubberProgress, setUseRubberProgress] = useState(false);
  const [importData, setImportData] = useState<DataPoint[]>();
  const [chartData, setChartData] = useState<ChartDataItem[]>(props.chartData || []);

  // Coeff field states
  const [freelength, setFreelength] = useState(props.selectedPart?.data.freelength || 0);
  const [engage_rubber, setEngage_rubber] = useState(props.selectedPart?.data.coeff?.engage_rubber || 0);// eslint-disable-line camelcase
  const [initial_disp, setInitial_disp] = useState(props.selectedPart?.data.coeff?.initial_disp || 0);// eslint-disable-line camelcase
  const [initial_force, setInitial_force] = useState(props.selectedPart?.data.coeff?.initial_force || 0);// eslint-disable-line camelcase
  const [initial_smooth, setInitial_smooth] = useState(props.selectedPart?.data.coeff?.initial_smooth || 0);// eslint-disable-line camelcase
  const [prog_base, setProg_base] = useState(props.selectedPart?.data.coeff?.prog_base || 0);// eslint-disable-line camelcase
  const [prog_rubber, setProg_rubber] = useState(props.selectedPart?.data.coeff?.prog_rubber || 0);// eslint-disable-line camelcase
  const [rate_base, setRate_base] = useState(props.selectedPart?.data.coeff?.rate_base || 0);// eslint-disable-line camelcase
  const [rate_rubber, setRate_rubber] = useState(props.selectedPart?.data.coeff?.rate_rubber || 0);// eslint-disable-line camelcase
  const [scale_factor, setScale_factor] = useState(props.selectedPart?.data.coeff?.scale_factor || 0);// eslint-disable-line camelcase
  const [smooth_factor, setSmooth_factor] = useState(props.selectedPart?.data.coeff?.smooth_factor || 0);// eslint-disable-line camelcase
  const [importPartFile] = useImportPartFileMutation();
  const [customFitPart, setCustomFitPart] = useState<PartInput []>([]);
  const xAxisLabel = 'Displacement (in)';
  const yAxisLabel = 'Load (lbf)';
  const xToolTipLabel = 'Disp';
  const yToolTipLabel = 'Load';
  const { data: springFitData } = useGetSimResultSubscription();

  // Query for Part Fit api request
  const [requestPartFit] = useRequestPartFitMutation();

  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    const { id, value } = e.target as HTMLInputElement;
    switch (id) {
      case 'engage_rubber':
        setEngage_rubber(Number(value));
        break;
      case 'initial_disp':
        setInitial_disp(Number(value));
        break;
      case 'initial_force':
        setInitial_force(Number(value));
        break;
      case 'initial_smooth':
        setInitial_smooth(Number(value));
        break;
      case 'prog_base':
        setProg_base(Number(value));
        break;
      case 'prog_rubber':
        setProg_rubber(Number(value));
        break;
      case 'rate_base':
        setRate_base(Number(value));
        break;
      case 'rate_rubber':
        setRate_rubber(Number(value));
        break;
      case 'scale_factor':
        setScale_factor(Number(value));
        break;
      case 'smooth_factor':
        setSmooth_factor(Number(value));
        break;
      case 'freelength':
        setFreelength(Number(value));
        break;
      default:
        break;
    }
  };

  const getStateCoeffData = () => {
    return {
      freelength,
      coeff: {
        engage_rubber, // eslint-disable-line camelcase
        initial_disp, // eslint-disable-line camelcase
        initial_force, // eslint-disable-line camelcase
        initial_smooth, // eslint-disable-line camelcase
        prog_base, // eslint-disable-line camelcase
        prog_rubber, // eslint-disable-line camelcase
        rate_base, // eslint-disable-line camelcase
        rate_rubber, // eslint-disable-line camelcase
        scale_factor, // eslint-disable-line camelcase
        smooth_factor, // eslint-disable-line camelcase
      },
    };
  };

  const getStatePartInput = () => {
    return {
      partId: props.selectedPart?.id,
      setupId: props.setupId,
      pos: props.position,
      partFormat: partType,
      description: props.selectedPart?.description,
      spec: props.selectedPart?.spec,
      data: getStateCoeffData(),
    };
  };

  // A value has chanded, request a refresh to plot chart data.
  const refreshPlotData = (event: React.FocusEvent<HTMLInputElement>) => {
    const field = event.target.id;
    const initialValue = (field === 'freelength' ? props.selectedPart?.data.freelength : props.selectedPart?.data.coeff[field]);
    const hasChanged = Number(event.target.value) !== initialValue;
    if (props.onUpdatePlotData && hasChanged) {
      // Gather updated Coeff Data and raise event for plot fetching request.
      props.onUpdatePlotData(getStatePartInput() as PartInput);
    }
  };

  const setStateCoeffData = (coeffData: CoeffDataType) => {
    setEngage_rubber(coeffData.engage_rubber);
    setInitial_disp(coeffData.initial_disp);
    setInitial_force(coeffData.initial_force);
    setInitial_smooth(coeffData.initial_smooth);
    setProg_base(coeffData.prog_base);
    setProg_rubber(coeffData.prog_rubber);
    setRate_base(coeffData.rate_base);
    setRate_rubber(coeffData.rate_rubber);
    setScale_factor(coeffData.scale_factor);
    setSmooth_factor(coeffData.smooth_factor);
  };

  // Get ChartDataItems
  const getChartDataItems = (partName: string, setupId: number, setupColor: string, forceData: number[][]) => {
    const fitArray: number [][] = forceData;

    const ChartDataItems = fitArray.map((item: number[]) => ({
      x: item[0],
      y: item[1],
      name: partName,
      id: setupId,
      color: setupColor,
    }));

    return ChartDataItems;
  };

  useEffect(() => {
    // Refresh if import data or imported file name are updated.
  }, [importData, importedFileName]);

  // This useEffect updates the graph chartdata with Coeff fit results.
  useEffect(() => {
    if (springFitData && springFitData.simResult && springFitData.simResult.dataType === 'spring_model_fit') {
      if (springFitData.simResult.pos === props.position) {
        // Update importData
        if (springFitData.simResult.output.import_data) {
          updateStateImportData(springFitData.simResult.output.import_data);
        }

        // Update baseline setup/position chartData based on returned fit data
        if (springFitData.simResult.output.chart_data) {
          let updatedChartData: ChartDataItem[] = [...chartData];
          const foundChartDataItem: ChartDataItem[] = updatedChartData.filter(item => item.id === props.setupId);
          const color = foundChartDataItem.length > 0 && foundChartDataItem[0].color ? foundChartDataItem[0].color : 'red';

          updatedChartData = [...updatedChartData.filter(item => item.id !== props.setupId),
            ...getChartDataItems(`${importedFileName} - FIT` || 'Fit Data', props.setupId, color,  springFitData.simResult.output.chart_data)];

          setChartData(updatedChartData);
        }

        // Update Return Coeff data
        if (springFitData.simResult.output.freelength) {
          setFreelength(springFitData.simResult.output.freelength);
        }
        setStateCoeffData(springFitData.simResult.output.coefficients as CoeffDataType);
      }
    }
  }, [springFitData]);

  useEffect(() => {
    setPartType(props.partType);
  }, [props.partType]);

  useEffect(() => {
    setChartData(props.chartData || []);
  }, [props.chartData]);

  // Use effect to query server for fitting spring parts request
  useEffect(() => {
    if (customFitPart.length > 0) {
      requestPartFit({
        variables: { input: customFitPart, partType: 'spring' },
        fetchPolicy: 'network-only',
      })
        .catch(error => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error submitted fit request: ${error.message}`,
          });
        });
    }
  }, [customFitPart, requestPartFit]);

  // Due to async loading of initial selectedPart, set state in useEffect
  useEffect(() => {
    if (props.selectedPart?.data.coeff) {
      setFreelength(props.selectedPart?.data.freelength || 0);
      setEngage_rubber(props.selectedPart?.data.coeff?.engage_rubber || 0);// eslint-disable-line camelcase
      setInitial_disp(props.selectedPart?.data.coeff?.initial_disp || 0);// eslint-disable-line camelcase
      setInitial_force(props.selectedPart?.data.coeff?.initial_force || 0);// eslint-disable-line camelcase
      setInitial_smooth(props.selectedPart?.data.coeff?.initial_smooth || 0);// eslint-disable-line camelcase
      setProg_base(props.selectedPart?.data.coeff?.prog_base || 0);// eslint-disable-line camelcase
      setProg_rubber(props.selectedPart?.data.coeff?.prog_rubber || 0);// eslint-disable-line camelcase
      setRate_base(props.selectedPart?.data.coeff?.rate_base || 0);// eslint-disable-line camelcase
      setRate_rubber(props.selectedPart?.data.coeff?.rate_rubber || 0);// eslint-disable-line camelcase
      setScale_factor(props.selectedPart?.data.coeff?.scale_factor || 0);// eslint-disable-line camelcase
      setSmooth_factor(props.selectedPart?.data.coeff?.smooth_factor || 0);// eslint-disable-line camelcase
    }
  }, [props.selectedPart]);

  const getImportCoords = (item: number[]) => {
    return {
      x: item[0],
      y: item[1],
    };
  };

  const ProcessSpringFile = async (file: File): Promise<FormattedCSVResult> => {
    // Set local state of imported file name
    setImportedFileName(file.name);

    if (_.endsWith(file.name.toUpperCase(), '.CSV')) {
      try {
        const { procData, objectType } = await readCSVFile(file);
        if (objectType === 'CSV') {
          const result = formatCSVSpringResults({ procData, objectType });
          return result;
        }
      } catch (error) {
        throw new Error('Failed to import CSV file.');
      }
    } else if (_.endsWith(file.name.toUpperCase(), '.ASR') || _.endsWith(file.name.toUpperCase(), '.SPR')) {
      try {
        const fileBinaryString = await new Promise<string>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = () => resolve(fileReader.result as string); // Explicitly cast to exclude null
          fileReader.onerror = reject;
          fileReader.readAsBinaryString(file);
        });

        // If after reading no error was thrown...and fileBinaryString is empty throw error
        if (!fileBinaryString) {
          throw new Error('Failed to import/read binary file.');
        }

        // Call the GraphQL mutation with the prepared arguments
        const response: ImportPartResult = await importPartFile({
          variables: {
            fileBuffer: fileBinaryString,
            fileName: file.name,
            pos: props.position,
            type: 'spring',
          },
        }) as ImportPartResult;

        // Handle the result of the mutation
        if (response.data.importPartFile.success) {
          // Process the successfully imported part file
          return {
            success: response.data.importPartFile.success,
            data: response.data.importPartFile.data.data,
            type: response.data.importPartFile.type || 'defaultType',
          };
        }
        return {
          success: false,
          data: response.data.importPartFile.data?.error,
          type: response.data.importPartFile.type || 'defaultType',
        };
      } catch (error) {
        throw new Error(`Import Binary Error: ${error}`);
      }
    }
    throw new Error('Selected file not supported for importing...');
  };

  const getChartDataArray = (dataPoints: DataPoint[] | undefined): number[][] => {
    if (!dataPoints) {
      return [];
    }

    return dataPoints.map(point => [point.x, point.y]);
  };

  const getStateHolds = () =>  {
    const holdArray: string[] = [];
    if (useInitialDisp) holdArray.push('initial_disp');
    if (useInitialSmooth) holdArray.push('initial_smooth');
    if (useBaseRate) holdArray.push('rate_base');
    if (useBaseProgress) holdArray.push('prog_base');
    if (useRubberEngage) holdArray.push('engage_rubber');
    if (useRubberSmooth) holdArray.push('smooth_factor');
    if (useRubberRate) holdArray.push('rate_rubber');
    if (useRubberProgress) holdArray.push('prog_rubber');

    return holdArray;
  };

  const getFitPartData = () => {
    const importName = importedFileName || 'imported_file';
    const fitData: PartInput = {
      partId: 0,
      setupId: 0,
      pos: props.position,
      partType: 'spring',
      partFormat: partType,
      description: importName,  // Get Import ChartData file name
      spec: props.selectedPart?.spec || Spec.GEN7,
      data: {},
    };
    // If Coeff exist, set payload structure first
    if (partType === 'coeff') {
      fitData.data = getStateCoeffData();
    }

    fitData.data.chartData = getChartDataArray(importData); // Set Import ChartData
    fitData.data.hold = getStateHolds();
    return fitData;
  };

  const handleFitPart = () => {
    // Collect part data into an object and pass/raise  props.onFitClicked
    setCustomFitPart([getFitPartData()]);
  };

  // Open save part dialog
  const handleCommitPart = () => {
    setIsSavePartDialogOpen(true);
  };

  // onSuccess close save part dialog
  const onCommittedPart = (newPart: Part) => {
    // Clear Import Data and imported file name
    setImportData(undefined);
    setImportedFileName('');
    // Fire onChangeHandler for newly created part.
    if (props.onChangeHandler) {
      props.onChangeHandler?.(props.path, newPart.id, props.position, newPart);
    }
  };

  const formatCSVSpringResults = (result: ProcSpringObj): FormattedCSVResult => {
    return {
      success: true,
      type: 'tabular',
      data: result.procData,
    };
  };

  const updateStateImportData = (data: number[][]) => {
    const scatterDatasets: DataPoint[] = data.map(item => ({
      ...getImportCoords(item),
      name: 'import-data',
      displayName: 'Import Data',
      id: -1,
      color: 'white',
    }));
    setImportData(scatterDatasets);
  };

  const handleFileSelected = (selectedFile: File) => {
    if (selectedFile) {
      ProcessSpringFile(selectedFile)
        .then(processResult => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: `Import spring request ${processResult.success ? 'successful' : 'failed'} for file "${selectedFile.name}".`,
          });
          if (processResult.data && processResult.data.length > 0) {
            updateStateImportData(processResult.data);
          }
          return 'Success';
        }).catch(e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Import spring Error for file "${selectedFile.name}": ${e.message}`,
          });
          return 'Failed';
        });
    }
  };

  const isDifferentStyle = (value1: string, value2: string) => {
    return value1 !== value2 ? { color: 'black', background: 'yellow' } : {};
  };
  const GetDBPartData = () => {
    if (partType === 'coeff') {
      return {
        freelength: Number(freelength),
        coeff: {
          engage_rubber: Number(engage_rubber),
          initial_disp: Number(initial_disp),
          initial_force: Number(initial_force),
          initial_smooth: Number(initial_smooth),
          prog_base: Number(prog_base),
          prog_rubber: Number(prog_rubber),
          rate_base: Number(rate_base),
          rate_rubber: Number(rate_rubber),
          scale_factor: Number(scale_factor),
          smooth_factor: Number(smooth_factor),
        },
      };
    }
    // Tabular Shocks, return shock array
    return {
      force_data: getChartDataArray(props.chartData.filter(point => point.id === props.setupId)), // Filter Baseline only data points
      freelength: 0,
    };
  };

  const getSpringInputsUI = () => {
    if (!props.selectedPart || (partType !== 'coeff')) return null;
    return (
      <div className={styles.fieldContainer}>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Free Length
          </span>
          <div className={styles.fieldValue}>
            <InputGroup
              onBlur={refreshPlotData}
              id="freelength"
              value={freelength}
              onChange={handleChange}
              style={isDifferentStyle(freelength, props.selectedPart.data.freelength)}
              disabled={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Scaling Factor
          </span>
          <div className={styles.fieldValue}>
            <InputGroup
              onBlur={refreshPlotData}
              id="scale_factor"
              value={scale_factor}// eslint-disable-line camelcase
              onChange={handleChange}
              style={isDifferentStyle(scale_factor, props.selectedPart.data.coeff?.scale_factor)}
              disabled={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Initial Force
          </span>
          <div className={styles.fieldValue}>
            <InputGroup
              onBlur={refreshPlotData}
              id="initial_force"
              value={initial_force}// eslint-disable-line camelcase
              onChange={handleChange}
              style={isDifferentStyle(initial_force, props.selectedPart.data.coeff?.initial_force)}
              disabled={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Initial Disp
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="initial_disp"
              onBlur={refreshPlotData}
              value={initial_disp}// eslint-disable-line camelcase
              isDifferent={initial_disp !== props.selectedPart.data.coeff?.initial_disp}// eslint-disable-line camelcase
              checked={useInitialDisp}
              handleChange={handleChange}
              handleCheck={() => setUseInitialDisp(!useInitialDisp)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Initial Smooth
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="initial_smooth"
              onBlur={refreshPlotData}
              value={initial_smooth}// eslint-disable-line camelcase
              isDifferent={initial_smooth !== props.selectedPart.data.coeff?.initial_smooth}// eslint-disable-line camelcase
              checked={useInitialSmooth}
              handleChange={handleChange}
              handleCheck={() => setUseInitialSmooth(!useInitialSmooth)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Base Rate
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="rate_base"
              onBlur={refreshPlotData}
              value={rate_base}// eslint-disable-line camelcase
              isDifferent={rate_base !== props.selectedPart.data.coeff?.rate_base}// eslint-disable-line camelcase
              checked={useBaseRate}
              handleChange={handleChange}
              handleCheck={() => setUseBaseRate(!useBaseRate)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Base Progress
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="prog_base"
              onBlur={refreshPlotData}
              value={prog_base}// eslint-disable-line camelcase
              isDifferent={prog_base !== props.selectedPart.data.coeff?.prog_base}// eslint-disable-line camelcase
              checked={useBaseProgress}
              handleChange={handleChange}
              handleCheck={() => setUseBaseProgress(!useBaseProgress)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Rubber Engage
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="engage_rubber"
              onBlur={refreshPlotData}
              value={engage_rubber}// eslint-disable-line camelcase
              isDifferent={engage_rubber !== props.selectedPart.data.coeff?.engage_rubber}// eslint-disable-line camelcase
              checked={useRubberEngage}
              handleChange={handleChange}
              handleCheck={() => setUseRubberEngage(!useRubberEngage)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Rubber Smooth
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="smooth_factor"
              onBlur={refreshPlotData}
              value={smooth_factor}// eslint-disable-line camelcase
              isDifferent={smooth_factor !== props.selectedPart.data.coeff?.smooth_factor}// eslint-disable-line camelcase
              checked={useRubberSmooth}
              handleChange={handleChange}
              handleCheck={() => setUseRubberSmooth(!useRubberSmooth)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Rubber Rate
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="rate_rubber"
              onBlur={refreshPlotData}
              value={rate_rubber}// eslint-disable-line camelcase
              isDifferent={rate_rubber !== props.selectedPart.data.coeff?.rate_rubber}// eslint-disable-line camelcase
              checked={useRubberRate}
              handleChange={handleChange}
              handleCheck={() => setUseRubberRate(!useRubberRate)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
        <div className={styles.fieldLine}>
          <span className={styles.fieldLabel}>
            Rubber Progress
          </span>
          <div className={styles.fieldValue}>
            <InputCheckbox
              id="prog_rubber"
              onBlur={refreshPlotData}
              value={prog_rubber}// eslint-disable-line camelcase
              isDifferent={prog_rubber !== props.selectedPart.data.coeff?.prog_rubber}// eslint-disable-line camelcase
              checked={useRubberProgress}
              handleChange={handleChange}
              handleCheck={() => setUseRubberProgress(!useRubberProgress)}
              readOnly={props.readOnly}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.singleChartContainer}>
      <div className={styles.chartHeader}>
        <span className={styles.chartTitle}>{props.position}</span>
        <PartSelector
          className={styles.partSelector}
          initialPart={props.selectedPart}
          parts={props.parts || []}
          onChange={partId => props.onChangeHandler?.(props.path, partId, props.position, undefined)}
          hideDetails
          disabled={props.readOnly}
        />
        <div className={styles.importSelector}>
          <FilePicker onFileSelected={handleFileSelected} selectedFile={importedFileName} buttonText="Import" extensions=".csv, .asr, .spr" readOnly={props.readOnly} />
        </div>
        <Button
          disabled={!importData || importData.length === 0}
          className={styles.chartHeaderButton}
          small
          onClick={handleFitPart}
        >
          Fit
        </Button>
        <Button
          className={styles.chartHeaderButton}
          small
          onClick={handleCommitPart}
          disabled={props.readOnly}
        >
          Commit
        </Button>
        <SavePartModal
          isOpen={isSavePartDialogOpen}
          onClose={() => setIsSavePartDialogOpen(false)}
          onCommitSuccess={onCommittedPart}
          partData={GetDBPartData()}
          partConfigId={props.partConfigId}
          spec={props.spec}
          desc={importedFileName ? importedFileName.replace(/\.[^/.]+$/, '') : ''}
        />
      </div>
      <div className={styles.chartMainSection}>
        <div className={styles.chart}>
          <Chart
            data={chartData}
            xAxisLabel={xAxisLabel}
            yAxisLabel={yAxisLabel}
            tooltip
            xToolTipLabel={xToolTipLabel}
            yToolTipLabel={yToolTipLabel}
            importData={importData}
          />
        </div>
        {getSpringInputsUI()}
      </div>
    </div>
  );
};
