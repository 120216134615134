import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ColumnDef, RowSelectionState, createColumnHelper } from '@tanstack/react-table';
import { Intent, Dialog, DialogBody, DialogFooter, Button } from '@blueprintjs/core';
import { format } from 'date-fns';
import classNames from 'classnames';
import { debounce, isEmpty, each, keys, keyBy, mapValues } from 'lodash';
import Table, { ParamsChangeFn } from 'components/Table';
import {
  Metric,
  useMetricsLazyQuery,
} from 'graphql/generated/graphql';
import { selectDarkMode } from 'reducers/ui';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (metrics: Metric[]) => void;
}

export default (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const [tableData, setTableData] = useState<Metric[]>([]);
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState({});

  const [getMetrics, { data: metricData }] = useMetricsLazyQuery();

  const columnHelper = createColumnHelper<Metric>();
  const columns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('description', {
      header: 'Description',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('owner', {
      header: 'Owner',
      cell: info => info.getValue(),
      enableColumnFilter: true,
    }),
    columnHelper.accessor('created_at', {
      header: 'Created',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
    columnHelper.accessor('updated_at', {
      header: 'Modified',
      cell: info => {
        const value = info.getValue() as string;
        return format(new Date(value), 'MM/dd/yy HH:mm:ss');
      },
    }),
  ] as ColumnDef<Metric>[];

  useEffect(() => {
    getMetrics();
  }, []);

  useEffect(() => {
    setTableData(metricData?.metrics.rows ?? [] as Metric[]);
  }, [metricData]);

  useEffect(() => {
    if (isEmpty(selectedRows)) {
      setIsAddDisabled(true);
    } else {
      setIsAddDisabled(false);
    }
  }, [selectedRows]);

  const onSelectionChange = (selections: RowSelectionState) => {
    setSelectedRows(selections);
  };

  const onSubmit = () => {
    const selectedMetrics: Metric[] = [];
    each(keys(selectedRows), (index: string) => {
      const selectedMetric = tableData[Number(index)];
      selectedMetrics.push(selectedMetric);
    });

    props.onSuccess(selectedMetrics);
  };

  const onTableParamsChange: ParamsChangeFn = async (filters, pagination, sorting) => {
    let sorts = {};
    if (sorting.length > 0) {
      sorts = { [sorting[0].id]: sorting[0].desc ? 'DESC' : 'ASC' };
    }

    getMetrics({
      variables: {
        input: {
          filters: mapValues(keyBy(filters, 'id'), 'value'),
          pagination: {
            offset: pagination.pageIndex * pagination.pageSize,
            limit: pagination.pageSize,
          },
          sorts,
        },
      },
    });
  };

  const debouncedOnTableParamsChange = debounce(onTableParamsChange, 200);

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Add Metric"
      style={{ width: '80%', maxWidth: 'none' }}
    >
      <DialogBody>
        <Table
          id="metric-selector"
          columns={columns}
          data={tableData}
          enableRowSelection
          enableSingleRowSelection
          onRowSelect={onSelectionChange}
          enablePagination
          enableHiding
          manualPagination
          manualSorting
          manualFiltering
          persistColumnVisibility
          onParamsChange={debouncedOnTableParamsChange as ParamsChangeFn}
          totalRowCount={metricData?.metrics.totalCount || 0}
        />
      </DialogBody>
      <DialogFooter
        actions={[
          <Button key="cancel" onClick={props.onClose} text="Cancel" />,
          <Button
            key="add"
            disabled={isAddDisabled}
            intent={Intent.PRIMARY}
            onClick={onSubmit}
            text="Add"
          />,
        ]}
      />
    </Dialog>
  );
};
