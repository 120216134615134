import { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { Button, ButtonProps, Collapse, CollapseProps } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './index.module.css';

interface Props {
  id?: string;
  className?: string;
  buttonProps?: ButtonProps;
  children: ReactNode;
  collapseProps?: CollapseProps;
  initialOpen?: boolean;
  isOpen?: boolean;
  title: string;
  willToggleCollapse?: (id: string | undefined, newOpenState: boolean) => void;
}

const Accordion = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.initialOpen ?? false);

  const buttonClasses = classNames(styles.collapseHeader, props.buttonProps?.className);
  const iconClasses = classNames(styles.collapseIcon, { [styles.open]: isOpen });

  useEffect(() => {
    if (!isNil(props.isOpen)) setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const handleCollapseClick = () => {
    props.willToggleCollapse?.(props.id, !isOpen);
    setIsOpen(!isOpen);
  };

  const collapseIcon = <FontAwesomeIcon className={iconClasses} icon="angle-right" />;

  return (
    <div className={props.className}>
      <Button
        id={props.id}
        fill
        large
        onClick={handleCollapseClick}
        rightIcon={collapseIcon}
        title={props.title}
        {...props.buttonProps}
        className={buttonClasses}
      >
        {props.title}
      </Button>
      <Collapse isOpen={isOpen} {...props.collapseProps}>
        {props.children}
      </Collapse>
    </div>
  );
};

export default Accordion;
