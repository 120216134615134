import { useRef } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Button, InputGroup, InputGroupProps, Intent } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';

import styles from './index.module.css';
import dayPickerStyles from 'react-day-picker/dist/style.css';
import classNames from 'classnames';

interface Props {
  controllerProps: UseControllerProps;
  inputProps?: InputGroupProps;
  intent?: Intent;
}

export default (props: Props) => {
  const ref = useRef(null);

  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => {
        const onSelect = (selected?: Date) => {
          field.onChange(selected ? format(selected, 'yyyy-MM-dd') : undefined);
        };

        let selected;
        if (field.value) {
          selected = new Date(field.value);
          // Although the persisted value does not include timezone information, `Date` will adjust for
          // the user's current timezone which will throw off the selection on the calendar if not
          // accounted for
          const tzOffset = selected.getTimezoneOffset() * 60_000;
          selected = new Date(selected.getTime() + tzOffset);
        }

        return (
          <InputGroup
            {...field}
            value={field.value ?? ''}
            {...props.inputProps}
            intent={props.intent}
            ref={ref}
            rightElement={(
              <Popover2
                content={(
                  <DayPicker
                    className={styles.dayPicker}
                    classNames={{
                      ...dayPickerStyles,
                      day: classNames('rdp-day', styles.dayPickerButton),
                      day_selected: styles.dayPickerSelected,
                    }}
                    mode="single"
                    onSelect={onSelect}
                    selected={selected}
                  />
                )}
              >
                <Button icon="calendar" intent={props.intent} minimal />
              </Popover2>
            )}
          />
        );
      }}
    />
  );
};
