import { useEffect } from 'react';

const useDocumentTitle = (title) => {
  useEffect(() => {
    // Save the original title
    const originalTitle = document.title;

    // Update the document title when the title prop changes
    document.title = title;

    // Cleanup function to restore the original title when component unmounts
    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};

export default useDocumentTitle;
