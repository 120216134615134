import { useRef } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { TextArea, TextAreaProps } from '@blueprintjs/core';

interface Props {
  controllerProps: UseControllerProps;
  textAreaProps?: TextAreaProps;
}

export default (props: Props) => {
  const ref = useRef(null);

  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => {
        return (
          <TextArea
            {...field}
            value={field.value ?? ''}
            {...props.textAreaProps}
            ref={ref}
          />
        );
      }}
    />
  );
};
