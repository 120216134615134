import { Dialog, DialogBody, DialogFooter, Button, FormGroup, Intent } from '@blueprintjs/core';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import { RHFSelect, RHFTextInput } from 'components/RHFInputs';
import { CreateSimInput } from 'graphql/generated/graphql';
import { simTypeSelectItems, specSelectItems, seriesItems, teamSelectItems, organizationSelectItems  } from '../../constants';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import { find } from 'lodash';
import { useRouteLoaderData } from 'react-router-dom';

interface Props {
  isOpen: boolean,
  onClose: () => void,
  onOk: (input: CreateSimInput) => void,
}

const AddSimDialog = (props: Props) => {
  const darkMode = useSelector(selectDarkMode);
  const nameRef = useRef<HTMLInputElement>(null);
  const form = useForm<Partial<CreateSimInput>>();
  const { control, watch, setValue, reset, formState: { errors } } = form;

  const teamName = watch('team_name');
  const orgName = watch('organization_name');

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const onSubmit = (input: Partial<CreateSimInput>) => {
    props.onOk(input as CreateSimInput);
    reset();
    props.onClose();
  };

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="New Sim"
      onOpened={() => nameRef.current?.focus()}
      style={{ width: '35%' }}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="organization_name" />}
              intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
              label="Organization"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'organization_name',
                  rules: {
                    required: 'Organization is required',
                  },
                }}
                intent={errors.organization_name && Intent.DANGER}
                items={organizationSelectItems(organizations)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="team_name" />}
              intent={errors.team_name ? Intent.DANGER : Intent.NONE}
              label="Team"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'team_name',
                }}
                disabled={!orgName}
                intent={errors.team_name && Intent.DANGER}
                items={teamSelectItems(teams, orgName)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="series" />}
              intent={errors.series ? Intent.DANGER : Intent.NONE}
              label="Series"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'series',
                  rules: {
                    required: 'Series is required',
                  },
                }}
                intent={errors.series && Intent.DANGER}
                items={seriesItems}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="type" />}
              label="Type"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'type',
                  rules: { required: 'Type is required' },
                }}
                items={simTypeSelectItems}
                selectProps={{ fill: true }}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="spec" />}
              label="Spec"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'spec',
                  rules: { required: 'Type is required' },
                }}
                items={specSelectItems}
                selectProps={{ fill: true }}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="name" />}
              label="Name"
              labelInfo="(required)"
            >
              <RHFTextInput
                controllerProps={{
                  control,
                  name: 'name',
                  rules: { required: 'Name is required' },
                }}
              />
            </FormGroup>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                text="Cancel"
                onClick={props.onClose}
              />,
              <Button
                intent="primary"
                text="OK"
                type="submit"
              />]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AddSimDialog;
