import { omit } from 'lodash';

import { GQLSetup } from 'types';

export const deleteProp = (object: { [k: string]: any }, path: string[]) => { // eslint-disable-line @typescript-eslint/no-explicit-any
  const last = path.pop();
  if (!last) return;
  delete path.reduce((o, k) => o[k] || {}, object)[last];
};

export const excludeSetupMeta = (setup: GQLSetup) => {
  return omit(setup, ['id']);
};

export const excludeMetaForCompare = (setup: GQLSetup) => {
  const omitKeys = [
    'id',
    'name',
    'parts',
    'owner',
    'root',
    'parent',
    'updated_at',
    'created_at',
  ];

  return omit(setup, omitKeys);
};
