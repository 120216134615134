import { Outlet } from 'react-router-dom';

import SetupNavigationMenu from 'components/SetupNavigationMenu';

import styles from './index.module.css';

export default () => {
  return (
    <div className={styles.container}>
      <SetupNavigationMenu />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
