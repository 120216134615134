import React, { useState, useEffect } from 'react';
import { Button } from '@blueprintjs/core';
import styles from './index.module.css';

interface FilePickerProps {
  onFileSelected?: (file: File) => void;
  onFilesSelected?: (files: FileList) => void;
  buttonText?: string,
  extensions?: string,
  multiple?: boolean,
  selectedFile?: string,
  showFileName?: boolean,
  readOnly?: boolean,
}

const FilePicker: React.FC<FilePickerProps> = ({ onFileSelected, buttonText = 'Select File', extensions  = '.*', multiple = true, onFilesSelected = undefined, selectedFile = '', showFileName = true, readOnly }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(selectedFile); // Initialize state for file name

  useEffect(() => {
    // Update fileName state when selectedFile prop changes
    setFileName(selectedFile);
  }, [selectedFile]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (onFileSelected && files && files.length >  0) {
      onFileSelected(files[0]);
      setFileName(files[0].name); // Update file name state
    } else if (multiple && onFilesSelected && files && files.length >  0) {
      onFilesSelected(files);
      // For multiple files, you might want to handle the file names differently, e.g., by joining them
      setFileName(Array.from(files).map(file => file.name).join(', '));
    }
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div className={styles.filePicker}>
      {showFileName && fileName && (
        <span
          style={{ cursor: 'pointer', marginRight: '5px', display: 'content',  marginLeft: '5px' }} // Add cursor style to indicate click ability
        >
          {fileName}
        </span>
      )}{}
      <input
        name="file-picker"
        ref={fileInputRef}
        type="file"
        accept={extensions}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
      <Button
        name="file-picker-button"
        onClick={handleClick}
        text={buttonText}
        disabled={readOnly}
      />
    </div>
  );
};

export default FilePicker;
