import { Part, SetupField, SetupFieldPosition, SweepPart, usePartConfigPartsLazyQuery } from 'graphql/generated/graphql';
import styles from './index.module.css';
import { get, useFormContext, useWatch } from 'react-hook-form';
import { Intent, Button } from '@blueprintjs/core';
import { RHFCheckbox, RHFNumericInput, RHFSelect } from 'components/RHFInputs';
import { useEffect, useState } from 'react';
import { SelectItem } from 'types';
import MultiSelect from 'components/MultiSelect';

type SweepPartRowProps = {
  sweepPart: Partial<SweepPart>;
  index: number;
  setupFields: SetupField[];
  prefix?: string;
  isInGroup?: boolean;
}

const partToSelectItem = (part: Part) => {
  return {
    label: part.serial_number?.toString() ?? '',
    value: part,
  };
};

const SweepPartRow = ({ sweepPart, index, setupFields, prefix = '', isInGroup = false }: SweepPartRowProps) => {
  const { control, formState: { errors }, getValues, setValue } = useFormContext();
  const [partList, setPartList] = useState<Part[]>([]);
  const [partSelectItems, setPartsSelectItems] = useState<SelectItem<Part>[]>([]);
  const [selectedPartItems, setSelectedPartItems] = useState<SelectItem<Part>[]>(sweepPart.parts?.map(p => partToSelectItem(p as Part)) ?? []);
  const [positionItems, setPositionItems] = useState<SelectItem<SetupFieldPosition>[]>([]);
  const [selectedSetupField, setSelectedSetupField] = useState<SetupField>();

  const setupFieldItems = setupFields.filter(sf => sf.use_in_sim).map(s => {
    return { label: s.label, value: s.id };
  });

  const selectedSetupFieldId = useWatch({
    name: `${prefix}parts.${index}.setup_field_id`,
    control,
  });

  const [fetchParts] = usePartConfigPartsLazyQuery({
    variables: { input: { filters: { part_config: selectedSetupField?.part_config ? [selectedSetupField.part_config.id] : [0] } } },
    onCompleted: data => {
      setPartList(data?.parts?.rows?.filter(p => p.serial_number).map(p => {
        return p as Part;
      }));
    },
  });

  useEffect(() => {
    setSelectedSetupField(setupFields.find(sf => sf.id === selectedSetupFieldId));
  }, [selectedSetupFieldId, setupFields]);

  useEffect(() => {
    if (selectedSetupField) fetchParts();

    setPositionItems(
      selectedSetupField?.positions
        ? selectedSetupField.positions.map(p => ({ label: p.label, value: p }))
        : []
    );
  }, [selectedSetupField]);

  useEffect(() => {
    setPartsSelectItems(partList.map(p => partToSelectItem(p)));
  }, [partList]);

  useEffect(() => {
    setValue(`${prefix}parts.${index}.parts`, selectedPartItems.map(pi => pi.value), { shouldDirty: false });
  }, [selectedPartItems]);

  const handleRemovePart = () => {
    const currentSweepParts = getValues(`${prefix}parts`) ?? [];
    currentSweepParts.splice(index, 1);
    setValue(`${prefix}parts`, currentSweepParts, { shouldDirty: true });
  };

  return (
    <div className={styles.inputRow}>
      {!isInGroup && (
        <RHFCheckbox
          controllerProps={{
            control,
            name: `${prefix}parts.${index}.enabled`,
          }}
          checkboxProps={{
            className: styles.inlineCheckbox,
            checked: sweepPart.enabled,
          }}
        />
      )}
      <RHFNumericInput
        controllerProps={{
          control,
          name: `${prefix}parts.${index}.setup_field.label`,
        }}
        inputProps={{
          fill: true,
          className: styles.inline,
          value: selectedSetupField?.label,
        }}
        useStringValue
      />
      <div className={styles.inlineButton}>
        <RHFSelect
          controllerProps={{
            control,
            name: `${prefix}parts.${index}.setup_field_id`,
          }}
          items={setupFieldItems}
          selectProps={{
            fill: true,
            filterable: true,
          }}
          buttonProps={{
            rightIcon: 'more',
            text: '',
          }}
          onChange={(newItem) => {
            const newSetupField = setupFields.find(sf => sf.id === newItem.value);
            if (newSetupField?.part_config?.id !== selectedSetupField?.part_config?.id) {
              setSelectedPartItems([]);
            }
          }}
        />
      </div>
      <div className={styles.inline}>
        <MultiSelect
          items={partSelectItems}
          onChange={updatedItems => {
            setSelectedPartItems(updatedItems);
          }}
          selectedItems={selectedPartItems}
          selectProps={{
            // Prevents selecting an item from dismissing this popover as well
            popoverProps: { captureDismiss: true },
            fill: true,
            disabled: !selectedSetupField,
          }}
          disableMultipleSelection={isInGroup}
        />
      </div>
      <div className={styles.inlineSelect}>
        <RHFSelect
          controllerProps={{
            control,
            name: `${prefix}parts.${index}.position`,
          }}
          intent={get(errors, 'position') && Intent.DANGER}
          items={positionItems}
          disabled={!positionItems.length}
          fill
        />
      </div>
      <div className={styles.inlineButton}>
        <Button
          intent={Intent.DANGER}
          type="button"
          icon="cross"
          minimal
          onClick={handleRemovePart}
        />
      </div>
    </div>
  );
};

export default SweepPartRow;
