import { createSlice } from '@reduxjs/toolkit';

import { State } from 'reducers';

export interface Settings {
  darkMode: boolean;
}

export interface UIState {
  settings: Settings;
}

export const initialState = {
  settings: {
    darkMode: true,
  },
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleDarkMode: state => {
      state.settings.darkMode = !state.settings.darkMode;
    },
  },
});
export default uiSlice.reducer;

export const selectUserSettings = (state: State) => state.ui.settings;
export const selectDarkMode = (state: State) => state.ui.settings.darkMode;

export const selectors = {
  selectDarkMode,
  selectUserSettings,
};
