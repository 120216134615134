import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { DeepPartial, Required } from 'utility-types';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Dialog, DialogBody, DialogFooter, FormGroup, Intent } from '@blueprintjs/core';
import classNames from 'classnames';

import RHFTextInput from 'components/RHFInputs/TextInput';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import { SetupBranch, SetupBranchesByRootIdDocument, useCreateBranchMutation, useSetupBranchesByRootIdQuery } from 'graphql/generated/graphql';

interface Props {
  isOpen: boolean,
  onBranchCreated?: (branch: SetupBranch) => void,
  onClose: () => void,
  sourceBranch: Required<DeepPartial<SetupBranch>, 'id' | 'name' | 'root'>,
}

const defaultValues = {
  name: '',
};

export default (props: Props) => {
  const branchNameInputRef = useRef<HTMLInputElement>(null);
  const [rootBranchNames, setRootBranchNames] = useState<string[]>([]);
  const { control, handleSubmit, formState: { errors }, reset } = useForm<{ name: string }>({ defaultValues });
  const darkMode = useSelector(selectDarkMode);

  const { data: rootBranches } = useSetupBranchesByRootIdQuery({
    variables: { rootId: props.sourceBranch.root.id ?? 0 },
  });
  const [createBranch] = useCreateBranchMutation();

  useEffect(() => {
    if (rootBranches?.branches) setRootBranchNames(rootBranches.branches.map(b => b.name));
  }, [rootBranches]);

  const onSubmit = (formValues: { name: string }) => {
    createBranch({
      variables: {
        fromId: props.sourceBranch.id,
        name: formValues.name,
      },
      onCompleted: data => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: `Created new branch named ${formValues.name}`,
        });
        props.onBranchCreated?.(data.branch as SetupBranch);
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Failed to create new branch: ${e.message}`,
        });
      },
      update: (cache, { data: mutationData }) => {
        if (mutationData?.branch) {
          cache.updateQuery({
            query: SetupBranchesByRootIdDocument,
            variables: { rootId: props.sourceBranch.root.id },
          }, queryData => {
            if (!queryData) return undefined;
            return {
              branches: [
                ...queryData.branches,
                mutationData.branch,
              ],
            };
          });
        }
      },
    });
  };

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      icon="git-branch"
      isCloseButtonShown
      isOpen={props.isOpen}
      onClose={() => {
        reset(defaultValues);
        props.onClose();
      }}
      onOpened={() => branchNameInputRef.current?.select()}
      title={`Branching from "${props.sourceBranch.name}"`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogBody>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="name" />}
          >
            <RHFTextInput
              controllerProps={{
                // There's a typing issue with RHF's `Control` type not inheriting the proper
                // type of the form so it's being typed as `any`
                control: control as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                name: 'name',
                rules: {
                  required: 'Branch name is required',
                  pattern: {
                    value: /^(?!.*main).*$/,
                    message: 'Branch name cannot be "main"',
                  },
                  validate: {
                    dupeName: v => !rootBranchNames.includes(v) || 'Cannot use duplicate branch name',
                  },
                },
              }}
              inputProps={{
                intent: errors.name && Intent.DANGER,
                placeholder: 'Branch name',
                inputRef: branchNameInputRef,
              }}
            />
          </FormGroup>
        </DialogBody>
        <DialogFooter
          actions={(
            <>
              <Button
                onClick={props.onClose}
                text="Cancel"
              />
              <Button
                intent="primary"
                text="Create"
                type="submit"
              />
            </>
          )}
        />
      </form>
    </Dialog>
  );
};
