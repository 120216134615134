import { createRef, useEffect, useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouteLoaderData, useSearchParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Button, Intent, Divider, FormGroup } from '@blueprintjs/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons';
import { json } from '@codemirror/lang-json';
import { useCodeMirror } from '@uiw/react-codemirror';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { classname } from '@uiw/codemirror-extensions-classname';

import { Track, useTracksQuery } from 'graphql/generated/graphql';
import {
  RHFSelect,
  RHFTextInput,
} from 'components/RHFInputs';
import { selectDarkMode } from 'reducers/ui';
import {
  simDocumentCategories,
  seriesItems,
  teamSelectItems,
  organizationSelectItems,
  driverSimTypes,
} from '../../../constants';
import { SelectItem, SimDocumentType, SimDocumentCategory } from 'types';
import styles from './index.module.css';
import { find } from 'lodash';

// get a single array containing all types for the select drop down
const allDocumentTypes = simDocumentCategories.flatMap((category: SimDocumentCategory) => (
  category.types.map((type: SimDocumentType) => ({
    label: type.displayName,
    value: type.name,
  }))));

const defaultPartDataStr = '{\n  \n}';

const SimDocumentForm = forwardRef((props, ref) => {
  const [searchParams] = useSearchParams();
  const darkMode = useSelector(selectDarkMode);
  const dataEditor = createRef<HTMLDivElement>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser: { teams, organizations } } = useRouteLoaderData('root') as any;

  const { control, formState: { errors }, getValues, setValue, watch } = useFormContext();
  const documentId = getValues('id');
  const documentData = getValues('data');
  let documentDataStr = defaultPartDataStr;
  if (documentData) documentDataStr = JSON.stringify(documentData, null, 2);
  const simDocumentType = watch('sim_document_type');
  const data = watch('data');
  const teamName = watch('team_name');
  const orgName = watch('organization_name');

  const [dataErrors, setDataErrors] = useState<Error[] | null>(null);
  const [dataStr, setDataStr] = useState<string>(documentDataStr);
  const [tracks, setTracks] = useState<SelectItem<string>[]>([]);

  const { data: tracksData } = useTracksQuery();

  // Expose `getData` to the parent component via ref
  useImperativeHandle(ref, () => ({
    getData: () => {
      try {
        // Attempt to parse the JSON and return it
        return JSON.parse(dataStr);
      } catch (error) {
        return null;
      }
    },
  }));

  const classnameExt = classname({
    add: (lineNumber: number) => {
      // Only need to check the first element here -- if JSON.parse fails, it
      // fails after finding the first error
      if (dataErrors?.[0] instanceof SyntaxError) {
        const res = dataErrors[0].message.match(/line (?<lineNumber>\d+)/);
        if (lineNumber === Number(res?.groups?.lineNumber)) {
          return styles.errorLine;
        }
      }
      return undefined;
    },
  });

  const { setContainer: setDataContainer } = useCodeMirror({
    height: '100%',
    theme: darkMode ? vscodeDark : eclipse,
    container: dataEditor.current,
    extensions: [
      json(),
      classnameExt,
    ],
    onChange: setDataStr,
    value: dataStr,
  });

  const setDataStrIfDifferent = useCallback((newDataStr: string) => {
    if (dataStr !== newDataStr) {
      setDataStr(newDataStr);
    }
  }, [dataStr]);

  useEffect(() => {
    if (tracksData) {
      setTracks(tracksData.tracks.map((t: Track) => {
        return {
          label: t.short_name,
          value: t.short_name,
        } as SelectItem<string>;
      }));
    }
  }, [tracksData]);

  useEffect(() => {
    if (dataEditor.current) setDataContainer(dataEditor.current);
  }, [dataEditor.current]);

  useEffect(() => {
    try {
      const dataObj = JSON.parse(dataStr);
      if (typeof dataObj !== 'object' || Array.isArray(dataObj)) {
        throw new Error('Data must be an object (begin with { and end with })');
      }
      setDataErrors(null);
    } catch (e) {
      setDataErrors([e as Error]);
    }
  }, [dataStr]);

  useEffect(() => {
    const currentFormValue = JSON.stringify(data, null, 2);
    setDataStrIfDifferent(currentFormValue);
  }, [data]);

  useEffect(() => {
    const simDocumentType = searchParams.get('type');
    if (simDocumentType) {
      setValue('sim_document_type', simDocumentType);
    }
  }, [searchParams]);

  const onPrettyPrintClicked = () => {
    setDataStr(JSON.stringify(JSON.parse(dataStr), null, 2));
  };

  // Reset team selections if a user changes organization.
  const findTeam = find(teams, team => team.name === teamName);
  if (findTeam && findTeam.organization.name !== orgName) {
    setValue('team_name', null);
  }

  return (
    <div className={styles.mainForm}>
      <div className={styles.columnsContainer}>
        <div className={styles.inputsColumn}>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="organization_name" />}
            intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
            label="Organization"
            labelInfo="(required)"
          >
            <RHFSelect
              controllerProps={{
                control,
                name: 'organization_name',
                rules: {
                  required: 'Organization is required',
                },
              }}
              intent={errors.organization_name && Intent.DANGER}
              items={organizationSelectItems(organizations)}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="team_name" />}
            intent={errors.team_name ? Intent.DANGER : Intent.NONE}
            label="Team"
          >
            <RHFSelect
              controllerProps={{
                control,
                name: 'team_name',
              }}
              disabled={!orgName}
              intent={errors.team_name && Intent.DANGER}
              items={teamSelectItems(teams, orgName)}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="series" />}
            intent={errors.series ? Intent.DANGER : Intent.NONE}
            label="Series"
            labelInfo="(required)"
          >
            <RHFSelect
              controllerProps={{
                control,
                name: 'series',
                rules: {
                  required: 'Series is required',
                },
              }}
              intent={errors.series && Intent.DANGER}
              items={seriesItems}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="sim_document_type" />}
            intent={errors.sim_document_type ? Intent.DANGER : Intent.NONE}
            label="Type"
            labelInfo="(required)"
          >
            <RHFSelect
              controllerProps={{
                control,
                name: 'sim_document_type',
                rules: {
                  required: 'Type is required',
                },
              }}
              disabled={documentId}
              intent={errors.sim_document_type && Intent.DANGER}
              items={allDocumentTypes}
              selectProps={{
                popoverTargetProps: { className: styles.documentTypeSelectTarget },
              }}
            />
          </FormGroup>
          <FormGroup
            helperText={<ErrorMessage errors={errors} name="desc" />}
            label="Description"
            labelInfo="(required)"
            intent={errors.desc ? Intent.DANGER : Intent.NONE}
          >
            <RHFTextInput
              controllerProps={{
                control,
                name: 'desc',
                rules: {
                  required: 'Description is required',
                },
              }}
              inputProps={{
                intent: errors.desc && Intent.DANGER,
              }}
            />
          </FormGroup>
          {((simDocumentType === 'driver') && (
            <>
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="name" />}
                intent={errors.name ? Intent.DANGER : Intent.NONE}
                label="Name"
                labelInfo="(required)"
              >
                <RHFSelect
                  controllerProps={{
                    control,
                    name: 'name',
                    rules: {
                      required: 'Name is required',
                    },
                  }}
                  intent={errors.name && Intent.DANGER}
                  items={driverSimTypes}
                  selectProps={{
                    popoverTargetProps: { className: styles.documentTypeSelectTarget },
                  }}
                />
              </FormGroup>
              <FormGroup
                helperText={<ErrorMessage errors={errors} name="track" />}
                intent={errors.track ? Intent.DANGER : Intent.NONE}
                label="Track"
                labelInfo="(required)"
              >
                <RHFSelect
                  controllerProps={{
                    control,
                    name: 'track',
                    rules: {
                      required: 'Track is required',
                    },
                  }}
                  intent={errors.track && Intent.DANGER}
                  items={tracks}
                  selectProps={{
                    popoverTargetProps: { className: styles.documentTypeSelectTarget },
                  }}
                />
              </FormGroup>
            </>
          ))}
          {((simDocumentType !== 'driver') && (
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="name" />}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              label="Track"
              labelInfo="(required)"
            >
              <RHFSelect
                controllerProps={{
                  control,
                  name: 'name',
                  rules: {
                    required: 'Track is required',
                  },
                }}
                intent={errors.name && Intent.DANGER}
                items={tracks}
                selectProps={{
                  popoverTargetProps: { className: styles.documentTypeSelectTarget },
                }}
              />
            </FormGroup>
          ))}
        </div>
        <Divider />
        <div className={styles.editorColumn}>
          <FormGroup
            className={styles.editorGroup}
            contentClassName={styles.editorContent}
            helperText={dataErrors?.map(e => <p>{e.message}</p>)}
            label="Data"
            intent={dataErrors ? Intent.DANGER : Intent.NONE}
          >
            <div className={styles.dataEditor}>
              <div
                className={styles.editorContainer}
                id="dataEditor"
                ref={dataEditor}
              />
              <Button
                className={styles.prettyPrintButton}
                icon={<FontAwesomeIcon icon={faBracketsCurly} />}
                minimal
                onClick={onPrettyPrintClicked}
                outlined
                title="Auto-format data"
                disabled={!!dataErrors}
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
});

export default SimDocumentForm;
