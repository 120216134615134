import { Dialog, DialogBody, DialogFooter, Button, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import { ErrorMessage } from '@hookform/error-message';
import classNames from 'classnames';
import Select from 'components/Select';
import { CreateSweepInput, Sweep, SweepMethod, useCreateSweepMutation } from 'graphql/generated/graphql';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectDarkMode } from 'reducers/ui';
import AppToaster from 'helpers/toaster';
import { useRouteLoaderData } from 'react-router-dom';
import { organizationSelectItems, teamSelectItems, seriesItems } from '../../constants';
import { find, get } from 'lodash';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleRefetchSweeps: () => void;
}

const SweepTypes = [
  { label: 'Full Factorial', value: 'FULLFACTORIAL' },
  { label: 'Random', value: 'RANDOM' },
  { label: 'Import', value: 'IMPORT' },
];

const AddSweepDialog = (props: Props) => {
  const { isOpen, onClose, handleRefetchSweeps } = props;
  const nameRef = useRef<HTMLInputElement>(null);
  const darkMode = useSelector(selectDarkMode);
  const [sweepName, setSweepName] = useState<string>('');
  const [sweepDescription, setSweepDescription] = useState<string>('');
  const [sweepType, setSweepType] = useState<string>('');
  const [orgName, setOrgName] = useState<string | null>(null);
  const [teamName, setTeamName] = useState<string | null>(null);
  const [series, setSeries] = useState<string | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { getUser } = useRouteLoaderData('root') as any;
  const { teams, organizations } = getUser;

  const form = useForm<Partial<Sweep>>();
  const { reset, formState: { errors } } = form;
  const [createSweep] = useCreateSweepMutation();

  const handleCreateSweep = (input: CreateSweepInput) => {
    createSweep({
      variables: {
        input: {
          ...input,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'Sweep successfully created',
        });
        handleRefetchSweeps();
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error creating sweep: ${e.message}`,
        });
      },
    });
  };

  const onSubmit = () => {
    if (sweepType) {
      const findOrg = find(organizations, org => orgName === org.name);
      const findTeam = find(teams, team => teamName === team.name);
      const createSweepInput: CreateSweepInput = {
        name: sweepName,
        method: sweepType as SweepMethod,
        description: sweepDescription,
        organization_name: orgName ?? '',
        organization_id: get(findOrg, 'id', null),
        team_id: get(findTeam, 'id', null),
        team_name: teamName,
        series: series ?? '',
      };

      handleCreateSweep(createSweepInput);
    }
    reset();
    onClose();
  };

  useEffect(() => {
    const findTeam = find(teams, team => team.name === teamName);
    if (findTeam && findTeam.organization.name !== orgName) {
      setTeamName(null);
    }
  }, [orgName]);

  return (
    <Dialog
      className={classNames({ 'bp4-dark': darkMode })}
      isCloseButtonShown
      isOpen={isOpen}
      onClose={onClose}
      title="New Sweep"
      onOpened={() => nameRef.current?.focus()}
    >
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <DialogBody>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="name" />}
              label="Name"
              labelInfo="(required)"
            >
              <InputGroup
                onChange={e => setSweepName(e.target.value)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="description" />}
              label="Description"
            >
              <InputGroup
                onChange={e => setSweepDescription(e.target.value)}
              />
            </FormGroup>
            <FormGroup
              helperText={<ErrorMessage errors={errors} name="type" />}
              label="Type"
              labelInfo="(required)"
            >
              <Select
                items={SweepTypes}
                noSelectionText="Select"
                onChange={item => setSweepType(item.value)}
              />
            </FormGroup>
            <FormGroup
              label="Organization"
              helperText={<ErrorMessage errors={errors} name="organization_name" />}
              intent={errors.organization_name ? Intent.DANGER : Intent.NONE}
              labelInfo="(required)"
            >
              <Select
                items={organizationSelectItems(organizations)}
                onChange={item => setOrgName(item.value)}
                intent={errors.organization_name && Intent.DANGER}
              />
            </FormGroup>
            <FormGroup
              label="Team"
              helperText={<ErrorMessage errors={errors} name="team_name" />}
            >
              <Select
                items={teamSelectItems(teams, orgName || undefined)}
                onChange={item => setTeamName(item.value)}
                disabled={!orgName}
              />
            </FormGroup>
            <FormGroup
              label="Series"
              helperText={<ErrorMessage errors={errors} name="series" />}
              labelInfo="(required)"
              intent={errors.series ? Intent.DANGER : Intent.NONE}
            >
              <Select
                items={seriesItems}
                onChange={item => setSeries(item.value)}
              />
            </FormGroup>
          </DialogBody>
          <DialogFooter
            actions={[
              <Button
                text="Cancel"
                onClick={onClose}
              />,
              <Button
                intent="primary"
                text="OK"
                type="submit"
              />]}
          />
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default AddSweepDialog;
