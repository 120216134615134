import { createRef, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  Callout,
  ControlGroup,
  Divider,
  EditableText,
  Icon,
  Intent,
} from '@blueprintjs/core';
import { EditorState, RangeSetBuilder, StateEffect, StateField } from '@codemirror/state';
import {
  Decoration,
  DecorationSet,
  EditorView,
  ViewPlugin,
  ViewUpdate,
  hoverTooltip,
} from '@codemirror/view';
import { RegExpCursor } from '@codemirror/search';
import { json } from '@codemirror/lang-json';
import { useCodeMirror } from '@uiw/react-codemirror';
import { eclipse } from '@uiw/codemirror-theme-eclipse';
import { vscodeDark } from '@uiw/codemirror-theme-vscode';
import { classname } from '@uiw/codemirror-extensions-classname';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import classNames from 'classnames';
import { cloneDeep, set, map, has } from 'lodash';

import RunFieldModal from 'components/RunFieldModal';
import { customValidate, schemaValidate } from 'components/RUITForm/ruit-schema';
import RUITForm from 'components/RUITForm';
import Select from 'components/Select';
import defaultRUIT from 'fixtures/default-ruit.json';
import {
  RUIT,
  RunField,
  UpdateRUITInput,
  useRUITByIdQuery,
  useRunFieldsQuery,
  useUpdateRUITMutation,
} from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import { selectDarkMode } from 'reducers/ui';
import {
  RunUITemplate,
  GenericRunField,
  ValidRunField,
} from 'types';
import { presetRunFields } from '../../constants';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import styles from './index.module.css';

const defaultRUITStr = JSON.stringify(defaultRUIT, null, 2);

type SelectableRunField = {
  label: string;
  value: ValidRunField;
}

/**
 * Run field name highlighting
 *
 * Creates CodeMirror `StateField`s and `StateEffect`s for updating (similar
 * to a Redux reducer) the run fields and selected field name and creates a
 * plugin which listens for transactions with that effect (action) in order to
 * highlight all run fields + the currently selected run field name
 */
const updateSelectedFieldName = StateEffect.define<string | undefined>();
const updateRunFields = StateEffect.define<RunField[]>();
const stateRunFields: StateField<RunField[]> = StateField.define({
  create() {
    return [] as RunField[];
  },
  update(oldValue, tx) {
    let newValue = oldValue;
    tx.effects.forEach(e => {
      if (e.is(updateRunFields)) newValue = e.value;
    });
    return newValue;
  },
});
const selectedFieldName: StateField<string | undefined> = StateField.define({
  create(): string | undefined {
    return undefined;
  },
  update(oldValue, tx): string | undefined {
    let newValue = oldValue;
    tx.effects.forEach(e => {
      if (e.is(updateSelectedFieldName)) newValue = e.value;
    });
    return newValue;
  },
});
const fieldNameHighlight = Decoration.mark({ class: styles.runField });
const selectedFieldHighlight = Decoration.mark({ class: styles.selectedRunField });
const missingFieldHighlight = Decoration.mark({ class: styles.missingRunField });
const selectedFieldHighlightPlugin = ViewPlugin.fromClass(class {
  decorations: DecorationSet;

  constructor(view: EditorView) {
    this.decorations = this.createDecorations(view);
  }

  update(update: ViewUpdate) {
    this.decorations = this.createDecorations(update.view);
  }

  // eslint-disable-next-line class-methods-use-this
  createDecorations(view: EditorView): DecorationSet {
    const builder = new RangeSetBuilder();

    const highlightValue = view.state.field(selectedFieldName);
    const fields = view.state.field(stateRunFields);
    if (!highlightValue) return builder.finish() as DecorationSet;

    const { doc } = view.state;
    const cursor = new RegExpCursor(doc, '(?<="run_field":\\s?").*?(?=")', undefined, 0, doc.length);
    while (!cursor.next().done) {
      const [fieldName] = cursor.value.match;
      const isSelected = fieldName === highlightValue;
      const fieldExists = fields.some(f => f.name === fieldName);

      let highlight = fieldNameHighlight;
      if (isSelected) highlight = selectedFieldHighlight;
      else if (!fieldExists) highlight = missingFieldHighlight;

      builder.add(cursor.value.from, cursor.value.to, highlight);
    }
    return builder.finish() as DecorationSet;
  }
}, { decorations: v => v.decorations });

/**
 * Run field hover tooltip
 */
export const runFieldHover = hoverTooltip((view, pos) => {
  const { from, to, text } = view.state.doc.lineAt(pos);
  const matches = text.match(/(?<="run_field":\s?").*?(?=")/);
  const fieldName = matches?.[0];
  if (fieldName) {
    const fieldNameIndex = text.indexOf(fieldName);
    const start = from + fieldNameIndex;
    const end = to - 1;
    if (pos >= start && pos <= end) {
      return {
        pos,
        end: to,
        above: true,
        create: () => {
          const fields = view.state.field(stateRunFields);
          const field = fields.find(f => f.name === fieldName);

          const el = document.createElement('div');
          el.className = styles.runFieldTooltip;

          if (field) {
            const shownField = {
              ...field,
              __typename: undefined,
            };
            if (field.positions) {
              shownField.positions = field.positions.map(p => ({ ...p, __typename: undefined }));
            }
            const runFieldStr = JSON.stringify(shownField, null, 2);
            new EditorView({ // eslint-disable-line no-new
              parent: el,
              state: EditorState.create({
                doc: runFieldStr,
                extensions: [vscodeDark, json()],
              }),
            });
          } else {
            el.innerText = 'Run Field not found';
          }

          return { dom: el };
        },
      };
    }
  }
  return null;
});

const defaultRUITValues = {
  name: '',
  description: '',
};

/*
* Component
*/
export default () => {
  const params = useParams();
  const ruitId = Number(params.ruitId);

  const darkMode = useSelector(selectDarkMode);

  const [ruit, setRUIT] = useState<RUIT>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errors, setErrors] = useState<Error[] | null>(null);
  const [isCreateFieldOpen, setIsCreateFieldOpen] = useState(false);
  const [runFields, setRunFields] = useState<RunField[]>([]);
  const [selectedRunField, setSelectedRunField] = useState<RunField | GenericRunField>();
  const [runFieldStr, setRunFieldStr] = useState<string>();
  const [templateStr, setTemplateStr] = useState(defaultRUITStr);
  const [template, setTemplate] = useState<RunUITemplate>(defaultRUIT as unknown as RunUITemplate);

  useDocumentTitle(
    ruit && ruit.name ? `Apex Setup - ${ruit.name}` : 'Apex Setup'
  );

  const form = useForm<UpdateRUITInput>({ defaultValues: defaultRUITValues });
  const runForm = useForm({ defaultValues: { runs: [] } });

  useRunFieldsQuery({
    onCompleted: data => setRunFields(data.runFields.rows),
  });
  useRUITByIdQuery({
    variables: { id: ruitId },
    skip: !ruitId,
    onCompleted: data => {
      if (data.ruit) {
        setRUIT(data.ruit);
        setIsEdit(true);
        form.reset({ ...data.ruit });
      }
    },
  });
  const [updateRUIT] = useUpdateRUITMutation();

  const filteredRunFields: SelectableRunField[] = useMemo(() => {
    const filteredFields = runFields as RunField[];
    if (filteredFields.length) setSelectedRunField(filteredFields[0]);
    return [
      ...filteredFields.map(field => ({ label: field.label, value: field })),
      ...presetRunFields.map(field => ({ label: field.label, value: field })),
    ];
  }, [runFields]);

  const runFieldEditor = createRef<HTMLDivElement>();
  const ruitEditor = createRef<HTMLDivElement>();

  const classnameExt = classname({
    add: (lineNumber: number) => {
      // Only need to check the first element here -- if JSON.parse fails, it
      // fails after finding the first error
      if (errors?.[0] instanceof SyntaxError) {
        const res = errors[0].message.match(/line (?<lineNumber>\d+)/);
        if (lineNumber === Number(res?.groups?.lineNumber)) {
          return styles.errorLine;
        }
      }
      return undefined;
    },
  });

  const editorConfig = {
    height: '100%',
    theme: darkMode ? vscodeDark : eclipse,
  };
  const { setContainer: setRunFieldContainer } = useCodeMirror({
    ...editorConfig,
    container: runFieldEditor.current,
    extensions: [
      json(),
      stateRunFields,
    ],
    readOnly: true,
    value: runFieldStr,
  });
  const { setContainer: setRUITContainer, view } = useCodeMirror({
    ...editorConfig,
    container: ruitEditor.current,
    extensions: [
      json(),
      selectedFieldHighlightPlugin,
      selectedFieldName,
      stateRunFields,
      runFieldHover,
      classnameExt,
    ],
    onChange: setTemplateStr,
    value: templateStr,
  });

  useEffect(() => {
    if (ruit?.template) setTemplateStr(JSON.stringify(ruit.template, null, 2));
  }, [ruit?.template]);

  useEffect(() => {
    if (runFieldEditor.current) setRunFieldContainer(runFieldEditor.current);
    if (ruitEditor.current) setRUITContainer(ruitEditor.current);
  }, [runFieldEditor.current, ruitEditor.current]);

  useEffect(() => {
    const isRunField = (field: RunField | GenericRunField | undefined): field is RunField => {
      return (field as RunField).type !== undefined;
    };

    const mapRunFieldPositions = () => {
      if (isRunField(selectedRunField) && has(selectedRunField, 'positions')) {
        return map(selectedRunField.positions, (p) => (p.label));
      } return undefined;
    };

    if (!selectedRunField) {
      return;
    }

    // Removes the GraphQL `__typename` field for display purposes
    const field = {
      ...selectedRunField,
      __typename: undefined,
      id: undefined,
      positions: mapRunFieldPositions(),
    };

    setRunFieldStr(JSON.stringify(field, null, 2));

    // Notifies CodeMirror that the selected run field name has changed
    view?.dispatch(view.state.update({
      effects: [updateSelectedFieldName.of(selectedRunField?.name)],
    }));
  }, [selectedRunField, view]);

  useEffect(() => {
    // Notifies CodeMirror that there are new run fields for highlighting
    view?.dispatch(view.state.update({
      effects: [updateRunFields.of(runFields as RunField[])],
    }));
  }, [runFields, view]);

  useEffect(() => {
    const availableRunFields: ValidRunField[] = [
      ...runFields,
      ...presetRunFields,
    ];
    try {
      const templateObj = JSON.parse(templateStr);
      if (schemaValidate(templateObj)) {
        const customValidationErrors = customValidate(templateObj, availableRunFields as ValidRunField[]);
        if (!customValidationErrors) {
          setTemplate(templateObj);
          setErrors(null);
        } else {
          setErrors(customValidationErrors);
        }
      } else {
        setErrors(schemaValidate.errors?.map(e => {
          return new Error(`${e.instancePath ? `${e.instancePath}: ` : ''}${e.message}`);
        }) ?? []);
      }
    } catch (e) {
      setErrors([e as Error]);
    }
  }, [templateStr, runFields]);

  const onPrettyPrintClicked = () => {
    setTemplateStr(JSON.stringify(JSON.parse(templateStr), null, 2));
  };

  const onRunFieldCreated = () => {
    setIsCreateFieldOpen(false);
  };

  const handleUpdateRUIT = (input: UpdateRUITInput) => {
    if (!ruit) return;
    updateRUIT({
      variables: {
        input: {
          ...input,
          name: ruit.name,
          description: ruit.description,
          template,
        },
      },
      onCompleted: () => {
        AppToaster.show({
          intent: Intent.SUCCESS,
          message: 'RUIT successfully updated',
        });
      },
      onError: e => {
        AppToaster.show({
          intent: Intent.DANGER,
          message: `Error updating RUIT: ${e.message}`,
        });
      },
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onRUITMetaChange = (target: string, value: any) => {
    if (!ruit) return;

    const newRUIT = cloneDeep(ruit);
    set(newRUIT, target, value);
    setRUIT(newRUIT);
  };

  const containerClasses = classNames('ruitForm', styles.container, { [styles.dark]: darkMode });

  return (
    <>
      {!errors && (
        <Callout
          className={classNames(styles.calloutContainer, styles.successContainer)}
          icon={false}
          intent={Intent.SUCCESS}
          title="Template is valid and can be saved"
        >
          <Button
            icon="add"
            onClick={form.handleSubmit(handleUpdateRUIT)}
            text="Save RUIT"
          />
        </Callout>
      )}
      {errors && (
        <Callout
          className={classNames(styles.calloutContainer, styles.errorContainer)}
          intent={Intent.DANGER}
          title="Template Invalid"
        >
          <ul className={styles.errorsList}>
            {errors.slice(-5).map((e, i) => <li key={`error-${i}`}>{e.message}</li>)}
          </ul>
          {errors.length > 5 && (
            <p>...and {errors.length - 5} more errors</p>
          )}
        </Callout>
      )}
      {isEdit && (
        <div className={styles.titleBar}>
          <div className={styles.titleContainer}>
            <div className={styles.titleColumn}>
              <div className={styles.titleLabel}>Name</div>
              <EditableText
                className={styles.titleValue}
                value={ruit?.name}
                onChange={value => onRUITMetaChange('name', value)}
                placeholder="Name"
              />
            </div>
            <div className={styles.titleColumn}>
              <div className={styles.titleLabel}>Description</div>
              <EditableText
                className={styles.titleValue}
                value={ruit?.description || ''}
                onChange={value => onRUITMetaChange('description', value)}
                placeholder="Description"
              />
            </div>
          </div>
        </div>
      )}
      <PanelGroup className={containerClasses} direction="vertical">
        <Panel
          className={styles.editorRow}
          defaultSize={40}
          minSize={20}
        >
          <div className={styles.editorRow}>
            <div className={styles.runFieldEditorOuter}>
              <div className={styles.runSelectContainer}>
                <ControlGroup>
                  <Select
                    buttonProps={{ className: styles.runFieldSelectButton }}
                    fill
                    value={selectedRunField ? { label: selectedRunField.label, value: selectedRunField } : undefined}
                    items={filteredRunFields}
                    noSelectionText="Select Run Field"
                    onChange={item => setSelectedRunField(item.value)}
                    popoverProps={{ fill: true }}
                  />
                  <Button
                    icon="add"
                    onClick={() => setIsCreateFieldOpen(true)}
                    title="Add Run Field"
                  />
                </ControlGroup>
              </div>
              <div className={styles.runFieldEditor}>
                <span className={classNames(styles.editorLabel, 'bp4-text-small')}>Run Field</span>
                <div className={styles.editorContainer} id="runFieldEditor" ref={runFieldEditor} />
              </div>
            </div>
          </div>
          <div className={styles.ruitEditor}>
            <span className={classNames(styles.editorLabel, 'bp4-text-small')}>RUIT</span>
            <Button
              className={styles.prettyPrintButton}
              icon={<FontAwesomeIcon icon={faBracketsCurly} />}
              minimal
              onClick={onPrettyPrintClicked}
              outlined
            />
            <div className={styles.editorContainer} id="ruitEditor" ref={ruitEditor} />
          </div>
        </Panel>
        <PanelResizeHandle className={styles.resizeHandleContainer}>
          <Divider className={styles.resizeDivider} />
          <Icon
            className={styles.resizeHandle}
            icon="arrows-vertical"
            size={12}
          />
        </PanelResizeHandle>
        <Panel className={styles.previewContainer}>
          <FormProvider {...runForm}>
            <RUITForm runIndex={0} template={template} runFields={runFields as RunField[]} />
          </FormProvider>
        </Panel>
      </PanelGroup>
      <RunFieldModal
        isOpen={isCreateFieldOpen}
        onClose={() => setIsCreateFieldOpen(false)}
        onSuccess={onRunFieldCreated}
      />
    </>
  );
};
