import { Button, EditableText, Intent } from '@blueprintjs/core';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { FormProvider, set, useForm } from 'react-hook-form';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import Accordion from 'components/Accordion';
import { useUpdateMetricMutation, useMetricChannelsQuery, useMetricByIdQuery, UpdateMetricInput, MetricCalculationMethod, Metric, MetricOutputInput } from 'graphql/generated/graphql';
import AppToaster from 'helpers/toaster';
import MetricOutputRow from './MetricOutputRow';
import classNames from 'classnames';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export type MetricForm = {
  name?: string;
  description?: string;
  outputs: Partial<MetricOutputInput>[];
}

export default () => {
  const params = useParams();
  const metricId = Number(params.metricId);
  const [metric, setMetric] = useState<Metric>();
  const [channelOptions, setChannelOptions] = useState<{ label: string, value: number }[]>([]);

  useDocumentTitle(
    metric && metric.name ? `Apex Setup - ${metric.name}` : 'Apex Setup'
  );

  const form = useForm<MetricForm>({
    defaultValues: {
      name: '',
      description: '',
      outputs: [],
    },
  });

  const { setValue, getValues, watch, formState: { isDirty } } = form;

  const [updateMetric] = useUpdateMetricMutation();

  useMetricChannelsQuery({
    onCompleted: data => setChannelOptions(data.metricChannels.map(c => ({ label: c.name, value: c.id }))),
  });

  const { loading } = useMetricByIdQuery({
    variables: { id: metricId },
    skip: !metricId,
    onCompleted: data => {
      if (data.metric) {
        setMetric({
          id: data.metric.id,
          name: data.metric.name,
          description: data.metric.description,
          outputs: data.metric.outputs ?? [],
          owner: data.metric.owner,
          organization_id: data.metric.organization_id,
          organization_name: data.metric.organization_name,
          series: data.metric.series,
        });
        form.reset({
          name: data.metric.name,
          outputs: data.metric.outputs as MetricOutputInput[],
        });
      }
    },
    fetchPolicy: 'no-cache',
  });

  const outputs = watch('outputs');

  const onMetricMetaChange = (target: string, value: string | number) => {
    if (!metric) return;

    const newMetric = cloneDeep(metric);
    set(newMetric, target, value);
    setMetric(newMetric);
  };

  const onSubmit = useCallback((input: MetricForm) => {
    if (metric) {
      const updateMetricInput: UpdateMetricInput = {
        id: metric.id,
        name: input.name || '',
        description: input.description,
        outputs: input.outputs
          .filter(output => output.channel_id)
          .map(output => ({
            ...output,
            metric_id: metric.id,
          } as MetricOutputInput)),
      };
      updateMetric({
        variables: {
          input: {
            ...updateMetricInput,
          },
        },
        onCompleted: () => {
          AppToaster.show({
            intent: Intent.SUCCESS,
            message: 'Metric successfully updated',
          });

          form.reset({ ...input });
        },
        onError: e => {
          AppToaster.show({
            intent: Intent.DANGER,
            message: `Error updating Metric: ${e.message}`,
          });
        },
      });
    }
  }, [metric]);

  const handleAddOutput = () => {
    const currentOutputs = getValues('outputs') ?? [];
    const newOutput: Partial<MetricOutputInput> = {
      name: '',
      enabled: true,
      calculation_method: MetricCalculationMethod.MAX,
    };

    setValue('outputs', [...currentOutputs, newOutput], { shouldDirty: true });
  };

  return (
    <FormProvider {...form}>
      <form className={styles.mainForm} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={styles.titleBar}>
          <div className={styles.titleContainer}>
            <div className={styles.titleColumn}>
              <div className={styles.titleLabel}>Name</div>
              <EditableText
                className={styles.titleValue}
                value={metric?.name}
                onChange={value => {
                  onMetricMetaChange('name', value);
                  setValue('name', value, { shouldDirty: true });
                }}
                placeholder="Metric name"
              />
            </div>
          </div>
          <div className={styles.titleColumnDesc}>
            <div className={styles.titleLabel}>Description</div>
            <EditableText
              className={styles.titleValue}
              value={metric?.description || ''}
              onChange={value => {
                onMetricMetaChange('description', value);
                setValue('description', value, { shouldDirty: true });
              }}
              minWidth={2}
              placeholder="Description"
            />
          </div>
        </div>
        <div className={styles.mainContainer}>
          <div className={styles.nav}>
            <Button
              className={styles.saveButton}
              intent={Intent.PRIMARY}
              type="submit"
              text="Save"
              fill
              loading={loading}
              disabled={!isDirty}
            />
          </div>
          <div className={styles.metricContainer}>
            <Accordion
              className={styles.itemContainer}
              id="section-0"
              initialOpen
              key="metrics"
              title="Metrics"
              buttonProps={{
                className: styles.accordionHeader,
              }}
            >
              <div className={styles.sectionContainer}>
                <div>
                  <div className={styles.headerRow}>
                    <div className={styles.inlineCheckbox}>Outputs</div>
                    <div className={styles.inline}>
                      <div className={styles.addButton}>
                        <Button
                          intent={Intent.PRIMARY}
                          type="button"
                          fill
                          icon="plus"
                          small
                          onClick={handleAddOutput}
                        />
                      </div>
                    </div>
                    <div className={styles.inlineSelect}>Metric</div>
                    <div className={classNames(styles.inline, styles.nameHeader)}>Name</div>
                  </div>
                  {outputs?.map((o, index) => {
                    return (
                      <MetricOutputRow
                        output={o}
                        index={index}
                        channelOptions={channelOptions}
                      />
                    );
                  })}
                </div>
              </div>
            </Accordion>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
