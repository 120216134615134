import { Button, Intent } from '@blueprintjs/core';
import { SetupField, SweepGroup, SweepParameter, SweepParameterApplicationMethod, SweepParameterGenerationMethod, SweepPart } from 'graphql/generated/graphql';
import styles from './index.module.css';
import SweepPartRow from './SweepPartRow';
import { RHFCheckbox } from 'components/RHFInputs';
import SweepParameterRow from './SweepParameterRow';
import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

type SweepGroupRowProps = {
  group: Partial<SweepGroup>;
  groupIndex: number;
  parameterOptions: { label: string, value: string }[];
  setupFields: SetupField[];
}

const SweepGroupRow = ({
  group,
  groupIndex,
  parameterOptions,
  setupFields,
}: SweepGroupRowProps) => {
  const { control, setValue, getValues, watch } = useFormContext();

  const parts = watch(`sweep_groups.${groupIndex}.parts`);
  const parameters = watch(`sweep_groups.${groupIndex}.parameters`);

  const handleAddParameter = () => {
    const currentParameters = getValues(`sweep_groups.${groupIndex}.parameters`) ?? [];
    const newParameter: Partial<SweepParameter> = {
      enabled: true,
      name: '',
      param_application_method: SweepParameterApplicationMethod.ABSOLUTE,
      param_generation_method: SweepParameterGenerationMethod.STEP,
      path: '',
      sweep_group_id: group.id,
    };

    setValue(`sweep_groups.${groupIndex}.parameters`, [...currentParameters, newParameter], { shouldDirty: true });
  };

  const handleAddPart = () => {
    const currentParts = getValues(`sweep_groups.${groupIndex}.parts`) ?? [];
    const newPart: Partial<SweepPart> = {
      enabled: true,
      setup_field: undefined,
      parts: [],
      position: undefined,
      sweep_group_id: group.id,
    };

    setValue(`sweep_groups.${groupIndex}.parts`, [...currentParts, newPart], { shouldDirty: true });
  };

  const handleRemoveGroup = () => {
    const currentSweepGroups = getValues('sweep_groups') ?? [];
    currentSweepGroups.splice(groupIndex, 1);
    setValue('sweep_groups', currentSweepGroups, { shouldDirty: true });
  };

  return (
    <>
      <div className={styles.groupHeader}>
        <RHFCheckbox
          controllerProps={{
            control,
            name: `sweep_groups.${groupIndex}.enabled`,
          }}
          checkboxProps={{
            checked: group.enabled,
            className: styles.headerCheckbox,
          }}
        />
        <div className={styles.addButton}>
          <Button
            intent={Intent.PRIMARY}
            type="button"
            text="Add Parameter"
            icon="plus"
            small
            onClick={handleAddParameter}
          />
        </div>
        <div className={styles.addButton}>
          <Button
            intent={Intent.PRIMARY}
            type="button"
            text="Add Part"
            icon="plus"
            small
            onClick={handleAddPart}
          />
        </div>
        <div className={styles.rightButton}>
          <Button
            intent={Intent.DANGER}
            type="button"
            icon="cross"
            minimal
            onClick={handleRemoveGroup}
          />
        </div>
      </div>
      <div className={styles.group}>
        {parameters?.length > 0 && (
          <div className={styles.headerRow}>
            <h6 className={styles.inline}>Parameters</h6>
            <div className={styles.inlineCheckbox}> </div>
            <h6 className={classNames(styles.inline, styles.pathHeader)}>Path</h6>
            <h6 className={styles.inlineSelect}>Absolute/Delta</h6>
            <h6 className={styles.inlineSelect}>Step/Range</h6>
            <h6 className={styles.inlineSmall}>Min</h6>
            <h6 className={styles.inlineSmall}>Increment</h6>
            <h6 className={styles.inlineSmall}>Max</h6>
            <div className={styles.inlineCheckbox}> </div>
          </div>
        )}
        {parameters?.map((p: Partial<SweepParameter>, index: number) => {
          return (
            <SweepParameterRow
              sweepParameter={p}
              index={index}
              parameterOptions={parameterOptions}
              prefix={`sweep_groups.${groupIndex}.`}
              isInGroup
            />
          );
        })}
        {parts?.length > 0 && (
          <div className={styles.headerRow}>
            <h6 className={styles.inline}>Parts</h6>
            <div className={styles.inlineCheckbox}> </div>
            <h6 className={styles.inlineSelect}>Position</h6>
            <div className={styles.inlineCheckbox}> </div>
          </div>
        )}
        {parts?.map((p: Partial<SweepPart>, index: number) => {
          return (
            <SweepPartRow
              sweepPart={p}
              index={index}
              setupFields={setupFields}
              prefix={`sweep_groups.${groupIndex}.`}
              isInGroup
            />
          );
        })}
      </div>
    </>
  );
};

export default SweepGroupRow;
