import { ReactNode, forwardRef, Ref } from 'react';
import { EditableText } from '@blueprintjs/core';
import classNames from 'classnames';

import Select from 'components/Select';
import { specSelectItems } from '../../constants';
import { Spec } from 'graphql/generated/graphql';

import styles from './index.module.css';

interface TitleProps {
  children?: ReactNode;
  forwardedRef?: Ref<HTMLDivElement>;
  onChange?: (key: string, value: string) => void;
  values: {
    spec?: Spec | null,
    name?: string | null,
    year?: number | null,
    track?: string | null,
    event?: string | null,
    session?: string | null,
    description?: string | null,
  }
}

export default forwardRef<HTMLDivElement, TitleProps>((props: TitleProps, ref: Ref<HTMLDivElement>) => {
  const { values, children, forwardedRef } = props;

  return (
    <div className={styles.titleBar} ref={forwardedRef ?? ref}>
      <div className={styles.titleContainer}>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Spec</div>
          <Select
            buttonProps={{ className: styles.specSelectButton }}
            disabled={!props.onChange}
            initialItem={specSelectItems.find(i => values.spec === i.value)}
            items={specSelectItems}
            noSelectionText="Spec"
            onChange={item => props.onChange?.('spec', item.value)}
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Name</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={values.name ?? ''}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('name', value)}
            placeholder="Name"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Year</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={String(values.year ?? '')}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('year', value)}
            minWidth={4}
            placeholder="Year"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Track</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={String(values.track ?? '')}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('track', value)}
            minWidth={4}
            placeholder="Track"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Event</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={values.event ?? ''}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('event', value)}
            minWidth={2}
            placeholder="Event"
          />
        </div>
        <div className={styles.titleColumn}>
          <div className={styles.titleLabel}>Session</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={values.session ?? ''}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('session', value)}
            minWidth={2}
            placeholder="Session"
          />
        </div>
        <div className={styles.titleColumnDesc}>
          <div className={styles.titleLabel}>Description</div>
          <EditableText
            className={classNames(styles.titleValue)}
            defaultValue={values.description || ''}
            disabled={!props.onChange}
            onChange={value => props.onChange?.('description', value)}
            minWidth={2}
            placeholder="Description"
          />
        </div>
      </div>
      {children}
    </div>
  );
});
