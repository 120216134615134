import { Button, Dialog, DialogBody, DialogFooter, Intent } from '@blueprintjs/core';
import styles from './index.module.css';
import { useEffect, useState } from 'react';
import { selectDarkMode } from 'reducers/ui';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { SimProgress, SimStatus, useSubscriptionContext } from 'context/SubscriptionContext';
import SimProgressVisual from './SimProgressVisual';
import { maxBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import AppToaster from 'helpers/toaster';
import SimProgressText from './SimProgressText';

export default () => {
  const [showSimList, setShowSimList] = useState<boolean>(false);
  const [recentSim, setRecentSim] = useState<SimProgress>();
  const [recentSimStatus, setRecentSimStatus] = useState<SimStatus>();

  const darkMode = useSelector(selectDarkMode);
  const { simProgress, simStatus, clearSimProgressData, getExistingProgress } = useSubscriptionContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (simProgress) {
      const mostRecentSim = maxBy(simProgress, sp => Date.parse(sp.started));
      if (!(mostRecentSim?.curr === mostRecentSim?.total && mostRecentSim?.status === 'Finished')
        && mostRecentSim?.status !== 'Failed') {
        setRecentSim(mostRecentSim ?? simProgress[0]);
        setRecentSimStatus(simStatus.find(s => s.cancelId === mostRecentSim?.cancelId));
      } else {
        setRecentSim(undefined);
      }
    }
  }, [simProgress, simStatus]);

  const clearSimProgress = () => {
    clearSimProgressData();
    getExistingProgress();
    setShowSimList(false);
    AppToaster.show({
      intent: Intent.SUCCESS,
      message: 'Cleared simulation progress',
    });
  };

  return (
    <>
      <div className={styles.simProgressHeader}>
        { recentSim && recentSimStatus && (
          <>
            <span className={styles.simProgressText}>
              <SimProgressText {...recentSim} simStatus={recentSimStatus} />
            </span>
            <SimProgressVisual visualType="spinner" {...recentSim} simStatus={recentSimStatus} />
          </>
        )}
        <Button
          className={styles.simProgressButton}
          small
          text={recentSim ? 'Sims in Progress' : 'No Sims Running'}
          onClick={() => setShowSimList(true)}
        />
      </div>
      <Dialog
        title="Simulation Progress"
        usePortal
        canOutsideClickClose
        canEscapeKeyClose
        isOpen={showSimList}
        className={classNames({ 'bp4-dark': darkMode })}
        onClose={() => setShowSimList(false)}
      >
        <DialogBody>
          {simProgress.map((simProg, index) => {
            const status = simStatus.find(s => s.cancelId === simProg.cancelId);
            return (
              <>
                {index !== 0 && <hr />}
                {status && (
                  <>
                    <SimProgressText {...simProg} simStatus={status} />
                    <SimProgressVisual visualType="bar" {...simProg} simStatus={status} />
                  </>
                )}
              </>
            );
          })}
        </DialogBody>
        <DialogFooter actions={(
          <>
            <Button
              intent={Intent.NONE}
              text="Clear"
              onClick={clearSimProgress}
            />
            <Button
              intent={Intent.PRIMARY}
              text="Sim Reporting"
              onClick={() => {
                setShowSimList(false);
                navigate('sims/reporting');
              }}
            />
          </>
        )}
        />
      </Dialog>
    </>
  );
};
