import { MenuItem, Tag } from '@blueprintjs/core';
import { ItemPredicate, ItemRenderer, MultiSelect2, MultiSelect2Props } from '@blueprintjs/select';
import { UseControllerProps, useFieldArray, useFormContext } from 'react-hook-form';

import { SelectItem } from 'types';

interface Props<T> {
  controllerProps: UseControllerProps;
  items: SelectItem<T>[];
  selectProps?: Partial<MultiSelect2Props<SelectItem<T>>>;
}

export default <T, >(props: Props<T>) => {
  const { getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control: props.controllerProps.control,
    name: props.controllerProps.name,
  });
  const selectedItems = fields.map((f, i) => {
    const value = getValues(`${props.controllerProps.name}.${i}`);
    return {
      label: props.items.find(item => item.value === value)?.label ?? value,
      value,
    };
  });
  const areItemsEqual = (itemA: SelectItem<T>, itemB: SelectItem<T>) => {
    return itemA.label === itemB.label && itemA.value === itemB.value;
  };
  const getSelectedItemIndex = (item: SelectItem<T>) => {
    return selectedItems.findIndex(selectedItem => areItemsEqual(selectedItem, item));
  };
  const isItemSelected = (item: SelectItem<T>) => {
    return selectedItems.some(selectedItem => areItemsEqual(selectedItem, item));
  };

  const onRemove = (_item: SelectItem<T>, index: number) => remove(index);
  const onItemSelect = (item: SelectItem<T>) => {
    if (!isItemSelected(item)) {
      append(item.value);
    } else {
      remove(getSelectedItemIndex(item));
    }
  };
  const itemPredicate: ItemPredicate<SelectItem<T>> = (query, item) => {
    return item.label.indexOf(query) >= 0;
  };
  const itemRenderer: ItemRenderer<SelectItem<T>> = (item, { handleClick, index, modifiers }) => {
    if (!modifiers.matchesPredicate) return null;

    return (
      <MenuItem
        key={index}
        onClick={handleClick}
        selected={isItemSelected(item)}
        text={item.label}
      />
    );
  };
  const tagRenderer = (item: SelectItem<T>) => {
    return <Tag>{item.label}</Tag>;
  };

  return (
    <MultiSelect2
      {...props.selectProps}
      itemPredicate={itemPredicate}
      itemRenderer={itemRenderer}
      items={props.items}
      onRemove={onRemove}
      onItemSelect={onItemSelect}
      placeholder="Select..."
      selectedItems={selectedItems}
      tagRenderer={tagRenderer}
    />
  );
};
