import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbProps, Button, IBreadcrumbProps, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Breadcrumbs2, Popover2 } from '@blueprintjs/popover2';
import classNames from 'classnames';

import PartCategoryModal from 'components/PartCategoryModal';
import {
  PartConfig,
  usePartNavCategoriesLazyQuery,
  usePartNavConfigsLazyQuery,
} from 'graphql/generated/graphql';

import styles from './index.module.css';

enum Mode {
  CATEGORY,
  CONFIG,
}

interface Props {
  className?: string;
}

export default (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [selectedConfig, setSelectedConfig] = useState<PartConfig | null>(null);
  const [isCreateCategoryDialogOpen, setIsCreateCategoryDialogOpen] = useState(false);
  const [getCategories, { data: partCategories, refetch: refetchPartCategories }] = usePartNavCategoriesLazyQuery({ fetchPolicy: 'no-cache' });
  const [getConfigs, { data: partConfigs }] = usePartNavConfigsLazyQuery();

  const [mode, setMode] = useState(Mode.CATEGORY);
  useEffect(() => {
    if (!params.categoryName && !params.configName) {
      getCategories();
    } else if (params.categoryName && params.configName) {
      getConfigs({
        variables: {
          categoryName: params.categoryName,
        },
      });
      setMode(Mode.CONFIG);
    }
  }, [params]);

  useEffect(() => {
    if (params.configName && partConfigs) {
      const config = partConfigs.partConfigs.find(c => c.name === params.configName);
      if (config) setSelectedConfig(config as PartConfig);
    }
  }, [params.configName, partConfigs]);

  const onPartCategoryCreated = () => {
    setIsCreateCategoryDialogOpen(false);
    refetchPartCategories();
  };

  const renderCategoryHeader = () => (
    <>
      <span>Choose a category</span>
      <Popover2
        content={(
          <Menu>
            <MenuItem
              onClick={() => setIsCreateCategoryDialogOpen(true)}
              text="Create Root Category"
            />
            <MenuItem
              onClick={() => navigate('/parts/configs/create')}
              text="Create Config"
            />
          </Menu>
        )}
        position={Position.BOTTOM}
      >
        <Button icon="chevron-down" small />
      </Popover2>
    </>
  );

  const breadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={breadcrumbProps.className}
      disabled={breadcrumbProps.disabled}
      onClick={breadcrumbProps.onClick}
      text={breadcrumbProps.text}
    />
  );
  const currentBreadcrumbRenderer = (breadcrumbProps: BreadcrumbProps) => (
    <Breadcrumb
      className={breadcrumbProps.className}
      text={breadcrumbProps.text}
      current
    />
  );

  const renderConfigHeader = () => {
    const entries: IBreadcrumbProps[] = [{
      text: 'All',
      onClick: () => navigate('/parts'),
    }];
    if (selectedConfig) {
      entries.push({
        className: styles.previousBreadcrumb,
        text: selectedConfig.category.display_name,
      }, {
        className: styles.currentBreadcrumb,
        text: selectedConfig.display_name,
        disabled: true,
      });
    }

    return (
      <>
        <Breadcrumbs2
          breadcrumbRenderer={breadcrumbRenderer}
          className={styles.breadcrumbsContainer}
          currentBreadcrumbRenderer={currentBreadcrumbRenderer}
          items={entries}
          minVisibleItems={1}
        />
        <Popover2
          content={(
            <Menu>
              <MenuItem
                onClick={() => navigate(`/parts/configs/${selectedConfig?.id}`)}
                text={`Edit ${selectedConfig?.display_name} Config`}
              />
            </Menu>
          )}
          position={Position.BOTTOM}
        >
          <Button
            className={styles.actionsMenuButton}
            icon="chevron-down"
            small
          />
        </Popover2>
      </>
    );
  };

  const renderCategoryItems = () => {
    if (!partCategories) return null;
    return partCategories.partCategories
      .map(category => (
        <MenuItem
          className={styles.menuItem}
          key={`category-menu-${category.name}`}
          disabled={!category.configs || category.configs?.length === 0}
          onClick={() => navigate(`category/${encodeURIComponent(category.name)}`)}
          tagName="div"
          text={category.display_name}
        />
      ));
  };

  const renderConfigItems = () => {
    if (!partConfigs) return null;
    return partConfigs.partConfigs.map(config => {
      const categoryName = encodeURIComponent(config.category.name);
      const configName = encodeURIComponent(config.name);
      return (
        <MenuItem
          className={styles.menuItem}
          key={`config-menu-${config.name}`}
          onClick={() => navigate(`/parts/category/${categoryName}/${configName}`)}
          tagName="div"
          text={config.display_name}
        />
      );
    });
  };

  const containerClasses = classNames(styles.container, props.className);
  return (
    <>
      <div className={containerClasses}>
        <div className={styles.menuHeader}>
          {mode === Mode.CATEGORY ? renderCategoryHeader() : renderConfigHeader()}
        </div>
        <Menu className={styles.menu}>
          {mode === Mode.CATEGORY ? renderCategoryItems() : renderConfigItems()}
        </Menu>
      </div>
      <PartCategoryModal
        isOpen={isCreateCategoryDialogOpen}
        onClose={() => setIsCreateCategoryDialogOpen(false)}
        onPartCategoryCreated={onPartCategoryCreated}
      />
    </>
  );
};
