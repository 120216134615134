import styles from './index.module.css';
import SetupTable from './SetupTable';

export default () => {
  return (
    <div className={styles.container}>
      <div className={styles.setupSection}>
        <SetupTable />
      </div>
    </div>
  );
};
