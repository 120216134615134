import { useEffect, useState } from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { union } from 'lodash';
import { ItemRenderer, Select2, ItemListPredicate } from '@blueprintjs/select';
import { Required } from 'utility-types';

import { Part, SetupFieldPosition } from 'graphql/generated/graphql';
import { SelectItem } from 'types';

import styles from './index.module.css';
import classNames from 'classnames';

type PartSelectorPart = Required<Part, 'id' | 'config' | 'description'>;

interface Props {
  className?: string;
  initialPart?: PartSelectorPart;
  onChange?: (partId: number) => void;
  parts: PartSelectorPart[];
  position?: SetupFieldPosition;
  disabled?: boolean;
  hideDetails?: boolean;
  buttonStyles?: string;
}

const PartSelector = (props: Props) => {
  const [selectedItem, setSelectedItem] = useState<SelectItem<Part> | null>(null);

  useEffect(() => {
    if (props.initialPart) {
      setSelectedItem({
        label: props.initialPart.description,
        value: props.initialPart,
      });
    } else {
      setSelectedItem(null);
    }
  }, [props.initialPart]);

  const { onChange, parts, position } = props;
  const partSelectItems = parts.map(p => ({ label: p.description, value: p }));
  const noSelectionText = position ? `Select ${`${position.label} `}Part` : 'Select Part';

  const getItemLayout = (item: SelectItem<Part>) => {
    const { mileage, part_number: partNum, serial_number: serialNum } = item.value;
    return (
      <>
        <p className={styles.selectDesc}>{item.label}</p>
        {!props.hideDetails && (
          <div className={classNames('bp4-text-small', 'bp4-text-muted')}>
            {partNum && <p className={styles.selectPartNum}>Part #: {partNum}</p>}
            {serialNum && <p className={styles.selectSerialNum}>Serial #: {serialNum}</p>}
            {(mileage || mileage === 0) && <p className={styles.selectMileage}>Mileage: {mileage}</p>}
          </div>
        )}
      </>
    );
  };

  const renderItem: ItemRenderer<SelectItem<Part>> = (item, { handleClick, index, modifiers }) => {
    if (!modifiers.matchesPredicate) return null;
    return (
      <MenuItem
        intent={item.intent}
        key={index}
        onClick={handleClick}
        text={getItemLayout(item)}
      />
    );
  };

  const filterList: ItemListPredicate<SelectItem<Part>> = (query, partSelectItems) => {
    // Filters list on label, serial_number, and part_number and then returns new list without duplicates
    const labelFilter = partSelectItems.filter(p => p.label.toLowerCase().includes(query.toLowerCase()));
    const serialNumberFilter = partSelectItems.filter(p => p.value?.serial_number?.toLowerCase().includes(query.toLowerCase()));
    const partNumberFilter = partSelectItems.filter(p => p.value?.part_number?.toLowerCase().includes(query.toLowerCase()));
    return union(labelFilter, serialNumberFilter, partNumberFilter);
  };

  const onItemSelect = (item: SelectItem<Part>) => {
    setSelectedItem(item);
    onChange?.(item.value.id);
  };

  return (
    <div className={classNames(props.className, styles.partInput)}>
      <Select2
        itemRenderer={renderItem}
        itemListPredicate={filterList}
        items={partSelectItems}
        onItemSelect={onItemSelect}
        popoverTargetProps={{
          className: styles.selectTarget,
        }}
        disabled={props.disabled}
      >
        <Button
          className={classNames(styles.partSelectButton, props.buttonStyles)}
          disabled={props.disabled}
          fill
          rightIcon="double-caret-vertical"
        >
          {selectedItem ? getItemLayout(selectedItem) : noSelectionText}
        </Button>
      </Select2>
    </div>
  );
};

export default PartSelector;
