export const hasPermission = (permissionToCheck: string, permissions: string[]) => {
  return (permissions.includes(permissionToCheck));
};

export const getTeamIds = (teams: { id: string; }[]) => {
  return teams.map((team: { id: string; }) => team.id);
};

export const getOrgIds = (organizations: { id: string; }[]) => {
  return organizations.map((org: { id: string; }) => org.id);
};
