import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import * as loaders from './loaders';
import GlobalLayout from 'components/GlobalLayout';
import SetupLayout from 'components/SetupLayout';
// import Admin from 'pages/Admin';
// import Permissions from 'pages/Admin/Permissions';
import Calculations from 'pages/Calculations';
import EnvironmentViews from 'pages/EnvironmentViews';
import EnvironmentDetail from 'pages/EnvironmentDetail';
import PartManagement from 'pages/PartManagement';
import PartConfigs from 'pages/PartManagement/PartConfigs';
import PartLifing from 'pages/PartManagement/PartLifing';
import PartCategory from 'pages/PartManagement/PartCategory';
import PartConfigParts from 'pages/PartManagement/PartConfigParts';
import Setup from 'pages/Setup';
import BumpStops from 'pages/Setup/BumpStops';
import Shocks from 'pages/Setup/Shocks';
import Springs from 'pages/Setup/Springs';
import Tires from 'pages/Setup/Tires';
import RunLog from 'pages/Setup/RunLog';
import SetupFieldManagement from 'pages/SetupFieldManagement';
import SetupSummary from 'pages/SetupSummary';
import SimManagement from 'pages/SimManagement';
import SetupMerge from 'pages/SetupMerge';
import SetupCompare from 'pages/SetupCompare';
import SUITForm from 'pages/SUITForm';
import SUITManagement from 'pages/SUITManagement';
import PartProperties from 'pages/PartManagement/PartProperties';
import AddPart from 'pages/PartManagement/AddPart';
import EditPart from 'pages/PartManagement/EditPart';
import ClonePart from 'pages/PartManagement/ClonePart';
import CreatePartConfig from 'pages/PartManagement/CreatePartConfig';
import EditPartConfig from 'pages/PartManagement/EditPartConfig';
import RunManagement from 'pages/RunManagement';
import RunFieldManagement from 'pages/RunFieldManagement';
import RUITManagement from 'pages/RUITManagement';
import RUITForm from 'pages/RUITForm';
import RunCompare from 'pages/RunCompare';
import SimDetail from 'pages/SimDetail';
import SimWorksheet from 'pages/SimWorksheet';
import SimWorksheetSummary from 'pages/SimWorksheetSummary';
import SimReporting from 'pages/SimReporting';
import SimDocumentManagement from 'pages/SimDocumentManagement';
import SimDocumentCategory from 'pages/SimDocumentManagement/SimDocumentCategory';
import SimDocuments from 'pages/SimDocumentManagement/SimDocuments';
import AddSimDocument from 'pages/SimDocumentManagement/AddSimDocument';
import EditSimDocument from 'pages/SimDocumentManagement/EditSimDocument';
import CloneSimDocument from 'pages/SimDocumentManagement/CloneSimDocument';
import Docs from 'pages/Docs';
import SweepDetail from 'pages/SweepDetail';
import SweepsSummary from 'pages/SweepsSummary';
import PrivateRoute from 'pages/PrivateRoute';
import MetricsSummary from 'pages/MetricsSummary';
import MetricDetail from 'pages/MetricDetail';

export default [{
  id: 'root',
  path: '/',
  element: <GlobalLayout />,
  loader: loaders.getUserData,
  children: [{
    index: true,
    element: <SetupSummary />,
  }, {
    path: 'setup',
    children: [{
      index: true,
      element: <Navigate to="/" />,
    }, {
      path: ':branchId',
      element: <SetupLayout />,
      children: [{
        index: true,
        element: <Setup />,
      }, {
        path: 'merge',
        element: <SetupMerge />,
      }, {
        path: 'springs',
        element: <Springs />,
      }, {
        path: 'shocks',
        element: <Shocks />,
      }, {
        path: 'bump-stops',
        element: <BumpStops />,
      }, {
        path: 'tires',
        element: <Tires />,
      }, {
        path: 'run-log',
        element: <RunLog />,
      }, {
        path: 'compare',
        element: <SetupCompare />,
      }],
    }],
  },
  {
    path: 'setups',
    children: [{
      path: 'fields',
      element: (
        <PrivateRoute permissionToCheck="suit_write">
          <SetupFieldManagement />
        </PrivateRoute>
      ),
    }, {
      path: 'suits',
      element: (
        <PrivateRoute permissionToCheck="suit_write">
          <SUITManagement />
        </PrivateRoute>
      ),
    }, {
      path: 'suit',
      children: [{
        path: 'create',
        element: (
          <PrivateRoute permissionToCheck="suit_write">
            <SUITForm />
          </PrivateRoute>
        ),
      }, {
        path: ':suitId',
        element: (
          <PrivateRoute permissionToCheck="suit_write">
            <SUITForm />
          </PrivateRoute>
        ),
      }],
    }],
  }, {
    path: 'runs',
    children: [{
      index: true,
      element: (
        <PrivateRoute permissionToCheck="ruit_write">
          <RunManagement />
        </PrivateRoute>
      ),
    }, {
      path: 'compare',
      element: <RunCompare />,
    }, {
      path: 'fields',
      element: (
        <PrivateRoute permissionToCheck="ruit_write">
          <RunFieldManagement />
        </PrivateRoute>
      ),
    }, {
      path: 'ruits',
      element: (
        <PrivateRoute permissionToCheck="ruit_write">
          <RUITManagement />
        </PrivateRoute>
      ),
    }, {
      path: 'ruit',
      children: [{
        path: 'create',
        element: (
          <PrivateRoute permissionToCheck="ruit_write">
            <RUITForm />
          </PrivateRoute>
        ),
      }, {
        path: ':ruitId',
        element: (
          <PrivateRoute permissionToCheck="ruit_write">
            <RUITForm />
          </PrivateRoute>
        ),
      }],
    }],
  }, {
    path: 'sims',
    children: [{
      index: true,
      element: <SimManagement />,
    }, {
      path: 'documents',
      children: [{
        index: true,
        element: <SimDocumentManagement />,
      }, {
        path: 'category',
        element: <Navigate to="/sims/documents" />,
      }, {
        path: 'category/:categoryName',
        element: <SimDocumentCategory />,
      }, {
        path: 'category/:categoryName/:configName',
        element: <SimDocuments />,
      }, {
        path: 'add',
        element: <AddSimDocument />,
      }, {
        path: 'category/:categoryName/:configName/:documentId',
        element: <EditSimDocument />,
      }, {
        path: 'category/:categoryName/:configName/clone/:documentId',
        element: <CloneSimDocument />,
      }],
    }, {
      path: 'reporting',
      element: <SimReporting />,
    }, {
      path: 'envs',
      element: <EnvironmentViews />,
    }, {
      path: 'calcs',
      element: <Calculations />,
    }, {
      path: ':simId',
      element: <SimDetail />,
    }, {
      path: 'metrics',
      children: [{
        index: true,
        element: <MetricsSummary />,
      }, {
        path: ':metricId',
        element: <MetricDetail />,
      }],
    }, {
      path: 'sweeps',
      children: [{
        index: true,
        element: <SweepsSummary />,
      }, {
        path: ':sweepId',
        element: <SweepDetail />,
      }],
    }],
  }, {
    path: 'sim-worksheets',
    children: [{
      index: true,
      element: <SimWorksheetSummary />,
    }, {
      path: ':worksheetId',
      element: <SimWorksheet />,
    }],
  }, {
    path: 'environments',
    children: [{
      index: true,
      element: <EnvironmentViews />,
    }, {
      path: ':environmentId',
      element: <EnvironmentDetail />,
    }],
  }, {
    path: 'parts',
    children: [{
      index: true,
      element: <PartManagement />,
    }, {
      path: 'properties',
      element: <PartProperties />,
    }, {
      path: 'configs',
      element: <Outlet />,
      children: [{
        index: true,
        element: <PartConfigs />,
      }, {
        path: 'create',
        element: <CreatePartConfig />,
      }, {
        path: ':configId',
        element: <EditPartConfig />,
      }],
    }, {
      path: 'lifing',
      element: <PartLifing />,
    }, {
      path: 'category',
      element: <Navigate to="/parts" />,
    }, {
      path: 'category/:categoryName',
      loader: loaders.partCategory,
      element: <PartCategory />,
    }, {
      path: 'category/:categoryName/:configName',
      element: <PartConfigParts />,
    }, {
      path: 'add',
      element: <AddPart />,
    }, {
      path: 'clone/:partId',
      element: <ClonePart />,
    }, {
      path: ':partId',
      element: <EditPart />,
    }],
  }, {
    path: 'docs',
    element: <Docs />,
  }],
  // }, {
  //   path: 'admin',
  //   element: <Admin />,
  //   children: [{
  //     path: 'permissions',
  //     element: <Permissions />,
  //   }],
  // }],
}] as RouteObject[];
