import React, { useState } from 'react';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Intent } from '@blueprintjs/core';
import styles from './index.module.css';
import AppToaster from 'helpers/toaster';
import { ApiRequest } from 'api';
import { GQLSimWorksheet } from 'types';
import { useSubscriptionContext } from 'context/SubscriptionContext';

interface RunButtonProps {
  simOutlineIds: number[]
  text?: string
  transparent?: boolean
  disabled?: boolean
  worksheet: GQLSimWorksheet
  isDirty?: boolean;
  onSave?: () => Promise<void>;
}

const RunButton: React.FC<RunButtonProps> = (props: RunButtonProps) => {
  const {
    simOutlineIds,
    text,
    transparent,
    disabled,
    worksheet,
    isDirty = false,
    onSave,
  } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { getExistingProgress } = useSubscriptionContext();

  const validateEnvironments = (): { valid: boolean; missingEnvOutlines: number[] } => {
    const missingEnvOutlines: number[] = [];

    // If there's no worksheet environment and no outlines have environments, we have a problem
    simOutlineIds.forEach(outlineId => {
      const outline = worksheet?.sim_outlines?.find(o => o.id === outlineId);
      if (outline && (!outline.environments || outline.environments.length === 0)) {
        // Check if we have a fallback worksheet environment
        if (!worksheet.environment) {
          missingEnvOutlines.push(outlineId);
        }
      }
    });

    return {
      valid: missingEnvOutlines.length === 0,
      missingEnvOutlines,
    };
  };

  const runSimOutlines = async () => {
    setIsLoading(true);

    if (!worksheet) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Worksheet is not defined',
      });
      return;
    }

    const { valid, missingEnvOutlines } = validateEnvironments();
    if (!valid) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: `Missing environment for sim outline${missingEnvOutlines.length > 1 ? 's' : ''} `
          + `${missingEnvOutlines.join(', ')}. Please add an environment to the outline${missingEnvOutlines.length > 1 ? 's' : ''} `
          + 'or set a default environment for the worksheet.',
      });
      setIsLoading(false);
      return;
    }

    try {
      // If worksheet is dirty and we have a save function, save first
      if (isDirty && onSave) {
        try {
          await onSave();
        } catch (error) {
          setIsLoading(false);
          return;
        }
      }

      await ApiRequest.post('/execution-plans/simserver', { worksheetId: worksheet.id, simOutlineIds });
      AppToaster.show({
        intent: Intent.SUCCESS,
        message: 'Successfully submitted sim(s)',
      });
    } catch (e) {
      AppToaster.show({
        intent: Intent.DANGER,
        message: 'Sim submission error',
      });
    } finally {
      setIsLoading(false);
      getExistingProgress();
    }
  };

  return (
    <Button
      onClick={() => runSimOutlines()}
      minimal={transparent}
      disabled={disabled}
      loading={isLoading}
    >
      <FontAwesomeIcon icon={faPlay} />
      {text ? <span className={styles.paddingLeft}>{text}</span> : null}
    </Button>
  );
};

export default RunButton;
