import { Position, Toaster } from '@blueprintjs/core';

export default Toaster.create({
  // This class name is a hacky workaround for Blueprint's decision to ignore
  // the `usePortal` prop for Toasters created using `.create`. Although it does
  // always put toasts into a portal in this scenario, it fails to mark them
  // with the correct "...in-portal" classname which would fix their position
  //
  // See: https://github.com/palantir/blueprint/blob/develop/packages/core/src/components/toast/toaster.tsx#L93
  //
  className: 'app-toaster', // Defined in index.css
  position: Position.BOTTOM_RIGHT,
});
